// src/components/DeviceModal/DeviceModal.jsx

import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { AuthContext } from '../../context/AuthContext';
import styles from './DeviceModal.module.css';

const DeviceModal = ({ isOpen, onRequestClose, deviceData, onSave }) => {
  const { usuarioId } = useContext(AuthContext); // Obtendo o ID do usuário do contexto
  const isEditMode = Boolean(deviceData);

  const [formData, setFormData] = useState({
    fotocelulaId: '',
    descricao: '',
    deviceEUI: '',
    // Outros campos que não serão enviados via input
    corrente: 0,
    potencia: 0,
    tempolampadaligada: 0,
    pa: 0,
    pr: 0,
    pf: 0,
  });

  useEffect(() => {
    if (isEditMode) {
      setFormData({
        fotocelulaId: deviceData.fotocelulaId || '',
        descricao: deviceData.descricao || '',
        deviceEUI: deviceData.deviceEUI || '',
        corrente: deviceData.corrente || 0,
        potencia: deviceData.potencia || 0,
        tempolampadaligada: deviceData.tempolampadaligada || 0,
        pa: deviceData.pa || 0,
        pr: deviceData.pr || 0,
        pf: deviceData.pf || 0,
      });
    } else {
      setFormData({
        descricao: '',
        deviceEUI: '',
        corrente: 0,
        potencia: 0,
        tempolampadaligada: 0,
        pa: 0,
        pr: 0,
        pf: 0,
      });
    }
  }, [deviceData, isEditMode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Preparar os dados para enviar
    const deviceToSave = {
      ...formData,
      latitude: 0, // Sempre enviar 0
      longitude: 0, // Sempre enviar 0
      usuarioId: isEditMode ? deviceData.usuarioId : usuarioId, // Usar usuarioId correto
    };
    onSave(deviceToSave);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={isEditMode ? "Editar Dispositivo" : "Adicionar Novo Dispositivo"}
      className={styles.modal}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <button className={styles.closeButton} onClick={onRequestClose}>
        <FaTimes />
      </button>
      <div className={styles.content}>
        <h2>{isEditMode ? "Editar Dispositivo" : "Adicionar Novo Dispositivo"}</h2>
        <form onSubmit={handleSubmit}>
          {isEditMode && (
            <div className={styles.formGroup}>
              <label htmlFor="fotocelulaId">Fotocélula ID</label>
              <input
                type="text"
                id="fotocelulaId"
                name="fotocelulaId"
                value={formData.fotocelulaId}
                disabled // Desabilitado para não permitir edição
              />
            </div>
          )}
          <div className={styles.formGroup}>
            <label htmlFor="descricao">Descrição</label>
            <input
              type="text"
              id="descricao"
              name="descricao"
              value={formData.descricao}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="deviceEUI">Device EUI</label>
            <input
              type="text"
              id="deviceEUI"
              name="deviceEUI"
              value={formData.deviceEUI}
              onChange={handleChange}
              required
            />
          </div>
          {/* Removidos os campos "Latitude" e "Longitude" */}
          <div className={styles.formActions}>
            <button type="submit" className={styles.submitButton}>
              {isEditMode ? "Salvar Alterações" : "Adicionar Dispositivo"}
            </button>
            <button type="button" className={styles.cancelButton} onClick={onRequestClose}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default DeviceModal;
