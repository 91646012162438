// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';
import './App.css';
import Sidebar from './components/Sidebar/Sidebar';
import Postes from './pages/Postes/Postes';
import Dashboard from './pages/Dashboard/Dashboard';
import Mapa from './pages/Mapa/Mapa';
import Login from './pages/Login/Login';
import Alertas from './pages/Alertas/Alertas';
import OrdensServicos from './pages/OrdensServicos/OrdensServicos';
import Levantamentos from './pages/Levantamentos/Levantamentos';
import DispositivosIOT from './pages/DispositivosIOT/DispositivosIOT';
import PerfisConfiguracao from './pages/PerfisConfiguracao/PerfisConfiguracao';
import GruposPostes from './pages/PosteGrupos/PosteGrupos';
import FormPage from './pages/FormPage/FormPage';
import ProtectedRoute from './ProtectedRoute';
import Relatorios from './pages/Relatorios/Relatorios'; // Importe o novo componente
import Administrador from './pages/Administrador/Administrador'; // Importe o Administrador
import MinhasConfiguracoes from './pages/MinhasConfiguracoes/MinhasConfiguracoes'
import AuthProvider from './context/AuthContext';
import GarantiasProdutos from './pages/GarantiasProdutos/GarantiasProdutos';
import { Bracos, Lampadas, TipoPostes, TipoVias, ClassificacaoVias, FormatoTampas, FormatoPostes } from './pages/CadastrosDeSistemas';

// Importar o ToastContainer e os estilos do React Toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Importar o interceptor
import { useFetchInterceptor } from './fetchInterceptor';

// Google Maps API Key
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; // Use variável de ambiente

function App() {
  return (
    <Router>
      <AuthProvider>
        <LoadScript
          googleMapsApiKey={googleMapsApiKey}
          libraries={['drawing', 'geometry']}
          onLoad={() => console.log("Google Maps API carregada com sucesso")}
          onError={() => console.error("Erro ao carregar a Google Maps API")}
        >
          <AppInner />
          {/* Apenas uma instância do ToastContainer */}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </LoadScript>
      </AuthProvider>
    </Router>
  );
}

function AppInner() {
  const location = useLocation();
  useFetchInterceptor(); // Configura o interceptor

  // Verifica se a rota atual corresponde à página de autenticação para esconder o Sidebar
  const isAuthPage = location.pathname === '/login';

  return (
    <div className="App">
      {!isAuthPage && <Sidebar />}
      <div className={`content ${!isAuthPage ? 'withSidebar' : ''}`}>
        <Routes>
          <Route path="/login" element={<Login />} />

          {/* Rotas Protegidas */}
          <Route
            path="/postes"
            element={
              <ProtectedRoute>
                <Postes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/lampadas"
            element={
              <ProtectedRoute>
                <Lampadas />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bracos"
            element={
              <ProtectedRoute>
                <Bracos />
              </ProtectedRoute>
            }
          />    
          <Route
            path="/tipopostes"
            element={
              <ProtectedRoute>
                <TipoPostes />
              </ProtectedRoute>
            }
          />
          <Route 
            path="/formatopostes" 
            element={
              <ProtectedRoute>
                <FormatoPostes />
              </ProtectedRoute>
            } 
          />   
          <Route
            path="/tipovias"
            element={
              <ProtectedRoute>
                <TipoVias />
              </ProtectedRoute>
            }
          />
          <Route 
            path="/classificacaovias" 
            element={
              <ProtectedRoute>
                <ClassificacaoVias />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/formatotampas" 
            element={
              <ProtectedRoute>
                <FormatoTampas />
              </ProtectedRoute>
            } 
          />    
          <Route
            path="/alertas"
            element={
              <ProtectedRoute>
                <Alertas />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mapa"
            element={
              <ProtectedRoute>
                <Mapa />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ordens-servico"
            element={
              <ProtectedRoute>
                <OrdensServicos />
              </ProtectedRoute>
            }
          />
          <Route
            path="/levantamentos"
            element={
              <ProtectedRoute>
                <Levantamentos />
              </ProtectedRoute>
            }
          />
          <Route
            path="/relatorios"
            element={
              <ProtectedRoute>
                <Relatorios />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dispositivos"
            element={
              <ProtectedRoute>
                <DispositivosIOT />
              </ProtectedRoute>
            }
          />
          <Route
            path="/perfis-configuracao"
            element={
              <ProtectedRoute>
                <PerfisConfiguracao />
              </ProtectedRoute>
            }
          />
          <Route
            path="/grupos-postes"
            element={
              <ProtectedRoute>
                <GruposPostes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/formulario"
            element={
              <ProtectedRoute>
                <FormPage />
              </ProtectedRoute>
            }
          />
          {/* Nova Rota para Administrador */}
          <Route
            path="/administrador"
            element={
              <ProtectedRoute>
                <Administrador />
              </ProtectedRoute>
            }
          />

          <Route
            path="/minhas-configuracoes"
            element={
              <ProtectedRoute>
                <MinhasConfiguracoes />
              </ProtectedRoute>
            }
          />

          {/* Nova Rota para Garantias de Produtos */}
          <Route
            path="/garantias"
            element={
              <ProtectedRoute>
                <GarantiasProdutos />
              </ProtectedRoute>
            }
          />


          {/* Rota Padrão */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
