import React, { useState, useEffect } from 'react';
import { FaIdCard, FaCog, FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import styles from './TipoPostesModal.module.css';
import BaseModal from '../../../components/BaseModal/BaseModal';

Modal.setAppElement('#root');

const TipoPostesModal = ({ isOpen, onRequestClose, onSubmit, initialData }) => {
  const [titulo, setTitulo] = useState("Novo Tipo de Poste");
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    tp_id: '',
    tp_nome: '',
  });

  useEffect(() => {
    if (initialData) {
      const formattedData = {
        ...initialData,
        tp_id: initialData.tp_id,
      };
      setFormData(formattedData);
      setTitulo("Editar Tipo de Poste");
      setEdit(true);
    } else {
      setFormData({
        tp_id: '',
        tp_nome: '',
      });
      setTitulo("Novo Tipo de Poste");
      setEdit(false);
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ ...formData });
    onRequestClose();
  };


  return (
    <BaseModal contentLabel={"Informações de Tipo Poste"} isOpen={isOpen} onRequestClose={onRequestClose}>
      <h1 className={styles.header}>{titulo}</h1>
      <form onSubmit={handleSubmit}>
        <fieldset className={styles.fieldset}>
          <div className={styles.formGroup}>
            {edit && (
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaIdCard /> ID</label>
                <input
                  className={styles.input}
                  type="text"
                  name="tp_id"
                  value={formData.tp_id}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            )}
            <div className={styles.colSpan1}>
              <label className={styles.label}><FaCog /> Tipo de Poste</label>
              <input
                className={styles.input}
                type="text"
                name="tp_nome"
                value={formData.tp_nome}
                onChange={handleChange}
              />
            </div>

          </div>
        </fieldset>

        <button className={styles.button} type="submit">Salvar</button>
      </form>
    </BaseModal>
  );
};

export default TipoPostesModal;
