// src/components/PosteGrupos/FerramentasBarra.jsx

import React from 'react';
import { FaMousePointer, FaCheck, FaTrash, FaDrawPolygon } from 'react-icons/fa';
import styles from './FerramentasBarra.module.css';
import PropTypes from 'prop-types';

const FerramentasBarra = ({
  activeTool,
  setActiveTool,
  selectedMarkersToAdd,
  selectedMarkersToRemove,
  applySelection,
  finishPolygonSelection,
  cancelPolygonSelection,
  polygonPoints, // Recebe os pontos criados para a seleção poligonal
}) => {
  // Verifica se a ferramenta ativa é poligonal e se há pontos no polígono
  const isPolygonActiveWithPoints = activeTool === 'polygon' && polygonPoints.length > 0;

  return (
    <>
      {/* Barra de Ferramentas */}
      <div className={styles.ferramentasBarra}>
        <div className={styles.toolButtons}>
          {/* Botão de Seleção por Clique */}
          <button
            className={`${styles.ferramentaBotao} ${activeTool === 'click' ? styles.active : ''}`}
            title="Seleção por Clique"
            onClick={() => setActiveTool('click')}
          >
            <FaMousePointer size={20} />
          </button>

          {/* Botão de Seleção Poligonal */}
          <button
            className={`${styles.ferramentaBotao} ${activeTool === 'polygon' ? styles.active : ''}`}
            title="Seleção Poligonal"
            onClick={() => setActiveTool(activeTool === 'polygon' ? 'click' : 'polygon')}
          >
            <FaDrawPolygon size={20} />
          </button>
        </div>

        {/* Botões de Ações Poligonais (somente se activeTool === 'polygon') */}
        {activeTool === 'polygon' && (
          <div className={styles.polygonActions}>
            {/* Botão "Finalizar" aparece apenas se houver mais de 2 pontos */}
            {polygonPoints.length > 2 && (
              <button
                className={styles.finishPolygonButton}
                title="Finalizar Seleção Poligonal"
                onClick={finishPolygonSelection}
              >
                <FaCheck size={16} />
              </button>
            )}

            {/* Botão "Cancelar" aparece apenas se houver pelo menos um ponto */}
            {polygonPoints.length > 0 && (
              <button
                className={styles.cancelPolygonButton}
                title="Cancelar Seleção Poligonal"
                onClick={cancelPolygonSelection}
              >
                <FaTrash size={16} />
              </button>
            )}
          </div>
        )}
      </div>

      {/* Botão Aplicar Seleção Flutuante */}
      {!isPolygonActiveWithPoints &&
        (selectedMarkersToAdd.length > 0 || selectedMarkersToRemove.length > 0) && (
          <div className={styles.applySelectionFloating}>
            <button
              className={styles.applySelectionButton}
              title="Aplicar Seleção"
              onClick={applySelection}
            >
              <FaCheck size={16} />
              <span>Aplicar Seleção</span>
            </button>
          </div>
        )}
    </>
  );
};

FerramentasBarra.propTypes = {
  activeTool: PropTypes.string.isRequired,
  setActiveTool: PropTypes.func.isRequired,
  selectedMarkersToAdd: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedMarkersToRemove: PropTypes.arrayOf(PropTypes.number).isRequired,
  applySelection: PropTypes.func.isRequired,
  finishPolygonSelection: PropTypes.func.isRequired,
  cancelPolygonSelection: PropTypes.func.isRequired,
  polygonPoints: PropTypes.arrayOf(PropTypes.object).isRequired, // Array de pontos da seleção
};

export default FerramentasBarra;
