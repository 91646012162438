// src/pages/MinhasConfiguracoes/MinhasConfiguracoes.jsx

import React, { useState, useContext, useEffect } from 'react';
import styles from './MinhasConfiguracoes.module.css';
import { AuthContext } from '../../context/AuthContext';
import { toast } from 'react-toastify';

const MinhasConfiguracoes = () => {
  const {
    usuarioId,
    token,
    cidadeId,
    isAuthenticated,
    loading,
  } = useContext(AuthContext);

  const [userData, setUserData] = useState(null); // Estado para armazenar os dados do usuário
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [error, setError] = useState('');
  const [fetchError, setFetchError] = useState('');
  const [isFetching, setIsFetching] = useState(true); // Estado de carregamento para a requisição

  // Função para buscar os dados do usuário
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`https://api.ads10.com.br/api/usuario/${usuarioId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'cidadeId': cidadeId,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Erro ao buscar os dados do usuário.');
        }

        const data = await response.json();
        setUserData(data); // Armazena os dados do usuário
        setIsFetching(false);
      } catch (err) {
        console.error(err);
        setFetchError(err.message);
        setIsFetching(false);
      }
    };

    fetchUserData();
  }, [usuarioId, token, cidadeId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validações
    if (novaSenha.length < 6) {
      setError('A senha deve ter no mínimo 6 caracteres.');
      return;
    }

    if (novaSenha !== confirmarSenha) {
      setError('As senhas não coincidem.');
      return;
    }

    setError('');

    try {
      const response = await fetch(`https://api.ads10.com.br/api/usuario/${usuarioId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify({
          usuarioId: userData.usuarioId,
          usuarioUserName: userData.usuarioUserName,
          usuarioNome: userData.usuarioNome,
          usuarioSenha: novaSenha, // Atualizando a senha
          usuarioAtivo: userData.usuarioAtivo ? 's' : 'n',
          usuarioDataInclusao: userData.usuarioDataInclusao,
          usuarioUserInclusao: userData.usuarioUserInclusao,
          usuarioDataAlteracao: new Date().toISOString().split('T')[0],
          usuarioUserAlteracao: userData.usuarioUserName, // Identificação do usuário atual
          usuarioMotorista: userData.usuarioMotorista || "",
          usuarioAdmin: userData.usuarioAdmin ? 's' : 'n',
          usuarioEmail: userData.usuarioEmail || "",
          usuarioCelular: userData.usuarioCelular || "",
          usuarioPrestador: userData.usuarioPrestador ? 's' : 'n',
          usuarioCidade: cidadeId || 0, // Usando cidadeId do AuthContext
          usuarioAreaLat: userData.usuarioAreaLat || "",
          usuarioAreaLng: userData.usuarioAreaLng || "",
        }),
      });

      if (response.ok) {
        toast.success('Senha alterada com sucesso.');
        setNovaSenha('');
        setConfirmarSenha('');
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Não foi possível alterar a senha.');
      }
    } catch (err) {
      console.error(err);
      toast.error(`Erro ao alterar a senha: ${err.message}`);
    }
  };

  if (loading || isFetching) {
    return <p>Carregando...</p>;
  }

  if (fetchError) {
    return <p className={styles.error}>{fetchError}</p>;
  }

  if (!isAuthenticated) {
    return <p className={styles.error}>Usuário não autenticado.</p>;
  }

  return (
    <div className={styles.minhasConfiguracoesContainer}>
      <h1 className={styles.title}>Minhas Configurações</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="novaSenha">Nova Senha</label>
          <input
            type="password"
            id="novaSenha"
            className={styles.inputField}
            value={novaSenha}
            onChange={(e) => setNovaSenha(e.target.value)}
            minLength="6"
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="confirmarSenha">Confirmar Nova Senha</label>
          <input
            type="password"
            id="confirmarSenha"
            className={styles.inputField}
            value={confirmarSenha}
            onChange={(e) => setConfirmarSenha(e.target.value)}
            minLength="6"
            required
          />
        </div>
        {error && <p className={styles.error}>{error}</p>}
        <div className={styles.formActions}>
          <button type="submit" className={styles.button}>Salvar</button>
        </div>
      </form>
    </div>
  );
};

export default MinhasConfiguracoes;
