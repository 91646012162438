import React, { useState, useEffect } from 'react';
import { FaIdCard, FaLightbulb, FaInfoCircle, FaTimes, FaTag } from 'react-icons/fa';
import { FaBoltLightning } from "react-icons/fa6";
import { MdOutlineWbTwilight, MdHighlight } from "react-icons/md";
import Modal from 'react-modal';
import styles from './LampadaModal.module.css';
import BaseModal from '../../../components/BaseModal/BaseModal';

Modal.setAppElement('#root');
/*
{
    "ltId": "1",
    "ltTipo": "FL 7",
    "ltPotencia": "7.00",
    "ltPerdas": "0.00",
    "ltConsumo": "7.00",
    "ltValorkwh": "0.57"
}
*/
const LampadaModal = ({ isOpen, onRequestClose, onSubmit, initialData }) => {
  const [titulo, setTitulo] = useState("Nova Lâmpada");
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    ltId: '',
    ltTipo: '',
    ltPotencia: 0,
    ltPerdas: 0,
    ltConsumo: 0,
    ltValorkwh: 0,
  });

  useEffect(() => {
    if (initialData) {
      const formattedData = {
        ...initialData,
        ltId: initialData.ltId,
      };
      setFormData(formattedData);
      setTitulo("Editar Lâmpada");
      setEdit(true);
    } else {
      setFormData({
        ltId: '',
        ltTipo: '',
        ltPotencia: 0,
        ltPerdas: 0,
        ltConsumo: 0,
        ltValorkwh: 0,
      });
      setTitulo("Nova Lâmpada");
      setEdit(false);
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ ...formData });
    onRequestClose();
  };


  return (
    <BaseModal contentLabel={"Informações de Poste"} isOpen={isOpen} onRequestClose={onRequestClose}>

      <h1 className={styles.header}>{titulo}</h1>
      <form onSubmit={handleSubmit}>
        <fieldset className={styles.fieldset}>
          <div className={styles.formGroup}>
            {edit && (
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaIdCard /> ID</label>
                <input
                  className={styles.input}
                  type="text"
                  name="ltId"
                  value={formData.ltId}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            )}
            <div className={styles.colSpan1}>
              <label className={styles.label}><FaLightbulb /> Tipo</label>
              <input
                className={styles.input}
                type="text"
                name="ltTipo"
                value={formData.ltTipo}
                onChange={handleChange}
              />
            </div>
            <div className={styles.colSpan1}>
              <label className={styles.label}><FaBoltLightning /> Potência</label>
              <input
                className={styles.input}
                type="text"
                name="ltPotencia"
                value={formData.ltPotencia}
                onChange={handleChange}
              />
            </div>
            <div className={styles.colSpan1}>
              <label className={styles.label}><FaTag /> Perdas</label>
              <input
                className={styles.input}
                type="text"
                name="ltPerdas"
                value={formData.ltPerdas}
                onChange={handleChange}
              />
            </div>
            <div className={styles.colSpan1}>
              <label className={styles.label}><MdHighlight /> Consumo</label>
              <input
                className={styles.input}
                type="text"
                name="ltConsumo"
                value={formData.ltConsumo}
                onChange={handleChange}
              />
            </div>
            <div className={styles.colSpan1}>
              <label className={styles.label}><MdOutlineWbTwilight /> Valor kWh</label>
              <input
                className={styles.input}
                type="text"
                name="ltValorkwh"
                value={formData.ltValorkwh}
                onChange={handleChange}
              />
            </div>
          </div>
        </fieldset>

        <button className={styles.button} type="submit">Salvar</button>
      </form>

    </BaseModal>
  );
};

export default LampadaModal;
