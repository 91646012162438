import { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

const useLampadaData = () => {
  const URL = 'https://api.ads10.com.br/api/lampadatipo';
  const { token, cidadeId, logout } = useContext(AuthContext);
  const [lampadas, setLampadas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Função para buscar e combinar dados dos postes, fotocélulas e mapeamentos
  const fetchData = useCallback(async () => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const lampadaResponse = await fetch(URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      // Tratamento de erros nas requisições
      if (lampadaResponse.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!lampadaResponse.ok) {
        console.log(lampadaResponse.statusText);
        throw new Error(`Erro ao buscar Lampadas: ${lampadaResponse.status} ${lampadaResponse.statusText}`);
      }

      const data = await lampadaResponse.json();
      setLampadas(data);

    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setError(error.message);
      toast.error(`Erro ao buscar dados: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [URL, token, cidadeId, logout]);

  // Criar lampada metodo POST
  const createLampada = useCallback(async (lampada) => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(lampada),
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao criar Lampada: ${response.status} ${response.statusText}`);
      }

      toast.success('Lampada criada com sucesso!');
    }
    catch (error) {
      console.error('Erro ao criar Lampada:', error);
      setError(error.message);
      toast.error(`Erro ao criar Lampada: ${error.message}`);
    } finally {
      setIsLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  // Editar lampada pelo metodo PUT
  const editLampada = useCallback(async (lampadaid, lampada) => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    if (lampadaid == null || lampadaid <= 0) {
      setError('ID da Lampada Invalido');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(URL + '/' + lampadaid, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(lampada),
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao editar Lampada: ${response.status} ${response.statusText}`);
      }

      toast.success('Lampada Editada com sucesso!');
    }
    catch (error) {
      console.error('Erro ao editar Lampada:', error);
      setError(error.message);
      toast.error(`Erro ao editar Lampada: ${error.message}`);
    } finally {
      setIsLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  // Deletar lampada pelo metodo DELETE
  const deleteLampada = useCallback(async (lampadaid) => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    if (lampadaid == null || lampadaid <= 0) {
      setError('ID da Lampada Invalido');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(URL + '/' + lampadaid, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao deletar Lampada: ${response.status} ${response.statusText}`);
      }

      toast.success('Lampada Deletada com sucesso!');
    }
    catch (error) {
      console.error('Erro ao deletar Lampada:', error);
      setError(error.message);
      toast.error(`Erro ao deletar Lampada: ${error.message}`);
    } finally {
      setIsLoading(false);
    }

  }, [URL, token, cidadeId, logout]);


  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { lampadas, isLoading, error, refetch: fetchData, createLampada: createLampada, editLampada: editLampada, deleteLampada: deleteLampada };
};

export default useLampadaData;