import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import styles from './Mapa.module.css';
import Legend from './Legend';
import CompanyLogos from './Logos';
import MarkerModal from '../../components/MarkerModal/MarkerModal';
import SearchBar from '../../components/SearchBar/SearchBar';
import { getMapCenterByCityId } from '../../config';
import { darkMapStyles, lightMapStyles } from '../../config/mapStyles';
import acesaIcon from '../../assets/green-icon.png';
import apagadaIcon from '../../assets/blue-icon.png';
import alertaIcon from '../../assets/orange-icon.png';
import subtensaoIcon from '../../assets/subtensao-icon.png';
import sobretensaoIcon from '../../assets/sobretensao-icon.png';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import MicroAlertWindow from '../../components/MicroAlertWindow/MicroAlertWindow';
import { AuthContext } from '../../context/AuthContext';
import usePosteDataComAlertas from '../../hooks/usePosteDataComAlertas';
import semFotocelulaIcon from '../../assets/grey-icon.png';
import { IoMdWifi } from 'react-icons/io';
import { GiPerson } from 'react-icons/gi'; // Importando GiPerson
import useDevicesSemPoste from '../../hooks/useDevicesSemPoste';
import { renderToStaticMarkup } from 'react-dom/server';
import AtrelarModal from './AtrelarModal';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../config'; // Importando BASE_URL de config.js
import Modal from './Modal'; // Importando o Modal

const containerStyle = {
  width: '100%',
  height: '100vh',
};

const MIN_ZOOM_FOR_LABELS = 20;

// Função para obter o zIndex com base no tipo do marcador
const getMarkerZIndex = (type) => {
  const zIndexMap = {
    alerta: 6,
    sobreTensao: 5,
    subTensao: 4,
    acesa: 3,
    apagada: 2,
    semFotocelula: 1,
    userLocation: 7,
    semPoste: 0,
    device: 1, // Z-Index para dispositivos sem poste
  };
  return zIndexMap[type] || 0;
};

// Função para verificar se a geolocalização é válida
const isValidGeolocation = (lat, lng) => {
  return (
    typeof lat === 'number' &&
    typeof lng === 'number' &&
    lat >= -90 &&
    lat <= 90 &&
    lng >= -180 &&
    lng <= 180
  );
};

// Função para criar o ícone do usuário usando GiPerson
const createUserIcon = () => {
  const personIconMarkup = renderToStaticMarkup(<GiPerson size={24} color="#FF0000" />); // Ajuste o tamanho e a cor conforme necessário
  return {
    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(personIconMarkup)}`,
    scaledSize: new window.google.maps.Size(24, 24), // Ajuste o tamanho conforme necessário
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(12, 12), // Centraliza o ícone
    labelOrigin: new window.google.maps.Point(12, -6), // Ajusta a posição da label, se necessário
  };
};

const Mapa = () => {
  const { token, cidadeId, usuarioId, logout } = useContext(AuthContext);

  // Estados para Postes e Dispositivos Sem Poste
  const [posteMarkers, setPosteMarkers] = useState([]);
  const [deviceSemPosteMarkers, setDeviceSemPosteMarkers] = useState([]);

  // Novo Estado para Todos os Postes
  const [allPostesMarkers, setAllPostesMarkers] = useState([]);

  // Outros Estados
  const [alerts, setAlerts] = useState([]);
  const [totalMarkerCounts, setTotalMarkerCounts] = useState({
    acesa: 0,
    apagada: 0,
    semFotocelula: 0,
    alerta: 0,
    subTensao: 0,
    sobreTensao: 0,
    semPoste: 0,
  });
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visibleMarkers, setVisibleMarkers] = useState({
    acesa: true,
    apagada: true,
    semFotocelula: true,
    alerta: true,
    subTensao: true,
    sobreTensao: true,
    semPoste: true,
  });
  const [searchInput, setSearchInput] = useState('');
  const [timeLeft, setTimeLeft] = useState(180);
  const [highlightedMarker, setHighlightedMarker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mapType, setMapType] = useState('roadmap');
  const [tilt, setTilt] = useState(0);
  const [zoom, setZoom] = useState(17);
  const [showPostIds, setShowPostIds] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  const mapRef = useRef(null);
  
  // Hooks para buscar dados
  const { postesDetalhados, isLoading: isDataLoading, refetch: refetchPostes } = usePosteDataComAlertas(true);
  const { devices: fotocelulasSemPoste, isLoading: isDevicesLoading, refetch: refetchDevicesSemPoste } = useDevicesSemPoste();
  
  const [isAtrelarModalOpen, setIsAtrelarModalOpen] = useState(false);
  const [selectedDeviceData, setSelectedDeviceData] = useState(null);
  const [error, setError] = useState(null); // Para exibir mensagens de erro no modal

  // Estados para o Modal de Informações do Grupo
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [postesDoGrupo, setPostesDoGrupo] = useState([]);
  const [devicesDoGrupo, setDevicesDoGrupo] = useState([]);

  // Novo estado para rastreamento GPS
  const [isGPSTracking, setIsGPSTracking] = useState(false);
  const gpsIntervalRef = useRef(null);

  // Função para abrir o modal de atribuição
  const openAtrelarModal = (deviceData) => {
    setSelectedDeviceData(deviceData);
    setIsAtrelarModalOpen(true);
  };

  // Função para fechar o modal de atribuição
  const closeAtrelarModal = () => {
    setIsAtrelarModalOpen(false);
    setSelectedDeviceData(null);
    setError(null);
  };

  /**
   * Função para atribuir um dispositivo a um poste
   * @param {number|string} posteId - ID do poste selecionado
   * @param {string} fotocelulaId - ID da fotocélula a ser associada
   */
  const atribuirPoste = async (posteId, fotocelulaId) => {
    setIsLoading(true);
    try {
      // Primeiro, buscar associações existentes
      const getResponse = await fetch(`${BASE_URL}/postefotocelula`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (getResponse.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (!getResponse.ok) {
        throw new Error(`Erro ao buscar associações: ${getResponse.statusText}`);
      }

      const associations = await getResponse.json();

      // Verificar se já existe uma associação para o poste e fotocelula
      const existingAssociation = associations.find(
        (assoc) => assoc.posteId === String(posteId) && assoc.fotocelulaId === String(fotocelulaId)
      );

      if (existingAssociation) {
        if (existingAssociation.ativo === 'N') {
          // Reativar a associação existente
          const updatedData = {
            ...existingAssociation,
            ativo: 'S',
            data: new Date().toISOString(),
          };

          const putResponse = await fetch(`${BASE_URL}/postefotocelula/${existingAssociation.postefotocelulaId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(updatedData),
          });

          if (!putResponse.ok) {
            throw new Error(`Erro ao reativar associação: ${putResponse.statusText}`);
          }

          toast.success('Associação reativada com sucesso!');
          refetchPostes(); // Recarregar dados dos postes
          refetchDevicesSemPoste(); // Recarregar dispositivos sem poste
          closeAtrelarModal();
          return;
        } else {
          // Associação já está ativa
          toast.info('A fotocélula já está associada ao poste.');
          return;
        }
      }

      // Se não existir, criar nova associação
      const payload = {
        posteId: posteId,
        fotocelulaId: fotocelulaId,
        usuarioId: usuarioId, // ID do usuário obtido do contexto
        data: new Date().toISOString(),
        ativo: 'S',
        ligado: 'N',
        manutencao: 'N',
        tensaoRede: '0',
      };

      const postResponse = await fetch(`${BASE_URL}/postefotocelula`, { // Usando BASE_URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (postResponse.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (!postResponse.ok) {
        let errorMessage = 'Erro ao atribuir o dispositivo ao poste.';
        try {
          const errorData = await postResponse.json();
          errorMessage = errorData.message || errorMessage;
        } catch (e) {
          const errorText = await postResponse.text();
          errorMessage = errorText || errorMessage;
        }
        throw new Error(errorMessage);
      }

      toast.success('Dispositivo atribuído ao poste com sucesso!');
      refetchPostes(); // Recarregar dados dos postes
      refetchDevicesSemPoste(); // Recarregar dispositivos sem poste
      closeAtrelarModal();
    } catch (error) {
      toast.error(error.message || 'Ocorreu um erro ao atribuir o dispositivo ao poste.');
      setError(error.message || 'Erro ao atribuir o dispositivo ao poste.');
    } finally {
      setIsLoading(false);
    }
  };

  // Função para criar o ícone Wi-Fi como marcador para dispositivos sem poste
  const createWifiIcon = () => {
    const wifiIconMarkup = renderToStaticMarkup(<IoMdWifi size={20} color="#007BFF" />);
    return {
      url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(wifiIconMarkup)}`,
      scaledSize: new window.google.maps.Size(20, 20), // Reduz o tamanho para 20x20
      labelOrigin: new window.google.maps.Point(10, -5),
    };
  };

  // Função para obter os dispositivos associados a um poste
  const getDevicesForPost = (poste) => {
    return poste.fotocelulas || [];
  };

  // Memoização da função getMarkerType para evitar recriações em cada renderização
  const getMarkerType = useCallback((marker) => {
    if (marker.type === 'deviceSemPoste') {
      return 'semPoste';
    }

    const tensao = parseFloat(marker.tensaoRede);

    // Prioridade 1: Verificar se há alerta em aberto
    if (marker.hasOpenAlert) {
      return 'alerta';
    }

    // Prioridade 2: Verificar se é um poste sem fotocélula
    if (!Array.isArray(marker.fotocelulas) || marker.fotocelulas.length === 0) {
      return 'semFotocelula';
    }

    // Prioridade 3: Verificar por subtensão e sobretensão
    if (tensao && tensao < 220 * 0.9) {
      return 'subTensao';
    } else if (tensao && tensao > 220 * 1.1) {
      return 'sobreTensao';
    }

    // Prioridade 4: Verificar o status do poste diretamente
    const statusNormalized = marker.status.trim().toUpperCase();
    if (statusNormalized === '(LIGADO)') {
      return 'acesa';
    } else if (statusNormalized === '(DESLIGADO)') {
      return 'apagada';
    } else {
      return 'apagada'; // padrão
    }
  }, []);

  // Função para obter o ícone do marcador
  const getMarkerIcon = useCallback((marker) => {
    const type = getMarkerType(marker);

    if (type === 'semPoste') {
      return createWifiIcon();
    }

    const iconUrl = (() => {
      switch (type) {
        case 'semFotocelula':
          return semFotocelulaIcon;
        case 'subTensao':
          return subtensaoIcon;
        case 'sobreTensao':
          return sobretensaoIcon;
        case 'alerta':
          return alertaIcon;
        case 'acesa':
          return acesaIcon;
        case 'apagada':
          return apagadaIcon;
        default:
          return apagadaIcon;
      }
    })();

    return {
      url: iconUrl,
      scaledSize: getMarkerSizeByZoom(zoom),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(16, 16),
      labelOrigin: new window.google.maps.Point(11, -10),
    };
  }, [getMarkerType, zoom]);

  // Função para calcular o tamanho do marcador baseado no zoom
  const getMarkerSizeByZoom = (zoomLevel) => {
    const baseSize = 25;
    const maxZoom = 25;
    const minZoom = 5;
    const scaleFactor = Math.min(
      Math.max((zoomLevel - minZoom) / (maxZoom - minZoom), 0.5),
      1.5
    );
    return new window.google.maps.Size(baseSize * scaleFactor, baseSize * scaleFactor);
  };

  // Função para ordenar os marcadores
  const getOrderedMarkers = useCallback(() => {
    return [...posteMarkers, ...deviceSemPosteMarkers].sort((a, b) => {
      const typeA = getMarkerType(a);
      const typeB = getMarkerType(b);
      const zIndexA = getMarkerZIndex(typeA);
      const zIndexB = getMarkerZIndex(typeB);
      return zIndexA - zIndexB;
    });
  }, [posteMarkers, deviceSemPosteMarkers, getMarkerType]);

  // Função memoizada para processar os marcadores e alertas
  const processMarkersAndAlerts = useCallback(() => {
    // Inicializa as contagens para todos os tipos de marcadores
    const counts = {
      acesa: 0,
      apagada: 0,
      semFotocelula: 0,
      alerta: 0,
      subTensao: 0,
      sobreTensao: 0,
      semPoste: 0,
    };

    const totalCounts = { ...counts };

    const openAlertSituations = ['1', '2'];
    const microAlertsTemp = [];
    let postesMarkersTemp = [];

    let allPostesMarkersTemp = []; // Temporário para todos os postes

    // Itera sobre `postesDetalhados` para processar os postes e suas fotocélulas
    postesDetalhados.forEach((poste) => {
      if (!poste.geoLocalizacao || !poste.geoLocalizacao.includes(',')) {
        return;
      }

      const [latStr, lngStr] = poste.geoLocalizacao.split(',').map((coord) => coord.trim());
      const lat = parseFloat(latStr);
      const lng = parseFloat(lngStr);

      if (!isValidGeolocation(lat, lng)) {
        return;
      }

      const markerAlerts = poste.alertas || [];
      const hasOpenAlert = markerAlerts.some((alerta) =>
        openAlertSituations.includes(alerta.alertaSituacao)
      );

      const fotocelulas = Array.isArray(poste.fotocelulas) ? poste.fotocelulas : [];
      let fotocelulasAtivas = 0;

      fotocelulas.forEach((fotocelula) => {
        // Adicionar verificação para fotocelula
        if (!fotocelula) return;

        const statusFotocelula = fotocelula.ligado;
        const dateString = fotocelula.dataUltimaLeitura;
        let leituraHour = null;
        let leituraTimestamp = null;

        if (dateString && dateString !== '0000-00-00 00:00:00') {
          const isoDateString = dateString.replace(' ', 'T');
          const date = new Date(isoDateString);
          if (!isNaN(date.getTime())) {
            leituraTimestamp = date;
            leituraHour = date.getHours();
          }
        }

        // Processa subtensão e sobretensão
        const tensao = parseFloat(fotocelula.tensaoRede);
        if (tensao && tensao < 220 * 0.9) {
          microAlertsTemp.push({
            id: `${poste.id}-${fotocelula.fotocelulaId}-subtensao`,
            alertType: 'Subtensão detectada',
            posteId: poste.id,
            fotocelulaId: fotocelula.fotocelulaId,
            lastReadingDateTime: leituraTimestamp,
          });
        }

        if (tensao && tensao > 220 * 1.1) {
          microAlertsTemp.push({
            id: `${poste.id}-${fotocelula.fotocelulaId}-sobretensao`,
            alertType: 'Sobretensão detectada',
            posteId: poste.id,
            fotocelulaId: fotocelula.fotocelulaId,
            lastReadingDateTime: leituraTimestamp,
          });
        }

        // Processamento de alertas de horário
        if (statusFotocelula === 'L' && leituraHour !== null && leituraHour >= 6 && leituraHour < 18) {
          microAlertsTemp.push({
            id: `${poste.id}-${fotocelula.fotocelulaId}-ligado-dia`,
            alertType: 'Fotocélula ligada durante o dia',
            posteId: poste.id,
            fotocelulaId: fotocelula.fotocelulaId,
            lastReadingDateTime: leituraTimestamp,
          });
        }

        if (statusFotocelula !== 'L' && leituraHour !== null && (leituraHour >= 18 || leituraHour < 6)) {
          microAlertsTemp.push({
            id: `${poste.id}-${fotocelula.fotocelulaId}-desligado-noite`,
            alertType: 'Fotocélula desligada durante a noite',
            posteId: poste.id,
            fotocelulaId: fotocelula.fotocelulaId,
            lastReadingDateTime: leituraTimestamp,
          });
        }

        // Contagem de fotocélulas ativas
        if (statusFotocelula === 'L') {
          fotocelulasAtivas++;
        }
      });

      // Determina o status do poste com base nas fotocélulas
      const statusNormalized = (() => {
        if (fotocelulasAtivas > 0) {
          return '(LIGADO)';
        } else if (fotocelulas.length > 0) {
          return '(DESLIGADO)';
        } else {
          return '(DESCONHECIDO)';
        }
      })();

      const marker = {
        idPoste: poste.id,
        codigoPoste: poste.codigoPoste,
        name: poste.codigoPoste,
        lat,
        lng,
        status: statusNormalized,
        tensaoRede: fotocelulas[0]?.tensaoRede || '0',
        tipo: poste.tipoPoste,
        fotocelulas: fotocelulas,
        fotocelulasCount: fotocelulas.length,
        hasOpenAlert: hasOpenAlert,
        posteData: poste,
        alerts: markerAlerts,
        potencia: poste.potencia && poste.potencia.trim() !== '' ? poste.potencia : 'Não disponível',
        type: 'poste',
      };

      // Adiciona à lista completa de postes
      allPostesMarkersTemp.push(marker);

      // Incrementa as contagens totais antes de filtrar por mapBounds
      const type = getMarkerType(marker);
      if (totalCounts.hasOwnProperty(type)) {
        totalCounts[type]++;
      }

      // Adicionar o marcador se dentro dos bounds
      if (mapBounds) {
        const position = new window.google.maps.LatLng(marker.lat, marker.lng);
        if (!mapBounds.contains(position)) {
          return;
        }
      }

      postesMarkersTemp.push(marker);

      // Incrementa a contagem visível com base no tipo do marcador
      if (counts.hasOwnProperty(type)) {
        counts[type]++;
      }
    });

    // Processa dispositivos sem poste
    let deviceSemPosteMarkersTemp = fotocelulasSemPoste
      .filter(
        (device) =>
          device.latitude !== '0' &&
          device.longitude !== '0' &&
          isValidGeolocation(parseFloat(device.latitude), parseFloat(device.longitude))
      )
      .map((device) => ({
        ...device,
        type: 'deviceSemPoste',
      }));

    // Incrementa as contagens totais para dispositivos sem poste
    counts.semPoste = deviceSemPosteMarkersTemp.length;
    totalCounts.semPoste += deviceSemPosteMarkersTemp.length;

    // Filtrar dispositivos sem poste com base em mapBounds
    if (mapBounds) {
      deviceSemPosteMarkersTemp = deviceSemPosteMarkersTemp.filter((device) => {
        const position = new window.google.maps.LatLng(
          parseFloat(device.latitude),
          parseFloat(device.longitude)
        );
        return mapBounds.contains(position);
      });
    }

    // Atualiza as contagens
    setTotalMarkerCounts({
      ...totalCounts,
    });

    // Atualiza a lista completa de postes
    setAllPostesMarkers(allPostesMarkersTemp);

    // Atualiza os alertas
    setAlerts(microAlertsTemp);

    // Atualiza os marcadores visíveis
    setPosteMarkers(postesMarkersTemp);
    setDeviceSemPosteMarkers(deviceSemPosteMarkersTemp);
  }, [postesDetalhados, fotocelulasSemPoste, mapBounds, getMarkerType]);

  useEffect(() => {
    if (postesDetalhados.length > 0 && !isDevicesLoading) {
      processMarkersAndAlerts();
    }
  }, [postesDetalhados, isDevicesLoading, processMarkersAndAlerts]);

  // Atualiza 'timeLeft' a cada segundo
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTimeLeft) => (prevTimeLeft > 0 ? prevTimeLeft - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  // Recarrega os dados a cada 3 minutos
  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchPostes(); // Recarregar dados dos postes
      refetchDevicesSemPoste(); // Recarregar dispositivos sem poste
      setTimeLeft(180);
    }, 180000); // 180000 ms = 3 minutos

    return () => {
      clearInterval(intervalId);
    };
  }, [refetchPostes, refetchDevicesSemPoste]);

  // Handle zoom changes
  const handleZoomChanged = () => {
    if (mapRef.current) {
      const newZoom = mapRef.current.getZoom();
      setZoom(newZoom);
    }
  };

  // Função para capturar os bounds visíveis do mapa
  const handleOnIdle = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      setMapBounds(bounds);
      setIsLoading(true);
      processMarkersAndAlerts();
      setIsLoading(false);
    }
  };

  const handleMapLoad = (map) => {
    mapRef.current = map;
    map.addListener('zoom_changed', handleZoomChanged);
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
    setIsModalOpen(true);
  };

  // Função para centralizar o mapa no marcador e tocar a animação
  const focusOnMarker = (markerId, isDevice = false) => {
    let targetMarker = null;

    if (!isDevice) {
      targetMarker = allPostesMarkers.find(
        (poste) => poste.idPoste.toString() === markerId.toString()
      );
    } else {
      targetMarker = deviceSemPosteMarkers.find(
        (device) => device.fotocelulaId.toString() === markerId.toString()
      );
    }

    if (
      targetMarker &&
      isValidGeolocation(
        isDevice ? parseFloat(targetMarker.latitude) : targetMarker.lat,
        isDevice ? parseFloat(targetMarker.longitude) : targetMarker.lng
      )
    ) {
      const lat = isDevice
        ? parseFloat(targetMarker.latitude)
        : targetMarker.lat;
      const lng = isDevice
        ? parseFloat(targetMarker.longitude)
        : targetMarker.lng;

      const latLng = new window.google.maps.LatLng(lat, lng);
      mapRef.current.panTo(latLng);
      mapRef.current.setZoom(18);
      highlightMarker(
        isDevice ? `device-${targetMarker.fotocelulaId}` : targetMarker.idPoste,
        isDevice
      );

      // Ajusta os limites do mapa para incluir o marcador
      if (mapRef.current) {
        const bounds = mapRef.current.getBounds();
        const position = new window.google.maps.LatLng(lat, lng);
        if (bounds && !bounds.contains(position)) {
          // Estende os bounds para incluir o marcador
          bounds.extend(position);
          mapRef.current.fitBounds(bounds);
        }
      }
    } else {
      toast.warn('Poste ou dispositivo não encontrado ou localização indisponível.');
    }
  };

  const focusOnDevice = (device) => {
    const lat = parseFloat(device.latitude);
    const lng = parseFloat(device.longitude);

    if (isValidGeolocation(lat, lng) && mapRef.current) {
      const latLng = new window.google.maps.LatLng(lat, lng);
      mapRef.current.panTo(latLng);
      mapRef.current.setZoom(18);
      highlightMarker(`device-${device.fotocelulaId}`, true);
    } else {
      toast.warn('Localização do dispositivo inválida ou indisponível.');
    }
  };

  const handleAlertClick = (alert) => {
    focusOnMarker(alert.posteId);
  };

  // Função para remover o alerta da lista
  const handleCloseAlert = (alertId) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== alertId));
  };

  const highlightMarker = (markerId, isDevice = false) => {
    setHighlightedMarker(markerId);
    setTimeout(() => setHighlightedMarker(null), 2000);
  };

  const shouldShowMarker = (marker) => {
    const type = marker.type === 'poste' ? getMarkerType(marker) : 'semPoste';
    return visibleMarkers[type];
  };

  const toggleMarkerVisibility = (type) => {
    setVisibleMarkers((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  // **Função Atualizada para Pesquisar Postes e Dispositivos**
  const handleSearchEnhanced = () => {
    const cleanedSearchInput = searchInput.trim();
    if (!cleanedSearchInput) {
      toast.warn('Por favor, insira um termo de pesquisa válido.');
      return;
    }

    // Converter a entrada de pesquisa para número
    const inputNumber = parseInt(cleanedSearchInput, 10);
    if (isNaN(inputNumber)) {
      toast.warn('Por favor, insira um número válido para pesquisa de postes.');
      return;
    }

    // Primeiro, tentar encontrar um poste com base no ID ou no código sem prefixo
    const foundPoste = postesDetalhados.find((marker) => {
      if (!marker) return false; // Verifica se o marker existe
      const { id, codigoPoste } = marker;

      // Verifica se o ID do poste corresponde
      if (id && parseInt(id, 10) === inputNumber) {
        return true;
      }

      // Extrai a parte numérica do código do poste, ignorando o prefixo
      if (codigoPoste) {
        const numericPartStr = codigoPoste.replace(/^\D+/g, ''); // Remove todos os caracteres não numéricos do início
        const numericPart = parseInt(numericPartStr, 10);
        if (!isNaN(numericPart) && numericPart === inputNumber) {
          return true;
        }
      }

      return false;
    });

    if (foundPoste) {
      focusOnMarker(foundPoste.id); // Usa 'id' conforme o exemplo de dados
      return;
    }

    // Se não encontrar um poste, tentar encontrar um dispositivo sem poste
    const foundDeviceSemPoste = deviceSemPosteMarkers.find((marker) => {
      if (!marker) return false; // Verifica se o marker existe
      const { fotocelulaId, deviceEUI, descricao } = marker;
      return (
        (fotocelulaId && fotocelulaId.toString() === cleanedSearchInput) ||
        (deviceEUI && deviceEUI.toLowerCase() === cleanedSearchInput.toLowerCase()) ||
        (descricao && descricao.toLowerCase() === cleanedSearchInput.toLowerCase())
      );
    });

    if (foundDeviceSemPoste) {
      focusOnDevice(foundDeviceSemPoste);
      return;
    }

    // Se ainda não encontrar, procurar em dispositivos associados a postes
    let foundDeviceAssociado = null;
    let associatedPosteId = null;

    // Iterar sobre todos os postes para encontrar o dispositivo
    postesDetalhados.forEach((poste) => {
      if (!poste || !Array.isArray(poste.fotocelulas)) return; // Verifica se o poste e suas fotocelulas existem

      const device = poste.fotocelulas.find((fc) => {
        if (!fc) return false; // Verifica se a fotocelula existe
        const { fotocelulaId, deviceEUI, descricao } = fc;
        return (
          (fotocelulaId && fotocelulaId.toString() === cleanedSearchInput) ||
          (deviceEUI && deviceEUI.toLowerCase() === cleanedSearchInput.toLowerCase()) ||
          (descricao && descricao.toLowerCase() === cleanedSearchInput.toLowerCase())
        );
      });
      if (device) {
        foundDeviceAssociado = device;
        associatedPosteId = poste.id; // Usa 'id' conforme o exemplo de dados
      }
    });

    if (foundDeviceAssociado && associatedPosteId) {
      focusOnMarker(associatedPosteId);
      return;
    }

    // Se não encontrar nada
    toast.warn('Nenhum poste ou dispositivo encontrado com o termo de pesquisa fornecido.');
  };

  // Função para alternar a visibilidade das labels
  const toggleLabels = () => {
    setShowPostIds((prevShowPostIds) => !prevShowPostIds);
  };

  // Função para focar na localização do usuário e alternar o modo GPS
  const focusOnUserLocationToggle = () => {
    if (isGPSTracking) {
      // Desativar o modo GPS
      setIsGPSTracking(false);
      if (gpsIntervalRef.current) {
        clearInterval(gpsIntervalRef.current);
        gpsIntervalRef.current = null;
      }
      return;
    }

    if (navigator.geolocation) {
      // Ativar o modo GPS
      setIsGPSTracking(true);

      // Função para obter a localização do usuário
      const updateUserLocation = () => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const newLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            if (isValidGeolocation(newLocation.lat, newLocation.lng)) {
              setUserLocation(newLocation);
              if (mapRef.current) {
                mapRef.current.panTo(newLocation);
                mapRef.current.setZoom(18);
              }
              setLocationError(null);
            } else {
              setLocationError('Geolocalização inválida.');
              toast.error('Geolocalização inválida.');
            }
          },
          () => {
            setLocationError('Não foi possível obter sua localização.');
            toast.error('Não foi possível obter sua localização.');
          }
        );
      };

      // Atualiza a localização imediatamente
      updateUserLocation();

      // Define o intervalo para atualizar a localização a cada 5 segundos
      gpsIntervalRef.current = setInterval(updateUserLocation, 5000); // 5000 ms = 5 segundos
    } else {
      setLocationError('Geolocalização não é suportada pelo seu navegador.');
      toast.error('Geolocalização não é suportada pelo seu navegador.');
    }
  };

  // Função para alternar tipo de mapa
  const toggleSatelliteView = () => {
    setMapType((prevMapType) => (prevMapType === 'roadmap' ? 'satellite' : 'roadmap'));
  };

  // Função para alternar inclinação
  const toggleTilt = () => {
    setTilt((prevTilt) => {
      const newTilt = prevTilt === 0 ? 45 : 0;
      if (mapRef.current) {
        mapRef.current.setTilt(newTilt);
      }
      return newTilt;
    });
  };

  // Função para lidar com cliques no ícone de info da legenda
  const handleGroupInfoClick = (groupType) => {
    let filteredPostes = [];
    let filteredDevices = [];

    switch (groupType) {
      case 'acesa':
        filteredPostes = allPostesMarkers.filter((poste) => getMarkerType(poste) === 'acesa');
        break;
      case 'apagada':
        filteredPostes = allPostesMarkers.filter((poste) => getMarkerType(poste) === 'apagada');
        break;
      case 'semFotocelula':
        filteredPostes = allPostesMarkers.filter((poste) => getMarkerType(poste) === 'semFotocelula');
        break;
      case 'alerta':
        filteredPostes = allPostesMarkers.filter((poste) => getMarkerType(poste) === 'alerta');
        break;
      case 'subTensao':
        filteredPostes = allPostesMarkers.filter((poste) => getMarkerType(poste) === 'subTensao');
        break;
      case 'sobreTensao':
        filteredPostes = allPostesMarkers.filter((poste) => getMarkerType(poste) === 'sobreTensao');
        break;
      case 'semPoste':
        // Para 'semPoste', listar dispositivos sem poste
        filteredDevices = deviceSemPosteMarkers;
        break;
      default:
        filteredPostes = [];
    }

    if (groupType !== 'semPoste') {
      // Coletar dispositivos dos postes filtrados
      filteredDevices = filteredPostes.flatMap((poste) => poste.fotocelulas || []);
    }

    setSelectedGroup(groupType);
    setPostesDoGrupo(filteredPostes);
    setDevicesDoGrupo(filteredDevices);
    setIsInfoModalOpen(true);
  };

  // Função para obter o nome legível do grupo
  const getGroupName = (groupType) => {
    switch (groupType) {
      case 'acesa':
        return 'Acesa';
      case 'apagada':
        return 'Apagada';
      case 'semFotocelula':
        return 'Sem Fotocélula';
      case 'alerta':
        return 'Alerta';
      case 'subTensao':
        return 'Subtensão';
      case 'sobreTensao':
        return 'Sobretensão';
      case 'semPoste':
        return 'Dispositivos sem Poste';
      default:
        return 'Grupo Desconhecido';
    }
  };

  // Limpeza do intervalo ao desmontar o componente
  useEffect(() => {
    return () => {
      if (gpsIntervalRef.current) {
        clearInterval(gpsIntervalRef.current);
      }
    };
  }, []);

  return (
    <div className={styles.mapPage}>
      {/* Exibe o overlay de carregamento se qualquer estado de carregamento estiver ativo */}
      {(isDevicesLoading || isLoading || isDataLoading) && <LoadingOverlay />}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={getMapCenterByCityId(cidadeId)}
        zoom={zoom}
        mapTypeId={mapType}
        tilt={tilt}
        options={{
          styles: mapType === 'roadmap' ? lightMapStyles : darkMapStyles,
          disableDefaultUI: true,
          zoomControl: true,
        }}
        onLoad={handleMapLoad}
        onIdle={handleOnIdle}
      >
        {/* Renderiza todos os marcadores via getOrderedMarkers */}
        {getOrderedMarkers().map((marker) => {
          if (!shouldShowMarker(marker)) return null; // Verifica visibilidade

          if (marker.type === 'deviceSemPoste') {
            return (
              <Marker
                key={`deviceSemPoste-${marker.fotocelulaId}`}
                position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
                icon={createWifiIcon()}
                title={`Dispositivo sem Poste: ${marker.descricao}`}
                onClick={() =>
                  openAtrelarModal({
                    fotocelulaId: marker.fotocelulaId,
                    deviceEUI: marker.deviceEUI,
                    descricao: marker.descricao,
                    latitude: marker.latitude,
                    longitude: marker.longitude,
                    ultimaAtualizacao: marker.deviceAtualizacao || 'Não disponível',
                  })
                }
                animation={
                  highlightedMarker === `device-${marker.fotocelulaId}`
                    ? window.google.maps.Animation.BOUNCE
                    : null
                }
              />
            );
          } else if (marker.type === 'poste') {
            return (
              <Marker
                key={`poste-${marker.idPoste}`}
                position={{ lat: marker.lat, lng: marker.lng }}
                icon={getMarkerIcon(marker)}
                zIndex={getMarkerZIndex(getMarkerType(marker))}
                animation={
                  highlightedMarker === marker.idPoste
                    ? window.google.maps.Animation.BOUNCE
                    : null
                }
                onClick={() => handleMarkerClick(marker)}
                title={`Código do Poste: ${marker.codigoPoste}`}
                label={
                  showPostIds && zoom >= MIN_ZOOM_FOR_LABELS
                    ? {
                        text:
                          marker.codigoPoste ||
                          marker.idPoste.toString().padStart(9, '0'),
                        color: 'black',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }
                    : undefined
                }
              />
            );
          }
          return null;
        })}

        {userLocation && (
          <Marker
            position={userLocation}
            icon={createUserIcon()} // Utilizando a nova função para criar o ícone do usuário
            zIndex={getMarkerZIndex('userLocation')}
            title="Minha Localização"
          />
        )}
      </GoogleMap>

      {/* Legend */}
      <Legend
        totalMarkerCounts={totalMarkerCounts}
        toggleMarkerVisibility={toggleMarkerVisibility}
        visibleMarkers={visibleMarkers}
        onGroupInfoClick={handleGroupInfoClick} // Passando a função para o Legend
      />

      {/* Company Logos */}
      <CompanyLogos />

      {/* Barra de Ferramentas */}
      <SearchBar
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        handleSearch={handleSearchEnhanced}
        toggleLabels={toggleLabels}
        toggleSatelliteView={toggleSatelliteView}
        focusOnUserLocation={focusOnUserLocationToggle} // Usando a nova função
        toggleTilt={toggleTilt}
        labelsVisible={showPostIds}
        isGPSTracking={isGPSTracking} // Passando o estado para o SearchBar
      />

      {/* Modal de Detalhes do Marcador */}
      {selectedMarker && (
        <MarkerModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          markerData={selectedMarker}
          cidadeId={cidadeId}
        />
      )}

      {/* MicroAlertWindow */}
      <MicroAlertWindow
        alerts={alerts}
        onAlertClick={handleAlertClick}
        onCloseAlert={handleCloseAlert}
      />

      {/* Barra de Carregamento de Atualização */}
      <div className={styles.loadingBarContainer}>
        <div
          className={styles.loadingBar}
          style={{ width: `${(180 - timeLeft) / 1.8}%` }}
        ></div>
        <div className={styles.loadingText}>
          Atualização em {Math.floor(timeLeft / 60)}:
          {(timeLeft % 60).toString().padStart(2, '0')}
        </div>
      </div>

      {/* Erro de Localização */}
      {locationError && <div className={styles.locationError}>{locationError}</div>}

      {/* Modal de Associação */}
      <AtrelarModal
        isOpen={isAtrelarModalOpen}
        onClose={closeAtrelarModal}
        markerData={selectedDeviceData}
        atribuirPoste={atribuirPoste}
        postesDropdown={postesDetalhados}
        markerType="dispositivo"
        error={error}
        setError={setError}
      />

      {/* Modal de Informações do Grupo */}
      <Modal
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
        title={`Detalhes - ${getGroupName(selectedGroup)}`}
      >
        {selectedGroup !== 'semPoste' && postesDoGrupo.length > 0 && (
          <>
            <h3>Postes e Dispositivos Associados</h3>
            <table className={styles.combinedTable}>
              <thead>
                <tr>
                  <th>ID do Poste</th>
                  <th>Código do Poste</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th>Status</th>
                  <th>Potência</th>
                  <th>Dispositivos Associados</th>
                </tr>
              </thead>
              <tbody>
                {postesDoGrupo.map((poste) => (
                  <tr
                    key={poste.idPoste}
                    onClick={() => focusOnMarker(poste.idPoste)}
                    className={styles.posteRow}
                  >
                    <td>{poste.idPoste}</td>
                    <td>{poste.codigoPoste}</td>
                    <td>{poste.lat.toFixed(6)}</td>
                    <td>{poste.lng.toFixed(6)}</td>
                    <td>{poste.status}</td>
                    <td>{poste.potencia}</td>
                    <td>
                      {getDevicesForPost(poste).length > 0 ? (
                        <ul className={styles.deviceList}>
                          {getDevicesForPost(poste).map((device) => (
                            <li
                              key={device.fotocelulaId}
                              className={styles.deviceItem}
                            >
                              <strong>ID:</strong> {device.fotocelulaId} <br />
                              <strong>Device EUI:</strong> {device.deviceEUI} <br />
                              <strong>Descrição:</strong> {device.descricao} <br />
                              <strong>Última Atualização:</strong>{' '}
                              {device.deviceAtualizacao
                                ? new Date(device.deviceAtualizacao).toLocaleString()
                                : 'Não disponível'}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        'Nenhum dispositivo associado.'
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}

        {selectedGroup === 'semPoste' && devicesDoGrupo.length > 0 && (
          <>
            <h3>Dispositivos sem Poste</h3>
            <table className={styles.combinedTable}>
              <thead>
                <tr>
                  <th>ID da Fotocélula</th>
                  <th>Device EUI</th>
                  <th>Descrição</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th>Última Atualização</th>
                </tr>
              </thead>
              <tbody>
                {devicesDoGrupo.map((device) => (
                  <tr
                    key={device.fotocelulaId}
                    className={styles.deviceRow}
                  >
                    <td>{device.fotocelulaId}</td>
                    <td>{device.deviceEUI}</td>
                    <td>{device.descricao}</td>
                    <td>{parseFloat(device.latitude).toFixed(6)}</td>
                    <td>{parseFloat(device.longitude).toFixed(6)}</td>
                    <td>
                      {device.deviceAtualizacao
                        ? new Date(device.deviceAtualizacao).toLocaleString()
                        : 'Não disponível'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}

        {(
          (selectedGroup !== 'semPoste' && postesDoGrupo.length === 0) ||
          (selectedGroup === 'semPoste' && devicesDoGrupo.length === 0)
        ) && <p>Nenhum dado encontrado para este grupo.</p>}
      </Modal>

    </div>
  );
};

export default Mapa;
