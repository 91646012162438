// src/components/ChartCard/ChartCard.jsx

import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LineChart,
  Line,
} from 'recharts';
import styles from './ChartCard.module.css';

const ChartCard = ({
  title,
  type,
  data,
  dataKey,
  colors,
  compactMode,
  additionalProps,
}) => {
  const renderChart = () => {
    switch (type) {
      case 'pie':
        return (
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={0}
              outerRadius={compactMode ? 80 : 100} // Raio ajustado no modo compacto
              dataKey={dataKey}
              label={!compactMode} // Exibir labels apenas no modo completo
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        );
      case 'bar':
        return (
          <BarChart data={data} {...additionalProps}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Bar dataKey={dataKey} fill={colors[0]} />
          </BarChart>
        );
      case 'line':
        return (
          <LineChart data={data} {...additionalProps}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="day"
              label={{ value: 'Dia', position: 'insideBottomRight', offset: -5 }}
            />
            <YAxis
              allowDecimals={false}
              label={{ value: 'Quantidade', angle: -90, position: 'insideLeft' }}
            />
            <Tooltip />
            <Line
              type="monotone"
              dataKey={dataKey}
              stroke={colors[0]}
              activeDot={{ r: 4 }} // Reduzido de 8 para 4 no modo compacto
            />
          </LineChart>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`${styles.card} ${compactMode ? styles.compact : ''}`}>
      <h2 className={styles.title}>{title}</h2>
      <ResponsiveContainer width="100%" height={compactMode ? 220 : 300}>
        {renderChart()}
      </ResponsiveContainer>
      {!compactMode && type === 'pie' && (
        <ul className={styles.legend}>
          {data.map((entry, index) => (
            <li key={`legend-${index}`} className={styles.legendItem}>
              <span
                className={styles.legendColor}
                style={{ backgroundColor: colors[index % colors.length] }}
              ></span>
              {entry.name}: {entry.value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ChartCard;
