// src/pages/Mapa/Legend.jsx

import React, { useState, useEffect } from 'react';
import { IoMdWifi } from 'react-icons/io';
import { FiHelpCircle } from 'react-icons/fi'; // Importando ícones
import styles from './Legend.module.css'; // Utilizando o CSS da Legenda
import acesaIcon from '../../assets/green-icon.png';
import apagadaIcon from '../../assets/blue-icon.png';
import semFotocelulaIcon from '../../assets/grey-icon.png';
import alertaIcon from '../../assets/orange-icon.png';
import subtensaoIcon from '../../assets/subtensao-icon.png';
import sobretensaoIcon from '../../assets/sobretensao-icon.png';

const Legend = ({ totalMarkerCounts, toggleMarkerVisibility, visibleMarkers, onGroupInfoClick }) => {
  const [isMinimized, setIsMinimized] = useState(false);

  const safeTotalMarkerCounts = totalMarkerCounts || {
    acesa: 0,
    apagada: 0,
    semFotocelula: 0,
    alerta: 0,
    subTensao: 0,
    sobreTensao: 0,
    semPoste: 0,
  };

  const safeVisibleMarkers = visibleMarkers || {
    acesa: true,
    apagada: true,
    semFotocelula: true,
    alerta: true,
    subTensao: true,
    sobreTensao: true,
    semPoste: true,
  };

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  useEffect(() => {
    console.log("Dados recebidos na legenda:", { totalMarkerCounts, visibleMarkers });
  }, [totalMarkerCounts, visibleMarkers]);

  return (
    <div className={`${styles.legendContainer} ${isMinimized ? styles.minimized : ''}`}>
      <div className={styles.legendHeader}>
        <h3 className={styles.legendTitle}>Legenda</h3>
        <button
          className={styles.minimizeButton}
          onClick={toggleMinimize}
          aria-label={isMinimized ? 'Expandir Legenda' : 'Minimizar Legenda'}
        >
        </button>
      </div>
      {!isMinimized && (
        <ul className={styles.legendList}>
          <li
            onClick={() => toggleMarkerVisibility('acesa')}
            className={!safeVisibleMarkers.acesa ? styles.inactive : ''}
          >
            <img src={acesaIcon} alt="Acesa" className={styles.icon} />
            <span>Acesa ({safeTotalMarkerCounts.acesa})</span>
            <FiHelpCircle
              className={styles.infoIcon}
              onClick={(e) => {
                e.stopPropagation(); // Impede que o clique no ícone acione o toggle
                onGroupInfoClick('acesa');
              }}
              title="Ver detalhes"
            />
          </li>
          <li
            onClick={() => toggleMarkerVisibility('apagada')}
            className={!safeVisibleMarkers.apagada ? styles.inactive : ''}
          >
            <img src={apagadaIcon} alt="Apagada" className={styles.icon} />
            <span>Apagada ({safeTotalMarkerCounts.apagada})</span>
            <FiHelpCircle
              className={styles.infoIcon}
              onClick={(e) => {
                e.stopPropagation();
                onGroupInfoClick('apagada');
              }}
              title="Ver detalhes"
            />
          </li>
          <li
            onClick={() => toggleMarkerVisibility('semFotocelula')}
            className={!safeVisibleMarkers.semFotocelula ? styles.inactive : ''}
          >
            <img src={semFotocelulaIcon} alt="Sem Fotocélula" className={styles.icon} />
            <span>Sem Fotocélula ({safeTotalMarkerCounts.semFotocelula})</span>
            <FiHelpCircle
              className={styles.infoIcon}
              onClick={(e) => {
                e.stopPropagation();
                onGroupInfoClick('semFotocelula');
              }}
              title="Ver detalhes"
            />
          </li>
          <li
            onClick={() => toggleMarkerVisibility('alerta')}
            className={!safeVisibleMarkers.alerta ? styles.inactive : ''}
          >
            <img src={alertaIcon} alt="Alerta" className={styles.icon} />
            <span>Alerta ({safeTotalMarkerCounts.alerta})</span>
            <FiHelpCircle
              className={styles.infoIcon}
              onClick={(e) => {
                e.stopPropagation();
                onGroupInfoClick('alerta');
              }}
              title="Ver detalhes"
            />
          </li>
          <li
            onClick={() => toggleMarkerVisibility('subTensao')}
            className={!safeVisibleMarkers.subTensao ? styles.inactive : ''}
          >
            <img src={subtensaoIcon} alt="Subtensão" className={styles.icon} />
            <span>Subtensão ({safeTotalMarkerCounts.subTensao})</span>
            <FiHelpCircle
              className={styles.infoIcon}
              onClick={(e) => {
                e.stopPropagation();
                onGroupInfoClick('subTensao');
              }}
              title="Ver detalhes"
            />
          </li>
          <li
            onClick={() => toggleMarkerVisibility('sobreTensao')}
            className={!safeVisibleMarkers.sobreTensao ? styles.inactive : ''}
          >
            <img src={sobretensaoIcon} alt="Sobretensão" className={styles.icon} />
            <span>Sobretensão ({safeTotalMarkerCounts.sobreTensao})</span>
            <FiHelpCircle
              className={styles.infoIcon}
              onClick={(e) => {
                e.stopPropagation();
                onGroupInfoClick('sobreTensao');
              }}
              title="Ver detalhes"
            />
          </li>
          <li
            onClick={() => toggleMarkerVisibility('semPoste')}
            className={!safeVisibleMarkers.semPoste ? styles.inactive : ''}
          >
            <IoMdWifi size={20} color="#007BFF" className={styles.icon} />
            <span>Disp. sem Poste ({safeTotalMarkerCounts.semPoste})</span>
            <FiHelpCircle
              className={styles.infoIcon}
              onClick={(e) => {
                e.stopPropagation();
                onGroupInfoClick('semPoste');
              }}
              title="Ver detalhes"
            />
          </li>
        </ul>
      )}
    </div>
  );
};

Legend.defaultProps = {
  totalMarkerCounts: {
    acesa: 0,
    apagada: 0,
    semFotocelula: 0,
    alerta: 0,
    subTensao: 0,
    sobreTensao: 0,
    semPoste: 0,
  },
  toggleMarkerVisibility: () => {},
  visibleMarkers: {
    acesa: true,
    apagada: true,
    semFotocelula: true,
    alerta: true,
    subTensao: true,
    sobreTensao: true,
    semPoste: true,
  },
  onGroupInfoClick: () => {},
};

export default Legend;
