// src/components/GarantiasProdutosModal/GarantiasProdutosModal.js

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styles from './GarantiasProdutosModal.js'; // Crie este arquivo CSS

Modal.setAppElement('#root'); // Ajuste conforme seu elemento root

const GarantiasProdutosModal = ({ isOpen, onRequestClose, onSave, initialData }) => {
  const [formData, setFormData] = useState({
    grupo: '',
    componente: '',
    dataCriacao: '',
    dataCompra: '',
    descricao: '',
    quantidade: 0,
    garantiaMeses: 0,
  });

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    } else {
      setFormData({
        grupo: '',
        componente: '',
        dataCriacao: '',
        dataCompra: '',
        descricao: '',
        quantidade: 0,
        garantiaMeses: 0,
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Adicionar/Editar Lote"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <h2>{initialData ? 'Editar Lote' : 'Adicionar Novo Lote'}</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <label>
          Grupo:
          <input
            type="text"
            name="grupo"
            value={formData.grupo}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Componente:
          <input
            type="text"
            name="componente"
            value={formData.componente}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Data de Criação:
          <input
            type="datetime-local"
            name="dataCriacao"
            value={formData.dataCriacao}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Data de Compra/Entrega:
          <input
            type="date"
            name="dataCompra"
            value={formData.dataCompra}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Descrição:
          <textarea
            name="descricao"
            value={formData.descricao}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Quantidade:
          <input
            type="number"
            name="quantidade"
            value={formData.quantidade}
            onChange={handleChange}
            required
            min="1"
          />
        </label>
        <label>
          Tempo de Garantia (Meses):
          <input
            type="number"
            name="garantiaMeses"
            value={formData.garantiaMeses}
            onChange={handleChange}
            required
            min="1"
          />
        </label>
        <div className={styles.buttons}>
          <button type="submit" className={styles.saveButton}>
            Salvar
          </button>
          <button type="button" onClick={onRequestClose} className={styles.cancelButton}>
            Cancelar
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default GarantiasProdutosModal;
