import React from 'react';
import { FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import styles from './BaseModal.module.css';

Modal.setAppElement('#root');

const BaseModal = ({ contentLabel, isOpen, onRequestClose, children }) => {

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      className={styles.modal}
      overlayClassName={styles.overlay}>

      <button className={styles.closeModalButton} onClick={onRequestClose}>
        <FaTimes />
      </button>
      <div className={styles.modalBody}>
        {children}
      </div>

    </Modal>);


};

export default BaseModal;