// src/pages/GarantiasProdutos/GarantiasProdutos.js

import React, { useState, useMemo } from 'react';
import { FaPlus, FaEdit, FaTrash, FaSortUp, FaSortDown } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import PageLayout from '../../components/PageLayout/PageLayout';
import GarantiasProdutosModal from './GarantiasProdutosModal'; // Importe o modal
import styles from './GarantiasProdutos.module.css'; // Crie este arquivo CSS com base no exemplo dado

const GarantiasProdutos = () => {
  const [lotes, setLotes] = useState([
    {
      id: 1,
      grupo: 'Eletrônicos',
      componente: 'Smartphone',
      dataCriacao: '2024-12-01T14:30',
      dataCompra: '2024-11-20',
      descricao: 'Lote de smartphones modelo X.',
      quantidade: 50,
      garantiaMeses: 12,
    },
    // Adicione outros lotes para simular dados
  ]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentLote, setCurrentLote] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const calculateTimeToExpire = (garantiaMeses, dataCompra) => {
    const compraDate = new Date(dataCompra);
    const expiryDate = new Date(compraDate.setMonth(compraDate.getMonth() + garantiaMeses));
    const today = new Date();
    const diffTime = Math.max(expiryDate - today, 0);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedLotes = useMemo(() => {
    const sorted = [...lotes];
    if (sortConfig.key) {
      sorted.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  }, [lotes, sortConfig]);

  const pageCount = Math.ceil(sortedLotes.length / itemsPerPage);

  const currentPageData = useMemo(() => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return sortedLotes.slice(start, end);
  }, [sortedLotes, currentPage, itemsPerPage]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  // Função para abrir o modal de edição
  const handleEditLote = (lote) => {
    setCurrentLote(lote);
    setModalIsOpen(true);
  };

  // Função para abrir o modal de criação
  const handleAddNew = () => {
    setCurrentLote(null);
    setModalIsOpen(true);
  };

  // Função para salvar (criar ou atualizar) um lote
  const handleSaveLote = (lote) => {
    if (lote.id) {
      // Atualizar lote existente
      setLotes((prevLotes) =>
        prevLotes.map((item) => (item.id === lote.id ? lote : item))
      );
    } else {
      // Criar novo lote
      const newLote = { ...lote, id: Date.now() };
      setLotes((prevLotes) => [...prevLotes, newLote]);
    }
  };

  // Função para deletar um lote
  const handleDeleteLote = (id) => {
    const confirmDelete = window.confirm('Tem certeza que deseja deletar este lote?');
    if (!confirmDelete) return;

    setLotes((prevLotes) => prevLotes.filter((lote) => lote.id !== id));
  };

  return (
    <PageLayout title="Cadastro de Garantias de Produtos">
      <div className={styles.container}>
        <div className={styles.controlsTop}>
          <button className={styles.addButton} onClick={handleAddNew}>
            <FaPlus /> Adicionar Novo Lote
          </button>
        </div>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th onClick={() => requestSort('grupo')}>
                  Grupo {sortConfig.key === 'grupo' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('componente')}>
                  Componente {sortConfig.key === 'componente' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('dataCriacao')}>
                  Data Criação {sortConfig.key === 'dataCriacao' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('dataCompra')}>
                  Data Compra {sortConfig.key === 'dataCompra' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th>Descrição</th>
                <th onClick={() => requestSort('quantidade')}>
                  Quantidade {sortConfig.key === 'quantidade' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('garantiaMeses')}>
                  Garantia (Meses) {sortConfig.key === 'garantiaMeses' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th>Tempo Restante</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.length > 0 ? (
                currentPageData.map((lote) => {
                  const diasRestantes = calculateTimeToExpire(lote.garantiaMeses, lote.dataCompra);
                  const rowStyle = {
                    backgroundColor:
                      diasRestantes <= 30 ? '#f8d7da' :
                      diasRestantes <= 60 ? '#fff3cd' :
                      'inherit',
                    color: diasRestantes <= 30 ? '#721c24' :
                           diasRestantes <= 60 ? '#856404' :
                           'inherit',
                  };
                  return (
                    <tr key={lote.id} style={rowStyle}>
                      <td>{lote.grupo}</td>
                      <td>{lote.componente}</td>
                      <td>{new Date(lote.dataCriacao).toLocaleString()}</td>
                      <td>{new Date(lote.dataCompra).toLocaleDateString()}</td>
                      <td>{lote.descricao}</td>
                      <td>{lote.quantidade}</td>
                      <td>{lote.garantiaMeses}</td>
                      <td>{diasRestantes} dias</td>
                      <td>
                        <button className={styles.actionButton} onClick={() => handleEditLote(lote)} title="Editar">
                          <FaEdit />
                        </button>
                        <button
                          className={`${styles.actionButton} ${styles.deleteButton}`}
                          onClick={() => handleDeleteLote(lote.id)}
                          title="Deletar"
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="9" className={styles.noData}>
                    Nenhum lote encontrado.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={styles.paginationContainer}>
          <ReactPaginate
            previousLabel={'← Anterior'}
            nextLabel={'Próximo →'}
            breakLabel={'...'}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={styles.pagination}
            activeClassName={styles.paginationActive}
          />
        </div>
        {modalIsOpen && (
          <GarantiasProdutosModal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            onSave={handleSaveLote}
            initialData={currentLote}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default GarantiasProdutos;
