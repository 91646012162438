// src/pages/PosteGrupos/PosteGrupos.jsx

import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { GoogleMap, Marker, Polygon, Circle } from '@react-google-maps/api';
import { FaMapPin } from 'react-icons/fa';
import { renderToStaticMarkup } from 'react-dom/server';
import styles from './PosteGrupos.module.css';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import usePosteData from '../../hooks/usePosteData';
import useGroupPoste from '../../hooks/useGroupPoste';
import useGruposData from '../../hooks/useGruposData';
import { AuthContext } from '../../context/AuthContext';
import { getMapCenterByCityId } from '../../config';
import SearchBar from '../../components/SearchBar/SearchBar';
import FerramentasBarra from './FerramentasBarra';
import JanelaGrupos from './JanelaGrupos';

const containerStyle = {
  width: '100%',
  height: '100vh',
};

/**
 * Função para validar as coordenadas geográficas.
 * @param {number} lat - Latitude.
 * @param {number} lng - Longitude.
 * @returns {boolean} - Retorna true se as coordenadas forem válidas.
 */
const isValidGeolocation = (lat, lng) => {
  return (
    typeof lat === 'number' &&
    typeof lng === 'number' &&
    lat >= -90 &&
    lat <= 90 &&
    lng >= -180 &&
    lng <= 180
  );
};

/**
 * Função para criar um ícone personalizado para o marcador.
 * @param {boolean} isToAdd - Indica se o marcador está marcado para adicionar.
 * @param {boolean} isToRemove - Indica se o marcador está marcado para remover.
 * @param {boolean} belongsToSelectedGroup - Indica se o marcador pertence ao grupo selecionado.
 * @param {string} groupColor - Cor do grupo selecionado.
 * @returns {object} - Retorna o objeto de ícone personalizado.
 */
const createCustomIcon = (isToAdd, isToRemove, belongsToSelectedGroup, groupColor) => {
  let color = '#7d8ca3'; // Cor padrão para postes que não pertencem ao grupo

  if (belongsToSelectedGroup) {
    color = groupColor || '#7d8ca3'; // Cor do grupo
  }

  if (isToAdd) {
    color = '#00FF00'; // Cor para adição (verde)
  }

  if (isToRemove) {
    color = '#FF0000'; // Cor para remoção (vermelho)
  }

  const iconMarkup = renderToStaticMarkup(<FaMapPin size={16} color={color} />);

  return {
    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(iconMarkup)}`,
    scaledSize: new window.google.maps.Size(24, 24), // Aumentado para melhor visualização
    anchor: new window.google.maps.Point(12, 24),
  };
};

const PosteGrupos = () => {
  const { cidadeId } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [zoom, setZoom] = useState(17);
  const [mapBounds, setMapBounds] = useState(null);
  const [filteredMarkers, setFilteredMarkers] = useState([]);
  const [markersToAdd, setMarkersToAdd] = useState([]); // Postes para adicionar
  const [markersToRemove, setMarkersToRemove] = useState([]); // Postes para remover
  const [searchInput, setSearchInput] = useState('');
  const [mapType, setMapType] = useState('roadmap');
  const [isEditing, setIsEditing] = useState(false);
  const [activeTool, setActiveTool] = useState('click'); // Ferramenta ativa
  const [selectedGroup, setSelectedGroup] = useState(null); // Grupo selecionado

  // Estados para seleção poligonal
  const [polygonPoints, setPolygonPoints] = useState([]); // Pontos da seleção poligonal
  const [isDrawingPolygon, setIsDrawingPolygon] = useState(false);

  const mapRef = useRef(null);
  const circleRefs = useRef([]); // Referência para os círculos

  const {
    grupos,
    isLoading: isGruposLoading,
    error: gruposError,
    refetch: refetchGrupos,
  } = useGruposData();

  const {
    postesDetalhados,
    isLoading: isPosteLoading,
    refetch: refetchPostes,
  } = usePosteData(true);

  // Integração com o hook useGroupPoste
  const {
    associations,
    isLoading: isAssociationsLoading,
    fetchAssociations,
    error: associationsError,
    createAssociation,
    removeAssociation,
  } = useGroupPoste();

  useEffect(() => {
    fetchAssociations(); // Buscar associações ao montar o componente
  }, [fetchAssociations]);

  const initialCenter = getMapCenterByCityId(parseInt(cidadeId, 10));

  /**
   * Função para processar os marcadores, incluindo os grupoIds.
   */
  const processMarkers = useCallback(() => {
    if (!mapBounds || postesDetalhados.length === 0 || associations.length === 0) {
      return;
    }

    setIsLoading(true);

    // Criar um mapeamento de posteId para grupoIds
    const posteIdToGrupoIds = associations.reduce((acc, association) => {
      const { grupoId, posteId } = association;
      const posteIdNum = parseInt(posteId, 10);
      const grupoIdNum = parseInt(grupoId, 10);
      if (!acc[posteIdNum]) {
        acc[posteIdNum] = [];
      }
      acc[posteIdNum].push(grupoIdNum);
      return acc;
    }, {});

    // Função para limpar os círculos do mapa
    const clearCircles = () => {
      circleRefs.current.forEach((circle) => circle.setMap(null)); // Remove do mapa
      circleRefs.current = []; // Limpa as referências
      console.log('Todos os círculos foram removidos.');
    };

    const validMarkers = postesDetalhados
      .filter((poste) => {
        if (!poste.geoLocalizacao || !poste.geoLocalizacao.includes(',')) {
          return false;
        }

        const [latStr, lngStr] = poste.geoLocalizacao.split(',').map((coord) => coord.trim());
        const lat = parseFloat(latStr);
        const lng = parseFloat(lngStr);

        return isValidGeolocation(lat, lng);
      })
      .map((poste) => {
        const [latStr, lngStr] = poste.geoLocalizacao.split(',').map((coord) => coord.trim());
        return {
          id: parseInt(poste.id, 10),
          codigoPoste: poste.codigoPoste,
          lat: parseFloat(latStr),
          lng: parseFloat(lngStr),
          grupoIds: posteIdToGrupoIds[parseInt(poste.id, 10)] || [], // Adiciona grupoIds ao marcador
        };
      });

    const filtered = validMarkers.filter((marker) => {
      const position = new window.google.maps.LatLng(marker.lat, marker.lng);
      return mapBounds.contains(position);
    });

    setFilteredMarkers(filtered);
    setIsLoading(false);
    console.log('Marcadores filtrados atualizados:', filtered);
  }, [postesDetalhados, mapBounds, associations]);

  useEffect(() => {
    if (postesDetalhados.length > 0 && mapBounds && associations.length > 0) {
      processMarkers();
    }
  }, [postesDetalhados, mapBounds, associations, processMarkers]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchPostes();
      refetchGrupos(); // Recarrega os grupos também
      fetchAssociations(); // Recarrega as associações também
    }, 180000); // Recarrega a cada 3 minutos

    return () => {
      clearInterval(intervalId);
    };
  }, [refetchPostes, refetchGrupos, fetchAssociations]);

  /**
   * Função para lidar com a mudança de zoom do mapa.
   */
  const handleZoomChanged = () => {
    if (mapRef.current) {
      const newZoom = mapRef.current.getZoom();
      setZoom(newZoom);
      console.log(`Zoom alterado para: ${newZoom}`);
    }
  };

  /**
   * Função para lidar com o evento de idle do mapa.
   */
  const handleOnIdle = () => {
    if (mapRef.current) {
      setIsLoading(true);
      const bounds = mapRef.current.getBounds();
      setMapBounds(bounds);
      console.log('Mapa está idle. Atualizando bounds.');
    }
  };

  /**
   * Função para lidar com o carregamento do mapa.
   * @param {object} map - Instância do mapa.
   */
  const handleMapLoad = (map) => {
    mapRef.current = map;
    map.addListener('zoom_changed', handleZoomChanged);
    handleOnIdle();
    console.log('Mapa carregado e listeners adicionados.');
  };

  /**
   * Função para lidar com a pesquisa de postes.
   */
  const handleSearch = () => {
    const cleanedSearchInput = searchInput.trim().toLowerCase();
    const marker = filteredMarkers.find(
      (m) =>
        m.codigoPoste.toLowerCase() === cleanedSearchInput ||
        m.id.toString() === cleanedSearchInput
    );
    if (marker) {
      mapRef.current.panTo({ lat: marker.lat, lng: marker.lng });
      mapRef.current.setZoom(18);
      console.log(`Poste encontrado e centrado: ID ${marker.id}`);
    } else {
      alert('Poste ou dispositivo não encontrado.');
      console.log(`Poste ou dispositivo não encontrado para o termo: ${cleanedSearchInput}`);
    }
  };

  /**
   * Função para alternar a visualização do mapa entre 'roadmap' e 'satellite'.
   */
  const toggleSatelliteView = () => {
    setMapType((prevType) => (prevType === 'roadmap' ? 'satellite' : 'roadmap'));
    console.log(`Tipo de mapa alterado para: ${mapType === 'roadmap' ? 'satellite' : 'roadmap'}`);
  };

  /**
   * Função para iniciar o modo de edição.
   */
  const startEditing = () => {
    if (!selectedGroup) {
      alert('Selecione um grupo antes de iniciar a edição.');
      return;
    }
    setIsEditing(true);
    setActiveTool('click'); // Ferramenta inicial: seleção por clique
    console.log(`Entrou no modo de edição para o grupo: ${selectedGroup.grupoNome}`);
  };

  /**
   * Função para parar o modo de edição.
   */
  const stopEditing = () => {
    setIsEditing(false);
    setMarkersToAdd([]); // Limpa os postes para adicionar
    setMarkersToRemove([]); // Limpa os postes para remover
    clearCircles(); // Remove os círculos do mapa
    setPolygonPoints([]); // Limpa o polígono, se estiver desenhando
    console.log(`Saiu do modo de edição.`);
  };

  /**
   * Função para lidar com o clique em um marcador.
   * Adiciona ou remove o marcador das listas de adição/remoção.
   * @param {number} markerId - ID do marcador clicado.
   */
  const handleMarkerClick = (markerId) => {
    if (isEditing && activeTool === 'click') {
      const marker = filteredMarkers.find((m) => m.id === markerId);
      const belongsToSelectedGroup =
        selectedGroup && marker.grupoIds.includes(parseInt(selectedGroup.grupoId, 10));

      if (belongsToSelectedGroup) {
        // Se já pertence ao grupo, gerenciar remoção
        if (markersToRemove.includes(markerId)) {
          // Já está marcado para remoção, desmarcar
          setMarkersToRemove((prev) => prev.filter((id) => id !== markerId));
          console.log(`Poste ${markerId} desmarcado para remoção do grupo.`);
        } else {
          // Marcar para remoção somente se não estiver sendo adicionado
          if (!markersToAdd.includes(markerId)) {
            setMarkersToRemove((prev) => [...prev, markerId]);
            console.log(`Poste ${markerId} marcado para remoção do grupo.`);
          }
        }
      } else {
        // Se não pertence ao grupo, gerenciar adição
        if (markersToAdd.includes(markerId)) {
          // Já está marcado para adição, desmarcar
          setMarkersToAdd((prev) => prev.filter((id) => id !== markerId));
          console.log(`Poste ${markerId} desmarcado para adição ao grupo.`);
        } else {
          // Marcar para adição
          setMarkersToAdd((prev) => [...prev, markerId]);
          console.log(`Poste ${markerId} marcado para adição ao grupo.`);
        }
      }
    }
  };

  /**
   * Função para lidar com o clique no mapa para seleção poligonal.
   * @param {object} event - Evento de clique no mapa.
   */
  const handleMapClickPolygon = (event) => {
    if (activeTool === 'polygon') {
      const newPoint = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      setPolygonPoints((prevPoints) => [...prevPoints, newPoint]);
      console.log('Ponto adicionado ao polígono:', newPoint);
    }
  };

  /**
   * useEffect para monitorar mudanças em polygonPoints
   */
  useEffect(() => {
    console.log('polygonPoints mudou:', polygonPoints);
  }, [polygonPoints]);

  /**
   * useEffect para monitorar mudanças em activeTool
   */
  useEffect(() => {
    console.log('activeTool mudou:', activeTool);
  }, [activeTool]);

  /**
   * Função para remover todos os círculos do mapa.
   */
  const clearCircles = () => {
    circleRefs.current.forEach((circle) => circle.setMap(null)); // Remove os círculos do mapa
    circleRefs.current = []; // Limpa as referências
    console.log('Todos os círculos foram removidos.');
  };

  /**
   * Função para finalizar a seleção poligonal.
   */
  const finishPolygonSelection = () => {
    if (polygonPoints.length < 3) {
      alert('Selecione pelo menos três pontos para formar um polígono.');
      return;
    }

    console.log('Seleção poligonal finalizada:', polygonPoints);

    // Identificar marcadores dentro do polígono
    const google = window.google;
    const polygon = new google.maps.Polygon({ paths: polygonPoints });
    const markersInside = filteredMarkers.filter((marker) =>
      google.maps.geometry.poly.containsLocation(
        new google.maps.LatLng(marker.lat, marker.lng),
        polygon
      )
    );

    console.log(`Postes dentro do polígono: ${markersInside.length}`);

    // Determinar quais postes devem ser adicionados
    const newMarkersToAdd = markersInside
      .filter(
        (marker) =>
          !marker.grupoIds.includes(parseInt(selectedGroup.grupoId, 10)) && // Não faz parte do grupo
          !markersToAdd.includes(marker.id) // Não está já marcado para adicionar
      )
      .map((marker) => marker.id);

    // Atualizar a lista de markersToAdd
    if (newMarkersToAdd.length > 0) {
      setMarkersToAdd((prev) => [...prev, ...newMarkersToAdd]);
      console.log('Postes para adicionar:', newMarkersToAdd);
    }

    // Limpa os círculos após finalizar
    clearCircles();
    setPolygonPoints([]); // Limpa os pontos
    console.log('polygonPoints após finalizar:', polygonPoints);
    setActiveTool('click'); // Volta para o modo de clique
  };

  /**
   * Função para cancelar a seleção poligonal.
   */
  const cancelPolygonSelection = () => {
    clearCircles(); // Remove os círculos do mapa
    setPolygonPoints([]); // Limpa os pontos
    setActiveTool('click'); // Volta para o modo de clique
    setIsDrawingPolygon(false);
    console.log('Seleção poligonal cancelada.');
  };

  /**
   * Função para aplicar as seleções de adição e remoção.
   */
  const applySelection = async () => {
    if (!selectedGroup) {
      alert('Nenhum grupo selecionado.');
      return;
    }

    if (markersToAdd.length === 0 && markersToRemove.length === 0) {
      alert('Nenhum poste selecionado para associar ou desassociar.');
      return;
    }

    try {
      setIsLoading(true);

      // Cria um array de promessas para associar cada poste ao grupo
      const addPromises = markersToAdd.map((posteId) =>
        createAssociation(selectedGroup.grupoId, posteId)
      );

      // Cria um array de promessas para remover cada associação
      const removePromises = markersToRemove.map((posteId) => {
        // Encontrar a associação correspondente
        const association = associations.find(
          (assoc) =>
            assoc.grupoId.toString() === selectedGroup.grupoId.toString() &&
            assoc.posteId.toString() === posteId.toString()
        );
        if (association) {
          return removeAssociation(association.grupoposteId);
        } else {
          return Promise.resolve(); // Se não encontrar, resolve imediatamente
        }
      });

      // Aguarda todas as requisições serem concluídas
      await Promise.all([...addPromises, ...removePromises]);

      alert('Alterações aplicadas com sucesso!');
      console.log('Alterações aplicadas:');
      console.log(`Adicionados: ${markersToAdd}`);
      console.log(`Removidos: ${markersToRemove}`);
      setMarkersToAdd([]); // Limpa os postes para adicionar após a associação
      setMarkersToRemove([]); // Limpa os postes para remover após a desassociação
      fetchAssociations(); // Recarrega as associações para refletir as alterações
      refetchPostes(); // Recarrega os postes, se necessário
      setIsEditing(false); // Sai do modo de edição
    } catch (error) {
      console.error('Erro ao aplicar alterações:', error);
      alert('Erro ao aplicar alterações. Tente novamente.');
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Resetar markersToAdd e markersToRemove ao selecionar um novo grupo durante a edição.
   */
  useEffect(() => {
    if (isEditing) {
      setMarkersToAdd([]);
      setMarkersToRemove([]);
      console.log('Resetando markersToAdd e markersToRemove devido à mudança de grupo.');
    }
  }, [selectedGroup, isEditing]);

  /**
   * Monitorar mudanças no selectedGroup para depuração.
   */
  useEffect(() => {
    console.log('Grupo selecionado mudou:', selectedGroup);
  }, [selectedGroup]);

  /**
   * Reprocessar os marcadores quando a seleção poligonal muda.
   */
  useEffect(() => {
    if (activeTool === 'polygon') {
      setIsDrawingPolygon(true);
      console.log('Iniciando seleção poligonal.');
    } else {
      // Se não estiver em modo de seleção poligonal, limpar o polígono
      clearCircles();
      setPolygonPoints([]);
      console.log('Modo de seleção poligonal desativado.');
    }
  }, [activeTool]);

  return (
    <div className={styles.posteGruposPage}>
      {(isPosteLoading || isLoading || isAssociationsLoading || isGruposLoading) && <LoadingOverlay />}
      {(associationsError || gruposError) && (
        <p className={styles.error}>Erro: {associationsError || gruposError}</p>
      )}

      {/* Mensagem de Edição no Topo */}
      {isEditing && (
        <div className={styles.editingBanner}>
          <p>Modo de Edição Ativo</p>
          <button onClick={stopEditing} className={styles.stopEditingButton}>
            Sair do Modo de Edição
          </button>
        </div>
      )}

      {/* Botão para Entrar no Modo de Edição */}
      {selectedGroup && !isEditing && (
        <div className={styles.editGroupButton}>
          <button onClick={startEditing}>Adicionar / Remover Postes</button>
        </div>
      )}

      {/* Barra de Ferramentas Visível Apenas no Modo de Edição */}
      {isEditing && (
        <FerramentasBarra
          activeTool={activeTool}
          setActiveTool={setActiveTool}
          selectedMarkersToAdd={markersToAdd}
          selectedMarkersToRemove={markersToRemove}
          applySelection={applySelection}
          finishPolygonSelection={finishPolygonSelection}
          cancelPolygonSelection={cancelPolygonSelection}
          polygonPoints={polygonPoints} // Passa os pontos para FerramentasBarra
        />
      )}

      {/* Barra de Pesquisa */}
      <SearchBar
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        handleSearch={handleSearch}
        toggleSatelliteView={toggleSatelliteView}
        mapType={mapType}
        visibleButtons={['search', 'satellite']}
      />

      {/* Janela de Grupos */}
      <JanelaGrupos onGroupSelect={(group) => setSelectedGroup(group)} isEditing={isEditing} />

      {/* Mapa Google */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={initialCenter}
        zoom={zoom}
        mapTypeId={mapType}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
        }}
        onLoad={handleMapLoad}
        onIdle={handleOnIdle}
        onClick={handleMapClickPolygon} // Adiciona o listener de clique no mapa
        libraries={['geometry']} // Adiciona a biblioteca geometry
      >
        {/* Desenhar o Polígono Selecionado */}
        {polygonPoints.length > 0 && (
          <>
            <Polygon
              paths={polygonPoints}
              options={{
                fillColor: '#FF0000',
                fillOpacity: 0.2,
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                clickable: false,
                draggable: false,
                editable: false,
                geodesic: false,
                zIndex: 1,
              }}
            />

            {/* Desenhar Círculos em Cada Ponto do Polígono */}
            {polygonPoints.map((point, index) => (
              <Circle
                key={index}
                center={point}
                radius={8} // Tamanho do círculo em metros (ajustável)
                options={{
                  fillColor: '#FFD700', // Cor de preenchimento dourada
                  fillOpacity: 0.9, // Opacidade de preenchimento
                  strokeColor: '#FF8C00', // Cor da borda laranja escura
                  strokeOpacity: 1, // Opacidade da borda
                  strokeWeight: 2, // Espessura da borda
                  clickable: false,
                  draggable: false,
                  editable: false,
                  zIndex: 2,
                }}
                onLoad={(circle) => {
                  // Adiciona cada círculo à lista de referências
                  circleRefs.current.push(circle);
                }}
              />
            ))}
          </>
        )}

        {/* Todos os Marcadores */}
        {!isLoading &&
          filteredMarkers.map((marker) => {
            // Verifica se o poste pertence ao grupo selecionado
            const belongsToSelectedGroup =
              selectedGroup && marker.grupoIds.includes(parseInt(selectedGroup.grupoId, 10));

            // Verifica se o poste está marcado para adicionar ou remover
            const isToAdd = markersToAdd.includes(marker.id);
            const isToRemove = markersToRemove.includes(marker.id);

            // Determina a cor do grupo, garantindo que esteja definido
            const groupColor = selectedGroup && selectedGroup.grupoCor ? selectedGroup.grupoCor : '#7d8ca3';

            // Criar o ícone personalizado
            const markerIcon = createCustomIcon(isToAdd, isToRemove, belongsToSelectedGroup, groupColor);

            // Adiciona logs para depuração
            console.log(
              `Marcador ID: ${marker.id}, 
               Pertence ao grupo selecionado: ${belongsToSelectedGroup}, 
               Marcado para adicionar: ${isToAdd}, 
               Marcado para remover: ${isToRemove}, 
               Cor do grupo: ${groupColor}`
            );

            return (
              <Marker
                key={marker.id}
                position={{ lat: marker.lat, lng: marker.lng }}
                title={`Código do Poste: ${marker.codigoPoste}`}
                icon={markerIcon}
                onClick={() => handleMarkerClick(marker.id)}
              />
            );
          })}
      </GoogleMap>
    </div>
  );
};

export default PosteGrupos;
