import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate'; // Importar React Paginate
import { FaPlus, FaEdit, FaTrash, FaSortUp, FaSortDown } from 'react-icons/fa';
import { CSVLink } from 'react-csv';
import TipoPostesModal from './TipoPostesModal';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay'; // Importando o componente de Loading
import PageLayout from '../../../components/PageLayout/PageLayout';
import styles from './TipoPostes.module.css';
import useTipoPoste from '../../../hooks/useTipoPoste';
import Tabela from '../../../components/Tabela/Tabela';


const TipoPostes = () => {
  const {tiposPoste, isTipoPosteLoading, tipoPosteError, refetch, createTipoPoste, editTipoPoste, deleteTipoPoste} = useTipoPoste(true);
  const [selectedTipoPoste, setSelectedTipoPoste] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [tipoFilter, setTipoFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(0); // Estado para a página atual
  const itemsPerPage = 10; // Define o número de itens por página


  useEffect(() => {
  }, []);


  /* Funções de Modificação de dados  */
  const handleAddNew = () => {
    setSelectedTipoPoste(null);
    setIsModalOpen(true);
  };

  const handleEdit = (tipoposte) => {
    setSelectedTipoPoste(tipoposte);
    setIsModalOpen(true);
  };

  const handleDelete = (tipoposte) => {
    const confirm = window.confirm('Tem certeza de que deseja deletar a Tipo Poste: ' + tipoposte.tp_nome + " (ID: " + tipoposte.tp_id + ")?");

    if (!confirm) {
      return;
    }
    else {
      deleteTipoPoste(tipoposte.tp_id).then(() => {
        refetch();
      });
    }
  };

  const handleSaveTipoPoste = (tipoposte) => {
    if (tipoposte.tp_id) {
      editTipoPoste(tipoposte.tp_id, tipoposte).then(() => {
        refetch();
      });
    }
    else {
      createTipoPoste(tipoposte).then(() => {
        refetch();
      });
    }
  };


  // Funções de sort da tabela
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedTipoPostes = () => {
    const sortedTipoPostes = [...tiposPoste];
    if (sortConfig.key) {
      sortedTipoPostes.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedTipoPostes;
  };

  const filteredTipoPostes = sortedTipoPostes().filter(tipoposte => {
    const matchesSearchTerm = tipoposte.tp_id.toString().toLowerCase().includes(searchTerm.toLowerCase());
    const matchesTipoFilter = tipoFilter ? tipoposte.tp_nome.toLowerCase().includes(tipoFilter.toLowerCase()) : true;

    return matchesSearchTerm && matchesTipoFilter;
  });


  // Funções de paginação
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentPageData = filteredTipoPostes.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(filteredTipoPostes.length / itemsPerPage);

  // Funções de input de filtro/pesquisa
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTipoFilterChange = (e) => {
    setTipoFilter(e.target.value);
  };


  // Preparando dados para exportação CSV
  const headers = [
    { label: 'ID', key: 'tp_id' },
    { label: 'Tipo', key: 'tp_nome' },
  ];

  const csvData = filteredTipoPostes.map(tipoposte => ({
    tp_id: tipoposte.tp_id,
    tp_nome: tipoposte.tp_nome,
  }));


  return (
    <PageLayout title="Lista de Tipo Postes">
      {isTipoPosteLoading && <LoadingOverlay />} {/* Loading screen enquanto os dados estão sendo carregados */}

      {!isTipoPosteLoading && (
        <>
        {tipoPosteError && (
            <div className={styles.errorContainer}>
              <p className={styles.errorMessage}>Erro: {tipoPosteError}</p>
              <button className={styles.retryButton} onClick={refetch}>
                Tentar Novamente
              </button>
            </div>
          )}
          <div className={styles.controlsTop}>
            <button className={styles.addButton} onClick={handleAddNew}>
              <FaPlus /> Adicionar Novo Tipo Poste
            </button>
            <CSVLink
              data={csvData}
              headers={headers}
              filename={"tipopostes-export.csv"}
              className={styles.exportButton}
              bom={true}
              separator={";"}
              enclosingCharacter={`"`}
            >
              Exportar para Excel
            </CSVLink>
          </div>
          <div className={styles.controls}>
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Pesquisar por ID do Tipo Poste..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Filtrar por Tipo..."
              value={tipoFilter}
              onChange={handleTipoFilterChange}
            />
          </div>

          {/* Tabela de Tipo Postes */}
          <div className={styles.tableContainer}>
            <Tabela>
              <thead>
                <tr>
                  <th onClick={() => requestSort('tp_id')}>
                    ID {sortConfig.key === 'tp_id' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('tp_nome')}>
                    Tipo {sortConfig.key === 'tp_nome' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                 
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {currentPageData.map(tipoposte => (
                  <tr key={tipoposte.tp_id}>
                    <td>{tipoposte.tp_id}</td>
                    <td>{tipoposte.tp_nome}</td>
                    <td>
                      <button className={styles.actionButton} onClick={() => handleEdit(tipoposte)}>
                        <FaEdit />
                      </button>
                      <button className={`${styles.actionButton} ${styles.deleteButton}`} onClick={() => handleDelete(tipoposte)}>
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Tabela>
          </div>

          {/* Componente de paginação */}
          <div className={styles.paginationContainer}>
            <ReactPaginate
              previousLabel={"← Anterior"}
              nextLabel={"Próximo →"}
              breakLabel={"..."}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={styles.pagination}
              previousLinkClassName={styles.paginationLink}
              nextLinkClassName={styles.paginationLink}
              disabledClassName={styles.paginationDisabled}
              activeClassName={styles.paginationActive}
            />
          </div>


          {<TipoPostesModal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            onSubmit={handleSaveTipoPoste}
            initialData={selectedTipoPoste}
          />}
        </>
      )}

    </PageLayout>);


};

export default TipoPostes;