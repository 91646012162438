// src/pages/Levantamentos/graficos/GraficoOrdensServico.jsx

import React, { useEffect, useState, useContext } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';
import { AuthContext } from '../../../context/AuthContext';
import { toast } from 'react-toastify';
import styles from '../Levantamentos.module.css';

const GraficoOrdensServico = () => {
  const { token, cidadeId, logout } = useContext(AuthContext);
  const [ordensData, setOrdensData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [chartError, setChartError] = useState(null);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  useEffect(() => {
    const fetchOrdensData = async () => {
      if (!token || !cidadeId) {
        toast.error('Token de autenticação ou ID da cidade não encontrado');
        return;
      }

      try {
        const response = await fetch('https://api.ads10.com.br/api/os', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            cidadeId: cidadeId,
          },
        });

        if (response.status === 401) {
          toast.error('Sessão expirada. Faça login novamente.');
          logout();
          return;
        }

        if (!response.ok) {
          throw new Error('Erro ao buscar dados das ordens de serviço');
        }

        const data = await response.json();
        const filteredData = data.filter((os) => {
          const osDate = new Date(`${os.osDataAbertura}T${os.osHoraAbertura}`);
          const startDateObj = startDate ? new Date(`${startDate}T${startTime || '00:00'}`) : null;
          const endDateObj = endDate ? new Date(`${endDate}T${endTime || '23:59'}`) : null;

          return (!startDateObj || osDate >= startDateObj) && (!endDateObj || osDate <= endDateObj);
        });

        const dataCounts = filteredData.reduce((acc, os) => {
          acc[os.osSituacaoId] = (acc[os.osSituacaoId] || 0) + 1;
          return acc;
        }, {});

        const formattedData = Object.keys(dataCounts).map((key) => ({
          name: `Situação ${key}`,
          value: dataCounts[key],
        }));

        setOrdensData(formattedData);
      } catch (error) {
        setChartError(error.message);
        toast.error(`Erro ao buscar dados das ordens de serviço: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrdensData();
  }, [token, cidadeId, logout, startDate, endDate, startTime, endTime]);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
  };

  const handleAtualizar = () => {
    setIsLoading(true);
    // O useEffect será chamado automaticamente devido às dependências
  };

  const handleClearFilters = () => {
    setStartDate('');
    setEndDate('');
    setStartTime('');
    setEndTime('');
    setIsLoading(true);
  };

  return (
    <div className={styles.chartCard}>
      <h3 className={styles.chartTitle}>Distribuição de Ordens de Serviço</h3>
      
      {/* Seção de Filtros Compacta */}
      <div className={styles.controlsTop}>
        <div className={styles.filterGroup}>
          <h3 className={styles.filterGroupTitle}>Filtro de Geração</h3>

          {/* Container Flexível para Inputs Lado a Lado */}
          <div className={styles.filtersContainer}>
            <div className={styles.filterItem}>
              <label htmlFor="startDate" className={styles.filterLabel}>Data Inicial:</label>
              <input
                type="date"
                id="startDate"
                value={startDate}
                onChange={handleStartDateChange}
                className={styles.dropdown}
              />
            </div>

            <div className={styles.filterItem}>
              <label htmlFor="startTime" className={styles.filterLabel}>Hora Inicial:</label>
              <input
                type="time"
                id="startTime"
                value={startTime}
                onChange={handleStartTimeChange}
                className={styles.dropdown}
              />
            </div>

            <div className={styles.filterItem}>
              <label htmlFor="endDate" className={styles.filterLabel}>Data Final:</label>
              <input
                type="date"
                id="endDate"
                value={endDate}
                onChange={handleEndDateChange}
                className={styles.dropdown}
              />
            </div>

            <div className={styles.filterItem}>
              <label htmlFor="endTime" className={styles.filterLabel}>Hora Final:</label>
              <input
                type="time"
                id="endTime"
                value={endTime}
                onChange={handleEndTimeChange}
                className={styles.dropdown}
              />
            </div>
          </div>
        </div>

        {/* Botões de Ação */}
        <div className={styles.actionsContainer}>
          <button onClick={handleAtualizar} className={styles.button}>
            Atualizar
          </button>
          <button onClick={handleClearFilters} className={styles.clearFiltersButton}>
            Limpar Filtros
          </button>
        </div>
      </div>

      {/* Gráfico */}
      {isLoading ? (
        <p className={styles.infoMessage}>Carregando dados...</p>
      ) : chartError ? (
        <p className={styles.errorMessage}>Erro: {chartError}</p>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={ordensData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill="#36A2EB" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default GraficoOrdensServico;
