import { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

/**
 * Hook customizado para buscar a lista de tipos de via.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @returns {object} - Contém a lista de tipos de via, estado de carregamento, erro e função para refetch.
 */
const useTipoVia = (isOpen) => {
  const URL = 'https://api.ads10.com.br/api/tipovia';
  const { token, logout, cidadeId } = useContext(AuthContext); // Obtém token e logout do AuthContext
  const [tiposVia, setTiposVia] = useState([]);
  const [isTipoViaLoading, setIsTipoViaLoading] = useState(false);
  const [tipoViaError, setTipoViaError] = useState(null);

  /**
   * Função para buscar os tipos de via da API.
   */
  const fetchTipoVia = useCallback(async () => {
    if (!isOpen) return; // Não buscar se o modal não estiver aberto

    setIsTipoViaLoading(true);
    setTipoViaError(null);
    try {
      if (!token) throw new Error('Token de autenticação não encontrado');

      const response = await fetch(URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar tipos de via: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Tipos de Via recebidos da API:', data); // Log para depuração
      setTiposVia(data);
    } catch (error) {
      console.error('Erro ao buscar tipos de via:', error);
      setTipoViaError('Falha ao carregar a lista de tipos de via. Verifique sua conexão ou tente novamente mais tarde.');
      toast.error(`Erro ao buscar tipos de via: ${error.message}`);
    } finally {
      setIsTipoViaLoading(false);
    }
  }, [isOpen, token, cidadeId, logout]);

  // Criar Tipo Via pelo metodo POST
  const createTipoVia = useCallback(async (tipoVia) => {
    if (!token) {
      setTipoViaError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsTipoViaLoading(true);
    setTipoViaError(null);

    try {
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(tipoVia),
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao criar Tipo Via: ${response.status} ${response.statusText}`);
      }

      toast.success('Tipo Via criada com sucesso!');
    }
    catch (error) {
      console.error('Erro ao criar Tipo Via:', error);
      setTipoViaError(error.message);
      toast.error(`Erro ao criar Tipo Via: ${error.message}`);
    } finally {
      setIsTipoViaLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  
  // Editar Tipo Via pelo metodo PUT
  const editTipoVia = useCallback(async (tipoViaid, tipoVia) => {
    if (!token) {
      setTipoViaError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    if (tipoViaid == null || tipoViaid <= 0) {
      setTipoViaError('ID da Tipo Via Invalido');
      return;
    }

    setIsTipoViaLoading(true);
    setTipoViaError(null);

    try {
      const response = await fetch(URL + '/' + tipoViaid, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(tipoVia),
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao editar Tipo Via: ${response.status} ${response.statusText}`);
      }

      toast.success('Tipo Via Editado com sucesso!');
    }
    catch (error) {
      console.error('Erro ao editar Tipo Via:', error);
      setTipoViaError(error.message);
      toast.error(`Erro ao editar Tipo Via: ${error.message}`);
    } finally {
      setIsTipoViaLoading(false);
    }

  }, [URL, token, cidadeId, logout]);


  // Deletar braco tipo pelo metodo DELETE
  const deleteTipoVia = useCallback(async (tipoViaid) => {
    if (!token) {
      setTipoViaError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    if (tipoViaid == null || tipoViaid <= 0) {
      setTipoViaError('ID da tipo Via Invalido');
      return;
    }

    setIsTipoViaLoading(true);
    setTipoViaError(null);

    try {
      const response = await fetch(URL + '/' + tipoViaid, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao deletar Tipo Via: ${response.status} ${response.statusText}`);
      }

      toast.success('Tipo Via Deletado com sucesso!');
    }
    catch (error) {
      console.error('Erro ao deletar Tipo Via:', error);
      setTipoViaError(error.message);
      toast.error(`Erro ao deletar Tipo Via: ${error.message}`);
    } finally {
      setIsTipoViaLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  useEffect(() => {
    fetchTipoVia();
  }, [fetchTipoVia]);

  return { tiposVia: tiposVia, isTipoViaLoading, tipoViaError, refetch: fetchTipoVia, createTipoVia: createTipoVia, editTipoVia: editTipoVia, deleteTipoVia: deleteTipoVia };
};

export default useTipoVia;
