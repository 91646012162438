// src/components/SearchBar/SearchBar.jsx

import React from 'react';
import PropTypes from 'prop-types';
import {
  FaCrosshairs,
  FaLayerGroup,
  FaTag,
  FaSearch,
  FaCamera,
} from 'react-icons/fa';
import styles from './SearchBar.module.css';

const BUTTONS = {
  search: FaSearch,
  labels: FaTag,
  satellite: FaLayerGroup,
  tilt: FaCamera,
  location: FaCrosshairs,
};

const SearchBar = ({
  searchInput,
  setSearchInput,
  handleSearch,
  toggleLabels,
  toggleSatelliteView,
  focusOnUserLocation,
  toggleTilt,
  labelsVisible,
  tiltEnabled,
  mapType,
  visibleButtons = Object.keys(BUTTONS), // Propriedade para definir quais botões exibir
  isGPSTracking, // Nova prop
}) => {
  return (
    <div className={styles.searchContainer}>
      <FaSearch className={styles.searchIcon} />
      <input
        type="text"
        placeholder="Buscar pelo ID do Poste ou Dispositivo"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        className={styles.searchInput}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
      />
      <div className={styles.buttonsContainer}>
        {visibleButtons.includes('search') && (
          <button onClick={handleSearch} className={styles.iconButton} aria-label="Buscar">
            <FaSearch />
          </button>
        )}
        {visibleButtons.includes('labels') && (
          <button
            onClick={toggleLabels}
            className={styles.iconButton}
            aria-label="Alternar Nomes dos Postes"
            style={{ color: labelsVisible ? '#000' : '#555' }}
          >
            <FaTag />
          </button>
        )}
        {visibleButtons.includes('satellite') && (
          <button
            onClick={toggleSatelliteView}
            className={styles.iconButton}
            aria-label="Alternar Visão do Mapa"
          >
            <FaLayerGroup />
          </button>
        )}
        {visibleButtons.includes('tilt') && mapType === 'satellite' && (
          <button
            onClick={toggleTilt}
            className={styles.iconButton}
            aria-label="Alternar Inclinação da Câmera"
            style={{ color: tiltEnabled ? '#000' : '#555' }}
          >
            <FaCamera />
          </button>
        )}
        {visibleButtons.includes('location') && (
          <button
            onClick={focusOnUserLocation}
            className={`${styles.iconButton} ${isGPSTracking ? styles.gpsActive : ''}`}
            aria-label="Focar na Minha Localização"
          >
            <FaCrosshairs />
          </button>
        )}
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  searchInput: PropTypes.string.isRequired,
  setSearchInput: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  toggleLabels: PropTypes.func,
  toggleSatelliteView: PropTypes.func,
  focusOnUserLocation: PropTypes.func,
  toggleTilt: PropTypes.func,
  labelsVisible: PropTypes.bool,
  tiltEnabled: PropTypes.bool,
  mapType: PropTypes.string,
  visibleButtons: PropTypes.arrayOf(PropTypes.string), // Lista de botões visíveis
  isGPSTracking: PropTypes.bool, // Nova prop
};

SearchBar.defaultProps = {
  toggleLabels: () => {},
  toggleSatelliteView: () => {},
  focusOnUserLocation: () => {},
  toggleTilt: () => {},
  labelsVisible: false,
  tiltEnabled: false,
  mapType: 'roadmap',
  visibleButtons: Object.keys(BUTTONS), // Exibe todos os botões por padrão
  isGPSTracking: false, // Valor padrão
};

export default SearchBar;
