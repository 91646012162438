import { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

/**
 * Hook customizado para buscar a lista de classificações de via.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @returns {object} - Contém a lista de classificações de via, estado de carregamento, erro e função para refetch.
 */
const useClassificacaoVia = (isOpen) => {
  const URL = 'https://api.ads10.com.br/api/classificacaovia';
  const { token, logout, cidadeId } = useContext(AuthContext); // Obtém token e logout do contexto
  const [classificacoesVia, setClassificacoesVia] = useState([]);
  const [isClassificacaoViaLoading, setIsClassificacaoViaLoading] = useState(false);
  const [classificacaoViaError, setClassificacaoViaError] = useState(null);

  /**
   * Função para buscar as classificações de via da API.
   */
  const fetchClassificacaoVia = useCallback(async () => {
    if (!isOpen) return; // Não buscar se o modal não estiver aberto

    setIsClassificacaoViaLoading(true);
    setClassificacaoViaError(null);
    try {
      if (!token) throw new Error('Token de autenticação não encontrado');

      const response = await fetch(URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Incluindo o token de autenticação
          'cidadeId': cidadeId,
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar classificações de via: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Classificações de Via recebidas da API:', data); // Log para depuração
      setClassificacoesVia(data);
    } catch (error) {
      console.error('Erro ao buscar classificações de via:', error);
      setClassificacaoViaError(error.message.includes('Token')
        ? 'Você precisa estar autenticado para carregar a lista de classificações de via.'
        : 'Falha ao carregar a lista de classificações de via. Verifique sua conexão ou tente novamente mais tarde.');
      toast.error(setClassificacaoViaError);
    } finally {
      setIsClassificacaoViaLoading(false);
    }
  }, [isOpen, token, cidadeId, logout]);


  // Criar Classificação Via pelo metodo POST
  const createClassificacaoVia = useCallback(async (classificacaoVia) => {
    if (!token) {
      setClassificacaoViaError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsClassificacaoViaLoading(true);
    setClassificacaoViaError(null);

    try {
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(classificacaoVia),
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao criar Classificação Via: ${response.status} ${response.statusText}`);
      }

      toast.success('Classificação Via criada com sucesso!');
    }
    catch (error) {
      console.error('Erro ao criar Classificação Via:', error);
      setClassificacaoViaError(error.message);
      toast.error(`Erro ao criar Classificação Via: ${error.message}`);
    } finally {
      setIsClassificacaoViaLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  
  // Editar Classificação Via pelo metodo PUT
  const editClassificacaoVia = useCallback(async (classificacaoViaid, classificacaoVia) => {
    if (!token) {
      setClassificacaoViaError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    if (classificacaoViaid == null || classificacaoViaid <= 0) {
      setClassificacaoViaError('ID da Classificação Via Invalida');
      return;
    }

    setIsClassificacaoViaLoading(true);
    setClassificacaoViaError(null);

    try {
      const response = await fetch(URL + '/' + classificacaoViaid, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(classificacaoVia),
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao editar Classificação Via: ${response.status} ${response.statusText}`);
      }

      toast.success('Classificação Via Editada com sucesso!');
    }
    catch (error) {
      console.error('Erro ao editar Classificação Via:', error);
      setClassificacaoViaError(error.message);
      toast.error(`Erro ao editar Classificação Via: ${error.message}`);
    } finally {
      setIsClassificacaoViaLoading(false);
    }

  }, [URL, token, cidadeId, logout]);


  // Deletar braco tipo pelo metodo DELETE
  const deleteClassificacaoVia = useCallback(async (classificacaoViaid) => {
    if (!token) {
      setClassificacaoViaError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    if (classificacaoViaid == null || classificacaoViaid <= 0) {
      setClassificacaoViaError('ID da classificacao Via Invalido');
      return;
    }

    setIsClassificacaoViaLoading(true);
    setClassificacaoViaError(null);

    try {
      const response = await fetch(URL + '/' + classificacaoViaid, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao deletar Classificação Via: ${response.status} ${response.statusText}`);
      }

      toast.success('Classificação Via Deletada com sucesso!');
    }
    catch (error) {
      console.error('Erro ao deletar Classificação Via:', error);
      setClassificacaoViaError(error.message);
      toast.error(`Erro ao deletar Classificação Via: ${error.message}`);
    } finally {
      setIsClassificacaoViaLoading(false);
    }

  }, [URL, token, cidadeId, logout]);
  

  useEffect(() => {
    fetchClassificacaoVia();
  }, [fetchClassificacaoVia]);

  return { classificacoesVia, isClassificacaoViaLoading, classificacaoViaError, refetch: fetchClassificacaoVia, createClassificacaoVia: createClassificacaoVia, editClassificacaoVia: editClassificacaoVia, deleteClassificacaoVia: deleteClassificacaoVia };
};

export default useClassificacaoVia;
