// src/pages/Postes/PosteModal.jsx

import React, { useState, useEffect, useContext } from 'react';
import { FaTimes, FaSyncAlt } from 'react-icons/fa';
import Modal from 'react-modal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import styles from './PosteModal.module.css';
import IdentificacaoLocalizacao from './SubComponents/IdentificacaoLocalizacao';
import Transformador from './SubComponents/Transformador';
import ConfiguracaoFisica from './SubComponents/ConfiguracaoFisica';
import Viario from './SubComponents/Viario';
import OutrosDetalhes from './SubComponents/OutrosDetalhes';
import FotoPoste from './SubComponents/FotoPoste';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

// Importar os hooks customizados
import useCountries from '../../hooks/useCountries';
import useStates from '../../hooks/useStates';
import useCities from '../../hooks/useCities';
import useFormatoPoste from '../../hooks/useFormatoPoste';
import useFormatoTampa from '../../hooks/useFormatoTampa';
import useTipoPoste from '../../hooks/useTipoPoste';
import useClassificacaoVia from '../../hooks/useClassificacaoVia';
import useTipoVia from '../../hooks/useTipoVia';
import useRegiao from '../../hooks/useRegiao';

// Importar AuthContext para obter cidadeId
import { AuthContext } from '../../context/AuthContext';
import { toast } from 'react-toastify';

// Importar a função de validação da sigla da cidade
import { getCityAbbreviationById } from '../../config'; // Ajuste o caminho conforme necessário

Modal.setAppElement('#root');

const PosteModal = ({ isOpen, onRequestClose, onSubmit, initialData }) => {
  // Obter cidadeId do AuthContext
  const { cidadeId } = useContext(AuthContext);

  // Estados do formulário
  const [formData, setFormData] = useState({
    idPoste: '',
    codigoPoste: '',
    barramento: '',
    geoLocalizacao: '',
    endereco: '',
    dataCadastro: '',
    caboAlimentacao: '',
    alimentacaoTransformador: null,
    numeroPlaquetaTransformador: '',
    lampadaMedida: '0',
    redeAltaTensao: '0',
    numeroMedidorLampada: '',
    transformador: '0',
    grafoConexao: '',
    tipoVia: '',
    classificacaoVia: '',
    comprimento: '',
    potencia: '',
    comando: '0',
    tipoPoste: '',
    formatoPoste: '',
    formatoTampa: '',
    bracoTipo: '',
    bracoDimensoes: '',
    pais: '',
    estado: '',
    cidade: '',
    regiao: '',
  });

  // Estado para rastrear erros de formulário
  const [formErrors, setFormErrors] = useState({
    codigoPosteError: '',
  });

  // Outros estados
  const [selectedImage, setSelectedImage] = useState(null);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // Usar os hooks para obter a lista de países, estados, cidades, formatos de poste, etc.
  const { countries, isCountriesLoading, countriesError, refetch: refetchCountries } = useCountries(isOpen);
  const { states, isStatesLoading, statesError, refetch: refetchStates } = useStates(isOpen, formData.pais);
  const { cities, isCitiesLoading, citiesError, refetch: refetchCities } = useCities(isOpen, formData.estado);
  const { formatosPoste, isFormatoPosteLoading, formatoPosteError, refetch: refetchFormatoPoste } = useFormatoPoste(isOpen);
  const { formatosTampo, isFormatTampoLoading, formatoTampoError, refetch: refetchFormatTampo } = useFormatoTampa(isOpen);
  const { tiposPoste, isTipoPosteLoading, tipoPosteError, refetch: refetchTipoPoste } = useTipoPoste(isOpen);
  const { classificacoesVia, isClassificacaoViaLoading, classificacaoViaError, refetch: refetchClassificacaoVia } = useClassificacaoVia(isOpen);
  const { tiposVia, isTipoViaLoading, tipoViaError, refetch: refetchTipoVia } = useTipoVia(isOpen);
  const { regioes, isRegiaoLoading, regiaoError, refetch: refetchRegiao } = useRegiao(isOpen, formData.pais, formData.estado, formData.cidade);

  // useEffect para preencher o formulário com os dados iniciais quando o modal é aberto para edição
  useEffect(() => {
    if (initialData) {
      const formattedData = {
        idPoste: initialData.id || initialData.idPoste || '',
        codigoPoste: initialData.codigoPoste || '',
        barramento: initialData.posteBarramento || '',
        geoLocalizacao: initialData.geoLocalizacao || '',
        endereco: initialData.posteEnd || '',
        dataCadastro: initialData.posteDataCad || '',
        caboAlimentacao: initialData.tipo_cabo_alimentacao || '',
        alimentacaoTransformador: initialData.alimentacao_transformador !== null && initialData.alimentacao_transformador !== undefined ? initialData.alimentacao_transformador : null,
        numeroPlaquetaTransformador: initialData.numero_plaqueta_transformador || '',
        lampadaMedida: initialData.lampada_medida !== null && initialData.lampada_medida !== undefined ? initialData.lampada_medida.toString() : '0',
        redeAltaTensao: initialData.rede_alta_tencao !== null && initialData.rede_alta_tencao !== undefined ? initialData.rede_alta_tencao.toString() : '0',
        numeroMedidorLampada: initialData.numero_medidor_lampada || '',
        transformador: initialData.transformador === 'sim' || initialData.transformador === '1' ? '1' : '0',
        grafoConexao: initialData.grafo_conexao || '',
        tipoVia: initialData.tipoVia || '',
        classificacaoVia: initialData.classificacaoVia || '',
        comprimento: initialData.comprimento || '',
        potencia: initialData.potencia || '',
        comando: initialData.comando === 'sim' || initialData.comando === '1' ? '1' : '0',
        tipoPoste: initialData.tipoPoste || '',
        formatoPoste: initialData.formatoPoste || '',
        formatoTampa: initialData.formatoTampa || '',
        bracoTipo: initialData.bracoTipos || '',
        bracoDimensoes: initialData.bracoDimensoes || '',
        pais: initialData.postePais ? String(initialData.postePais) : '',
        estado: initialData.posteEstado ? String(initialData.posteEstado) : '',
        cidade: initialData.posteCidade ? String(initialData.posteCidade) : '',
        regiao: initialData.posteRegiao ? String(initialData.posteRegiao) : '',
      };
      setFormData(formattedData);
      setSelectedImage(initialData.image || null);

      // **Log dos dados iniciais**
      console.log('Dados iniciais carregados no formData:', formattedData);
    } else {
      // Resetar formulário se não houver dados iniciais
      const resetData = {
        idPoste: '',
        codigoPoste: '',
        barramento: '',
        geoLocalizacao: '',
        endereco: '',
        dataCadastro: '',
        caboAlimentacao: '',
        alimentacaoTransformador: null,
        numeroPlaquetaTransformador: '',
        lampadaMedida: '0',
        redeAltaTensao: '0',
        numeroMedidorLampada: '',
        transformador: '0',
        grafoConexao: '',
        tipoVia: '',
        classificacaoVia: '',
        comprimento: '',
        potencia: '',
        comando: '0',
        tipoPoste: '',
        formatoPoste: '',
        formatoTampa: '',
        bracoTipo: '',
        bracoDimensoes: '',
        pais: '',
        estado: '',
        cidade: '',
        regiao: '',
      };
      setFormData(resetData);
      setSelectedImage(null);

      // **Log de reset do formData**
      console.log('Formulário resetado, formData:', resetData);
    }
  }, [initialData]);

  // Função para lidar com mudanças nos campos do formulário
  const handleChange = (e) => {
    const { name, value } = e.target;

    let newValue = value;

    // Tratamento especial para "alimentacaoTransformador"
    if (name === 'alimentacaoTransformador') {
      newValue = value ? parseInt(value, 10) : null;
    }

    // Tratamento especial para "lampadaMedida", "redeAltaTensao" e "comando"
    if (['lampadaMedida', 'redeAltaTensao', 'comando'].includes(name)) {
      newValue = value === 'sim' || value === '1' ? '1' : '0';
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));

    // **Log da mudança no campo**
    console.log(`Campo alterado: ${name}, Novo valor: ${newValue}`);
  };

  // Função para obter a localização do usuário via Geolocalização
  const handleGetUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const geoLocation = `${latitude}, ${longitude}`;
          setFormData((prevFormData) => ({
            ...prevFormData,
            geoLocalizacao: geoLocation,
          }));

          // **Log da geolocalização obtida**
          console.log('Geolocalização obtida:', geoLocation);
        },
        (error) => {
          console.error('Erro ao obter geolocalização:', error);
          alert('Erro ao obter geolocalização.');
        }
      );
    } else {
      console.error('Geolocalização não é suportada neste navegador.');
      alert('Geolocalização não é suportada neste navegador.');
    }
  };

  // Função para lidar com upload de imagem
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        alert('Por favor, selecione um arquivo de imagem válido.');
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);

        // **Log da imagem carregada**
        console.log('Imagem carregada com sucesso.');
      };
      reader.readAsDataURL(file);
    }
  };

  // Funções para lidar com a remoção de imagem
  const handleImageClick = () => {
    setIsConfirmDeleteOpen(true);
  };

  const handleImageDelete = () => {
    setSelectedImage(null);
    setIsConfirmDeleteOpen(false);

    // **Log da remoção da imagem**
    console.log('Imagem removida.');
  };

  const handleConfirmDeleteClose = () => {
    setIsConfirmDeleteOpen(false);
  };

  // Função para lidar com a submissão
  const handleSubmit = async (e) => {
    e.preventDefault();

    // **Log do formData ao submeter**
    console.log('FormData ao submeter:', formData);

    // Validação do campo "Código Poste"
    const sigla = getCityAbbreviationById(cidadeId);
    const codigoPoste = formData.codigoPoste.trim();

    // Atualizar a expressão regular para remover o espaço
    const regex = new RegExp(`^${sigla}\\d{9}$`);

    if (!regex.test(codigoPoste)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        codigoPosteError: `Formato inválido para Código Poste. Deve ser no formato: ${sigla}000000000`,
      }));
      toast.error(`Formato inválido para Código Poste. Deve ser no formato: ${sigla}000000000`);
      return;
    } else {
      // Verificar se os 9 dígitos não são todos zeros
      const numericPart = codigoPoste.slice(sigla.length);
      if (/^0{9}$/.test(numericPart)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          codigoPosteError: 'O Código Poste não pode conter apenas zeros.',
        }));
        toast.error('O Código Poste não pode conter apenas zeros.');
        return;
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          codigoPosteError: '',
        }));
      }
    }

    // Preparar os dados a serem enviados
    const payload = {
      id: formData.idPoste ? parseInt(formData.idPoste, 10) : undefined,
      codigoPoste: formData.codigoPoste,
      qrcodePoste: null, // Ajustar conforme necessário
      qrWhatsApp: null, // Ajustar conforme necessário
      qrSite: null, // Ajustar conforme necessário
      etiquetaPoste: formData.etiquetaPoste || '',
      etiqueta: null, // Ajustar conforme necessário
      descrição: formData.descrição || null,
      geoLocalizacao: formData.geoLocalizacao,
      postePais: parseInt(formData.pais, 10),
      posteEstado: parseInt(formData.estado, 10),
      posteCidade: parseInt(formData.cidade, 10),
      posteBairro: formData.posteBairro || null,
      posteUsuarioCad: formData.posteUsuarioCad || null,
      lat: formData.lat || null,
      lng: formData.lng || null,
      address: formData.address || null,
      name: formData.name || null,
      type: formData.type || null,
      posteAppId: formData.posteAppId || null,
      posteAppCodigo: formData.posteAppCodigo || null,
      posteRegiao: parseInt(formData.regiao, 10),
      posteDataCad: formData.dataCadastro || '0000-00-00',
      posteBarramento: formData.barramento,
      posteEnd: formData.endereco || '',
      posteRef: formData.posteRef || null,
      posteLampadaOriginal: formData.posteLampadaOriginal || null,
      posteDataColetaDados: formData.posteDataColetaDados || null,
      posteLampadaInstalada: formData.posteLampadaInstalada || null,
      posteDataInstalacaoLampadaNova: formData.posteDataInstalacaoLampadaNova || null,
      tipo_cabo_alimentacao: formData.caboAlimentacao,
      alimentacao_transformador: formData.alimentacaoTransformador,
      numero_plaqueta_transformador: formData.numeroPlaquetaTransformador || '',
      lampada_medida: formData.lampadaMedida,
      rede_alta_tencao: formData.redeAltaTensao,
      numero_medidor_lampada: formData.numeroMedidorLampada || '',
      grafo_conexao: formData.grafoConexao || '',
      tipoVia: formData.tipoVia,
      classificacaoVia: formData.classificacaoVia,
      quantidadePontos: formData.quantidadePontos || null,
      transformador: formData.transformador,
      tipoPoste: formData.tipoPoste,
      formatoPoste: formData.formatoPoste,
      comprimento: formData.comprimento,
      formatoTampa: formData.formatoTampa,
      potencia: formData.potencia,
      bracoTipos: formData.bracoTipo,
      bracoDimensoes: formData.bracoDimensoes,
      comando: formData.comando,
      ativo: formData.ativo || 'S',
      posteStatus: formData.posteStatus || null,
    };

    try {
      // Enviar os dados diretamente ao servidor
      await onSubmit(payload, selectedImage); // Passe também a imagem, se necessário
      onRequestClose(); // Fechar o modal após o sucesso
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
      // Exibir mensagem de erro ao usuário, se necessário
      toast.error('Ocorreu um erro ao salvar o poste.');
    }
  };

  // Função para mudar de aba
  const handleTabSelect = (index) => {
    setActiveTabIndex(index);

    // **Log da mudança de aba**
    console.log(`Aba selecionada: ${index}`);
  };

  // **Log de depuração para monitorar formData**
  useEffect(() => {
    console.log('formData mudou:', formData);
  }, [formData]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Informações de Poste"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <button
        className={styles.closeModalButton}
        onClick={onRequestClose}
        aria-label="Fechar Modal"
      >
        <FaTimes />
      </button>
      <div className={styles.postePage}>
        <h1 className={styles.header}>Informações de Poste</h1>
        <form onSubmit={handleSubmit}>
          <Tabs selectedIndex={activeTabIndex} onSelect={handleTabSelect}>
            <TabList>
              <Tab>Informações & Imagem</Tab>
              <Tab>Infraestrutura & Detalhes</Tab>
            </TabList>

            <TabPanel>
              {/* Informações e Imagem */}
              <IdentificacaoLocalizacao
                formData={formData}
                handleChange={handleChange}
                handleGetUserLocation={handleGetUserLocation}
                countries={countries}
                isCountriesLoading={isCountriesLoading}
                countriesError={countriesError}
                states={states}
                isStatesLoading={isStatesLoading}
                statesError={statesError}
                cities={cities}
                isCitiesLoading={isCitiesLoading}
                citiesError={citiesError}
                regioes={regioes}
                isRegiaoLoading={isRegiaoLoading}
                regiaoError={regiaoError}
                formErrors={formErrors}
              />
              {/* Foto do Poste */}
              <FotoPoste
                selectedImage={selectedImage}
                handleImageUpload={handleImageUpload}
                handleImageClick={handleImageClick}
              />
              {/* Se houver erro, exiba um botão para refazer a busca */}
              {(countriesError || statesError || citiesError || regiaoError) && (
                <div className={styles.errorContainer}>
                  {countriesError && <p className={styles.errorMessage}>{countriesError}</p>}
                  {statesError && <p className={styles.errorMessage}>{statesError}</p>}
                  {citiesError && <p className={styles.errorMessage}>{citiesError}</p>}
                  {regiaoError && <p className={styles.errorMessage}>{regiaoError}</p>}
                  <button
                    type="button"
                    className={styles.retryButton}
                    onClick={() => {
                      if (countriesError) refetchCountries();
                      if (statesError) refetchStates();
                      if (citiesError) refetchCities();
                      if (regiaoError) refetchRegiao();
                    }}
                  >
                    <FaSyncAlt /> Tentar Novamente
                  </button>
                </div>
              )}
            </TabPanel>

            <TabPanel>
              {/* Infraestrutura & Detalhes */}
              <Transformador
                formData={formData}
                handleChange={handleChange}
              />
              <ConfiguracaoFisica
                formData={formData}
                handleChange={handleChange}
                tiposPoste={tiposPoste || []}
                isTipoPosteLoading={isTipoPosteLoading}
                tipoPosteError={tipoPosteError}
                refetchTipoPoste={refetchTipoPoste}
                formatosPoste={formatosPoste || []}
                isFormatoPosteLoading={isFormatoPosteLoading}
                formatoPosteError={formatoPosteError}
                refetchFormatoPoste={refetchFormatoPoste}
                formatosTampo={formatosTampo || []}
                isFormatTampoLoading={isFormatTampoLoading}
                formatoTampoError={formatoTampoError}
                refetchFormatTampo={refetchFormatTampo}
              />
              <Viario
                formData={formData}
                handleChange={handleChange}
                classificacoesVia={classificacoesVia || []}
                isClassificacaoViaLoading={isClassificacaoViaLoading}
                classificacaoViaError={classificacaoViaError}
                refetchClassificacaoVia={refetchClassificacaoVia}
                tiposVia={tiposVia || []}
                isTipoViaLoading={isTipoViaLoading}
                tipoViaError={tipoViaError}
                refetchTipoVia={refetchTipoVia}
              />
              <OutrosDetalhes
                formData={formData}
                handleChange={handleChange}
              />
              {/* Se houver erro, exiba um botão para refazer a busca */}
              {(formatoPosteError || formatoTampoError || tipoPosteError || classificacaoViaError || tipoViaError || regiaoError) && (
                <div className={styles.errorContainer}>
                  {formatoPosteError && <p className={styles.errorMessage}>{formatoPosteError}</p>}
                  {formatoTampoError && <p className={styles.errorMessage}>{formatoTampoError}</p>}
                  {tipoPosteError && <p className={styles.errorMessage}>{tipoPosteError}</p>}
                  {classificacaoViaError && <p className={styles.errorMessage}>{classificacaoViaError}</p>}
                  {tipoViaError && <p className={styles.errorMessage}>{tipoViaError}</p>}
                  {regiaoError && <p className={styles.errorMessage}>{regiaoError}</p>}
                  <button
                    type="button"
                    className={styles.retryButton}
                    onClick={() => {
                      if (formatoPosteError) refetchFormatoPoste();
                      if (formatoTampoError) refetchFormatTampo();
                      if (tipoPosteError) refetchTipoPoste();
                      if (classificacaoViaError) refetchClassificacaoVia();
                      if (tipoViaError) refetchTipoVia();
                      if (regiaoError) refetchRegiao();
                    }}
                  >
                    <FaSyncAlt /> Tentar Novamente
                  </button>
                </div>
              )}
            </TabPanel>
          </Tabs>

          {/* Botão de Submissão */}
          <button className={styles.submitButton} type="submit">Salvar</button>
        </form>

        {/* Modal de Confirmação para Remoção da Imagem */}
        <ConfirmDeleteModal
          isOpen={isConfirmDeleteOpen}
          onRequestClose={handleConfirmDeleteClose}
          onConfirmDelete={handleImageDelete}
        />
      </div>
    </Modal>
  );
};

export default PosteModal;
