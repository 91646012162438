// src/hooks/useGroupPoste.js

import { useState, useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

const useGroupPoste = () => {
  const { token, cidadeId, logout } = useContext(AuthContext);

  // Estados para gerenciar as associações, carregamento e erros
  const [associations, setAssociations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Função para buscar todas as associações entre grupos e postes.
   */
  const fetchAssociations = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch('https://api.ads10.com.br/api/grupoposte', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      // Verificar se a sessão expirou
      if (response.status === 401) {
        toast.error('Sessão expirada. Por favor, faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erro ao buscar associações');
      }

      const data = await response.json();
      setAssociations(data);
    } catch (err) {
      console.error('Erro ao buscar associações:', err);
      setError(err.message);
      toast.error(`Erro ao buscar associações: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId, logout]);

  /**
   * Função para criar uma nova associação entre um grupo e um poste.
   * @param {number} grupoId - ID do grupo.
   * @param {number} posteId - ID do poste.
   */
  const createAssociation = useCallback(async (grupoId, posteId) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch('https://api.ads10.com.br/api/grupoposte', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify({
          grupoId: grupoId,
          posteId: posteId,
        }),
      });

      // Verificar se a sessão expirou
      if (response.status === 401) {
        toast.error('Sessão expirada. Por favor, faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erro ao criar associação');
      }

      const newAssociation = await response.json();
      setAssociations((prev) => [...prev, newAssociation]);
      toast.success('Associação criada com sucesso!');
      return newAssociation; // Retornar a nova associação para uso externo
    } catch (err) {
      console.error('Erro ao criar associação:', err);
      setError(err.message);
      toast.error(`Erro ao criar associação: ${err.message}`);
      throw err; // Re-throw para permitir tratamento no componente
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId, logout]);

  /**
   * Função para remover uma associação existente.
   * @param {number} grupoposteId - ID da associação a ser removida.
   */
  const removeAssociation = useCallback(async (grupoposteId) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`https://api.ads10.com.br/api/grupoposte/${grupoposteId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      // Verificar se a sessão expirou
      if (response.status === 401) {
        toast.error('Sessão expirada. Por favor, faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erro ao remover associação');
      }

      // Atualizar o estado local removendo a associação deletada
      setAssociations((prev) => prev.filter((assoc) => assoc.grupoposteId !== grupoposteId));
      toast.success('Associação removida com sucesso!');
    } catch (err) {
      console.error('Erro ao remover associação:', err);
      setError(err.message);
      toast.error(`Erro ao remover associação: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId, logout]);

  /**
   * Função para adicionar múltiplas associações de uma vez (opcional).
   * @param {Array} associationsArray - Array de objetos contendo grupoId e posteId.
   */
  const createMultipleAssociations = useCallback(async (associationsArray) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch('https://api.ads10.com.br/api/grupoposte', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(associationsArray),
      });

      // Verificar se a sessão expirou
      if (response.status === 401) {
        toast.error('Sessão expirada. Por favor, faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erro ao criar múltiplas associações');
      }

      const newAssociations = await response.json();
      setAssociations((prev) => [...prev, ...newAssociations]);
      toast.success('Associações criadas com sucesso!');
    } catch (err) {
      console.error('Erro ao criar múltiplas associações:', err);
      setError(err.message);
      toast.error(`Erro ao criar múltiplas associações: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId, logout]);

  return {
    associations,
    isLoading,
    error,
    fetchAssociations,
    createAssociation,
    removeAssociation,
    createMultipleAssociations, // Opcional
  };
};

export default useGroupPoste;
