import React, { useState } from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import styles from './FormPage.module.css';
import GenericModal from './GenericModal';

const FormPage = () => {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: '',
    });
    const [isModalOpen, setModalOpen] = useState(false);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      console.log('Dados do formulário:', formData);
    };
  
    const handleCancel = () => {
      setFormData({ name: '', email: '', message: '' });
    };
  
    const handleAdd = () => {
      setModalOpen(true);
    };
  
    const handleSave = (data) => {
      console.log('Novo item adicionado:', data);
      setModalOpen(false);
    };
  
    return (
      <PageLayout title="Formulário de Contato">
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="name">Nome:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          
          <div className={styles.formGroup}>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
  
          <div className={styles.formGroup}>
            <label htmlFor="message">Mensagem:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
  
          <div className={styles.buttonGroup}>
            <button type="submit" className={styles.submitButton}>Enviar</button>
            <button type="button" className={styles.cancelButton} onClick={handleCancel}>Cancelar</button>
            <button type="button" className={styles.addButton} onClick={handleAdd}>+ Adicionar</button>
          </div>
        </form>
  
        <GenericModal
          isOpen={isModalOpen}
          onRequestClose={() => setModalOpen(false)}
          onSave={handleSave}
        />
      </PageLayout>
    );
  };
  
  export default FormPage;