// src/components/Dashboard/ConfigModal.jsx

import React from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import styles from './Dashboard.module.css';

const ConfigModal = ({
  visibleCharts,
  chartOrder,
  onChartVisibilityChange,
  onChartOrderChange,
  onCompactModeChange,
  onClose,
  getChartDisplayName,
  cidadeId,
}) => {
  const handleMoveUp = (index) => {
    if (index === 0) return;
    const newOrder = [...chartOrder];
    [newOrder[index - 1], newOrder[index]] = [newOrder[index], newOrder[index - 1]];
    onChartOrderChange(newOrder);
  };

  const handleMoveDown = (index) => {
    if (index === chartOrder.length - 1) return;
    const newOrder = [...chartOrder];
    [newOrder[index + 1], newOrder[index]] = [newOrder[index], newOrder[index + 1]];
    onChartOrderChange(newOrder);
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2>Configurações</h2>
        <button
          className={styles.closeButton}
          onClick={onClose}
          aria-label="Fechar configurações"
        >
          &times;
        </button>

        <div className={styles.configSection}>
          <h3>Exibir Gráficos</h3>
          <div className={styles.checkboxGroup}>
            {chartOrder.map((chartKey, index) => {
              // Conditionally render based on cidadeId
              if (parseInt(cidadeId, 10) === 3315) {
                if (!['internos', 'externos', 'status', 'postesGeo', 'last4Months', 'currentMonth', 'osBySituation', 'osLast4Months', 'osCurrentMonth', 'posteStatusFotocelula'].includes(chartKey)) {
                  return null;
                }
              } else {
                if (!['todos', 'status', 'postesGeo', 'last4Months', 'currentMonth', 'osBySituation', 'osLast4Months', 'osCurrentMonth', 'posteStatusFotocelula'].includes(chartKey)) {
                  return null;
                }
              }

              return (
                <div key={chartKey} className={styles.chartOrderItem}>
                  <label className={styles.checkboxLabel}>
                    <input
                      type="checkbox"
                      checked={visibleCharts[chartKey]}
                      onChange={() => onChartVisibilityChange(chartKey)}
                    />
                    {getChartDisplayName(chartKey)}
                  </label>
                  <div className={styles.orderButtons}>
                    <button
                      className={styles.orderButton}
                      onClick={() => handleMoveUp(index)}
                      disabled={index === 0}
                      aria-label={`Mover ${getChartDisplayName(chartKey)} para cima`}
                    >
                      <FaArrowUp />
                    </button>
                    <button
                      className={styles.orderButton}
                      onClick={() => handleMoveDown(index)}
                      disabled={index === chartOrder.length - 1}
                      aria-label={`Mover ${getChartDisplayName(chartKey)} para baixo`}
                    >
                      <FaArrowDown />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.configSection}>
          <h3>Modo de Exibição</h3>
          <label className={styles.checkboxLabel}>
            <input
              type="checkbox"
              checked={visibleCharts.isCompactMode}
              onChange={onCompactModeChange}
            />
            Modo Compacto
          </label>
        </div>
      </div>
    </div>
  );
};

export default ConfigModal;
