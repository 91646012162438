import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [authState, setAuthState] = useState({
    token: null,
    usuarioId: null,
    usuarioNome: null,
    cidadeId: null,
    isAuthenticated: false,
    loading: true,
  });

  // Função de login
  const login = (token, usuarioId, usuarioNome, cidadeId, rememberMe) => {
    if (rememberMe) {
      localStorage.setItem('jwtToken', token);
      localStorage.setItem('usuarioId', usuarioId);
      localStorage.setItem('usuarioNome', usuarioNome);
      localStorage.setItem('cidadeId', cidadeId);
      localStorage.setItem('rememberMe', 'true');
    } else {
      sessionStorage.setItem('jwtToken', token);
      sessionStorage.setItem('usuarioId', usuarioId);
      sessionStorage.setItem('usuarioNome', usuarioNome);
      sessionStorage.setItem('cidadeId', cidadeId);
    }

    setAuthState({
      token,
      usuarioId,
      usuarioNome,
      cidadeId,
      isAuthenticated: true,
      loading: false,
    });

    // Redireciona para o Dashboard após login bem-sucedido
    navigate('/dashboard');
  };

  // Função de logout
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();

    setAuthState({
      token: null,
      usuarioId: null,
      usuarioNome: null,
      cidadeId: null,
      isAuthenticated: false,
      loading: false,
    });

    navigate('/login');
  };

  // Recuperar token e cidadeId ao carregar o aplicativo
  useEffect(() => {
    const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
    const usuarioId = localStorage.getItem('usuarioId') || sessionStorage.getItem('usuarioId');
    const usuarioNome = localStorage.getItem('usuarioNome') || sessionStorage.getItem('usuarioNome');
    const cidadeId = localStorage.getItem('cidadeId') || sessionStorage.getItem('cidadeId');

    if (token && cidadeId) {
      setAuthState({
        token,
        usuarioId,
        usuarioNome,
        cidadeId,
        isAuthenticated: true,
        loading: false,
      });
    } else {
      setAuthState({
        token: null,
        usuarioId: null,
        usuarioNome: null,
        cidadeId: null,
        isAuthenticated: false,
        loading: false,
      });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ ...authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
