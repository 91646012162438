import React, { useState, useEffect } from 'react';
import styles from './Tabela.module.css';

const Tabela = ({children}) => {

  return (
      <table className={styles.table}>
        {children}
      </table>);


};

export default Tabela;