import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate'; // Importar React Paginate
import { FaPlus, FaEdit, FaTrash, FaSortUp, FaSortDown } from 'react-icons/fa';
import { CSVLink } from 'react-csv';
import useClassificacaoVia from '../../../hooks/useClassificacaoVia';
import ClassificacaoViasModal from './ClassificacaoViaModal';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay'; // Importando o componente de Loading
import PageLayout from '../../../components/PageLayout/PageLayout';
import styles from './ClassificacaoVia.module.css';
import Tabela from '../../../components/Tabela/Tabela';


const ClassificacaoVias = () => {
  const {classificacoesVia, isClassificacaoViaLoading, classificacaoViaError, refetch, createClassificacaoVia, editClassificacaoVia, deleteClassificacaoVia} = useClassificacaoVia(true);
  const [selectedClassificacaoVia, setSelectedClassificacaoVia] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [tipoFilter, setTipoFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(0); // Estado para a página atual
  const itemsPerPage = 10; // Define o número de itens por página


  useEffect(() => {
  }, []);

  /* Funções de Modificação de dados  */
  const handleAddNew = () => {
    setSelectedClassificacaoVia(null);
    setIsModalOpen(true);
  };

  const handleEdit = (classificacaovia) => {
    setSelectedClassificacaoVia(classificacaovia);
    setIsModalOpen(true);
  };

  const handleDelete = (classificacaovia) => {
    const confirm = window.confirm('Tem certeza de que deseja deletar a Classificação Via: ' + classificacaovia.cv_nome + " (ID: " + classificacaovia.cv_id + ")?");

    if (!confirm) {
      return;
    }
    else {
      deleteClassificacaoVia(classificacaovia.cv_id).then(() => {
        refetch();
      });
    }
  };

  const handleSaveClassificacaoVia = (classificacaovia) => {
    //setIsLoading(false);
    if (classificacaovia.cv_id) {
      editClassificacaoVia(classificacaovia.cv_id, classificacaovia).then(() => {
        refetch();
      });
    }
    else {
      createClassificacaoVia(classificacaovia).then(() => {
        refetch();
      });
    }
  };


  // Funções de sort da tabela
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedClassificacaoVias = () => {
    const sortedClassificacaoVias = [...classificacoesVia];
    console.log(sortedClassificacaoVias);
    if (sortConfig.key) {
      sortedClassificacaoVias.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedClassificacaoVias;
  };

  const filteredClassificacaoVias = sortedClassificacaoVias().filter(classificacaovia => {
    const matchesSearchTerm = classificacaovia.cv_id.toString().toLowerCase().includes(searchTerm.toLowerCase());
    const matchesTipoFilter = tipoFilter ? classificacaovia.cv_nome.toLowerCase().includes(tipoFilter.toLowerCase()) : true;

    return matchesSearchTerm && matchesTipoFilter;
  });


  // Funções de paginação
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentPageData = filteredClassificacaoVias.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(filteredClassificacaoVias.length / itemsPerPage);

  // Funções de input de filtro/pesquisa
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTipoFilterChange = (e) => {
    setTipoFilter(e.target.value);
  };


  // Preparando dados para exportação CSV
  const headers = [
    { label: 'ID', key: 'cv_id' },
    { label: 'Tipo', key: 'cv_nome' },
  ];

  const csvData = filteredClassificacaoVias.map(classificacaovia => ({
    cv_id: classificacaovia.cv_id,
    cv_nome: classificacaovia.cv_nome,
  }));


  return (
    <PageLayout title="Lista de Classificação Vias">
      {isClassificacaoViaLoading && <LoadingOverlay />} {/* Loading screen enquanto os dados estão sendo carregados */}

      {!isClassificacaoViaLoading && (
        <>
        {/* Exibição de Erro */}
        {classificacaoViaError && (
            <div className={styles.errorContainer}>
              <p className={styles.errorMessage}>Erro: {classificacaoViaError}</p>
              <button className={styles.retryButton} onClick={refetch}>
                Tentar Novamente
              </button>
            </div>
          )}
          <div className={styles.controlsTop}>
            <button className={styles.addButton} onClick={handleAddNew}>
              <FaPlus /> Adicionar Nova Classificação de Via
            </button>
            <CSVLink
              data={csvData}
              headers={headers}
              filename={"classificacaovia-export.csv"}
              className={styles.exportButton}
              bom={true}
              separator={";"}
              enclosingCharacter={`"`}
            >
              Exportar para Excel
            </CSVLink>
          </div>
          <div className={styles.controls}>
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Pesquisar por ID da Classificação da Via..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Filtrar por Tipo..."
              value={tipoFilter}
              onChange={handleTipoFilterChange}
            />
          </div>

          {/* Tabela de Formato Poste */}
          <div className={styles.tableContainer}>
            <Tabela>
              <thead>
                <tr>
                  <th onClick={() => requestSort('cv_id')}>
                    ID {sortConfig.key === 'cv_id' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('cv_nome')}>
                    Classificação {sortConfig.key === 'cv_nome' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                 
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {currentPageData.map(classificacaovia => (
                  <tr key={classificacaovia.cv_id}>
                    <td>{classificacaovia.cv_id}</td>
                    <td>{classificacaovia.cv_nome}</td>
                    <td>
                      <button className={styles.actionButton} onClick={() => handleEdit(classificacaovia)}>
                        <FaEdit />
                      </button>
                      <button className={`${styles.actionButton} ${styles.deleteButton}`} onClick={() => handleDelete(classificacaovia)}>
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Tabela>
          </div>

          {/* Componente de paginação */}
          <div className={styles.paginationContainer}>
            <ReactPaginate
              previousLabel={"← Anterior"}
              nextLabel={"Próximo →"}
              breakLabel={"..."}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={styles.pagination}
              previousLinkClassName={styles.paginationLink}
              nextLinkClassName={styles.paginationLink}
              disabledClassName={styles.paginationDisabled}
              activeClassName={styles.paginationActive}
            />
          </div>


          {<ClassificacaoViasModal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            onSubmit={handleSaveClassificacaoVia}
            initialData={selectedClassificacaoVia}
          />}
        </>
      )}

    </PageLayout>);


};

export default ClassificacaoVias;