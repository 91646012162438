import React, { useState, useEffect } from 'react';
import { FaIdCard, FaTools, FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import styles from './BracoTiposModel.module.css';
import BaseModal from '../../../components/BaseModal/BaseModal';

Modal.setAppElement('#root');

/*
{
  "bt_id": "2",
  "bt_nome": "Sem Sapata"
}
*/
const BracoTiposModal = ({ isOpen, onRequestClose, onSubmit, initialData }) => {
  const [titulo, setTitulo] = useState("Novo Tipo de Braço");
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    bt_id: '',
    bt_nome: '',
  });

  useEffect(() => {
    if (initialData) {
      const formattedData = {
        ...initialData,
        ltId: initialData.ltId,
      };
      setFormData(formattedData);
      setTitulo("Editar Tipo de Braço");
      setEdit(true);
    } else {
      setFormData({
        bt_id: '',
        bt_nome: '',
      });
      setTitulo("Novo Tipo de Braço");
      setEdit(false);
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ ...formData });
    onRequestClose();
  };


  return (
    <BaseModal contentLabel={"Informações de Braço Tipo"} isOpen={isOpen} onRequestClose={onRequestClose}>
      <h1 className={styles.header}>{titulo}</h1>
      <form onSubmit={handleSubmit}>
        <fieldset className={styles.fieldset}>
          <div className={styles.formGroup}>
            {edit && (
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaIdCard /> ID</label>
                <input
                  className={styles.input}
                  type="text"
                  name="bt_id"
                  value={formData.bt_id}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            )}
            <div className={styles.colSpan1}>
              <label className={styles.label}><FaTools /> Tipo</label>
              <input
                className={styles.input}
                type="text"
                name="bt_nome"
                value={formData.bt_nome}
                onChange={handleChange}
              />
            </div>
          </div>
        </fieldset>

        <button className={styles.button} type="submit">Salvar</button>
      </form>

    </BaseModal>
  );
};

export default BracoTiposModal;
