// src/pages/Relatorios/RelatorioPostesGeolocalizacao.jsx

import React, { useEffect, useState } from 'react';
import { FaFileExport } from 'react-icons/fa';
import usePosteDataComAlertas from '../../hooks/usePosteDataComAlertas';
import styles from './Relatorios.module.css';
import * as XLSX from 'xlsx';

const RelatorioPostesGeolocalizacao = () => {
  const { postesDetalhados, isLoading, error } = usePosteDataComAlertas();
  const [filter, setFilter] = useState('all');
  const [filteredPostes, setFilteredPostes] = useState([]);

  useEffect(() => {
    if (filter === 'geolocalizados') {
      setFilteredPostes(postesDetalhados.filter((poste) => poste.geoLocalizacao));
    } else if (filter === 'nao-geolocalizados') {
      setFilteredPostes(postesDetalhados.filter((poste) => !poste.geoLocalizacao));
    } else {
      setFilteredPostes(postesDetalhados);
    }
  }, [filter, postesDetalhados]);

  const handleClearFilters = () => {
    setFilter('all');
  };

  const handleExportToExcel = () => {
    const exportData = filteredPostes.map((poste) => ({
      'ID do Poste': poste.id,
      'Código do Poste': poste.codigoPoste,
      'Geo-localização': poste.geoLocalizacao || 'N/A',
      'Tipo de Poste': poste.tipoPoste || 'Desconhecido',
      'Status': poste.ativo === 'S' ? 'Ativo' : 'Inativo',
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Postes Geolocalizados');
    XLSX.writeFile(workbook, 'postes_geolocalizados.xlsx');
  };

  return (
    <div className={styles.container}>
      <div className={styles.controlsTop}>
        {/* Filtro de Geração */}
        <div className={styles.filterGroup}>
          <h3>Filtro de Geração</h3>
          <div className={styles.controlGroup}>
            <label htmlFor="geoFilter">Filtrar por Geolocalização:</label>
            <select
              id="geoFilter"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className={styles.dropdown}
            >
              <option value="all">Exibir Todos</option>
              <option value="geolocalizados">Somente Geolocalizados</option>
              <option value="nao-geolocalizados">Somente Não Geolocalizados</option>
            </select>
          </div>
        </div>

        {/* Botões de Ação */}
        <div className={styles.actionsContainer}>
          <button onClick={handleExportToExcel} className={styles.exportButton}>
            <FaFileExport /> Exportar para Excel
          </button>
          <button onClick={handleClearFilters} className={styles.clearFiltersButton}>
            Limpar Filtros
          </button>
        </div>
      </div>

      {/* Mensagens de Status */}
      {isLoading && <p className={styles.infoMessage}>Carregando dados...</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}

      {/* Tabela de Dados */}
      {!isLoading && filteredPostes.length > 0 && (
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Código</th>
                <th>Geo-localização</th>
                <th>Tipo</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredPostes.map((poste) => (
                <tr key={poste.id}>
                  <td>{poste.id}</td>
                  <td>{poste.codigoPoste}</td>
                  <td>{poste.geoLocalizacao || 'N/A'}</td>
                  <td>{poste.tipoPoste || 'Desconhecido'}</td>
                  <td>{poste.ativo === 'S' ? 'Ativo' : 'Inativo'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Mensagem de Nenhum Dado Encontrado */}
      {!isLoading && filteredPostes.length === 0 && (
        <p className={styles.infoMessage}>Nenhum dado encontrado para a opção selecionada.</p>
      )}
    </div>
  );
};

export default RelatorioPostesGeolocalizacao;
