// src/pages/Levantamentos/graficos/GraficoDispositivos.jsx

import React, { useState, useEffect, useMemo } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { format } from 'date-fns'; // Importa a função format
import usePostesComFotocelulas from '../../../hooks/usePostesComFotocelulas';
import styles from '../Levantamentos.module.css';
import { toast } from 'react-toastify';

const GraficoDispositivos = () => {
  const { postes, fotocelulas, postefotocelulas, isLoading, error } = usePostesComFotocelulas();
  const [selectedPoste, setSelectedPoste] = useState('');
  const [selectedMetric, setSelectedMetric] = useState('deviceConsumo'); // Padrão: Consumo
  const [startDateTime, setStartDateTime] = useState('');
  const [endDateTime, setEndDateTime] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [isChartLoading, setIsChartLoading] = useState(false);
  const [chartError, setChartError] = useState(null);

  // Métricas disponíveis com rótulos e unidades
  const metricsOptions = useMemo(() => [
    { value: 'deviceConsumo', label: 'Consumo', unit: 'kWh' },
    { value: 'deviceKw', label: 'Potência', unit: 'kW' },
    { value: 'deviceTensaoRede', label: 'Tensão', unit: 'V' },
    { value: 'deviceCorrente', label: 'Corrente', unit: 'A' },
    { value: 'devicePf', label: 'Fator de Potência', unit: '' },
    { value: 'deviceTempoLampadaLigada', label: 'Tempo Ligado', unit: 's' },
  ], []);

  // Mapeamento das métricas para rótulos e unidades
  const metricsMap = useMemo(() => {
    const map = {};
    metricsOptions.forEach(metric => {
      map[metric.value] = { label: metric.label, unit: metric.unit };
    });
    return map;
  }, [metricsOptions]);

  // Memorizar postes com dispositivos para evitar recalcular a cada render
  const postesComDevices = useMemo(() => {
    return postes.filter(poste =>
      postefotocelulas.some(pf => pf.posteId === poste.id && pf.ativo === 'S') &&
      fotocelulas.some(f => f.deviceEUI && postefotocelulas.some(pf => pf.posteId === poste.id && pf.fotocelulaId === f.fotocelulaId && pf.ativo === 'S'))
    );
  }, [postes, fotocelulas, postefotocelulas]);

  // Extrai a lista de "codigoPoste" para o dropdown
  const codigoPostes = useMemo(() => {
    return postesComDevices.map((poste) => ({
      id: poste.id,
      codigoPoste: poste.codigoPoste,
    }));
  }, [postesComDevices]);

  const handlePosteChange = (e) => {
    setSelectedPoste(e.target.value);
    // Resetar datas ao mudar o poste
    setStartDateTime('');
    setEndDateTime('');
  };

  const handleMetricChange = (e) => {
    setSelectedMetric(e.target.value);
  };

  const handleStartDateTimeChange = (e) => {
    setStartDateTime(e.target.value);
  };

  const handleEndDateTimeChange = (e) => {
    setEndDateTime(e.target.value);
  };

  const handleAtualizar = () => {
    setIsChartLoading(true);
    // O useEffect será chamado automaticamente devido às dependências
  };

  const handleClearFilters = () => {
    setSelectedPoste('');
    setSelectedMetric('deviceConsumo');
    setStartDateTime('');
    setEndDateTime('');
    setIsChartLoading(true);
  };

  useEffect(() => {
    const getFilteredLogs = async () => {
      if (!selectedPoste) {
        setFilteredData([]);
        setIsChartLoading(false);
        return;
      }

      setIsChartLoading(true);
      setChartError(null);

      try {
        const poste = postesComDevices.find((poste) => poste.codigoPoste === selectedPoste);
        if (!poste) {
          setFilteredData([]);
          setIsChartLoading(false);
          return;
        }

        const posteId = poste.id;

        const fotocelulasAssociadas = fotocelulas.filter(f =>
          postefotocelulas.some(pf => pf.posteId === posteId && pf.fotocelulaId === f.fotocelulaId && pf.ativo === 'S') &&
          f.deviceEUI
        );

        const uniqueDeviceEUIs = [...new Set(fotocelulasAssociadas.map(f => f.deviceEUI))];

        const deviceDataPromises = uniqueDeviceEUIs.map(async (eui) => {
          try {
            const response = await fetch(`https://api.ads10.com.br/api/device/${eui}`, {
              headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) throw new Error(`Erro ao buscar dispositivo: ${eui}`);
            return await response.json();
          } catch (err) {
            console.error(err);
            return null;
          }
        });

        const devicesData = await Promise.all(deviceDataPromises);
        const allDevicesData = devicesData.flat();

        console.log('All Devices Data:', allDevicesData); // Depuração

        const dispositivosValidos = allDevicesData.filter(device => device !== null && device[selectedMetric] !== undefined && device.Data);

        console.log('Dispositivos Válidos:', dispositivosValidos); // Depuração

        // Converter strings de data para objetos Date para filtragem
        const start = startDateTime ? new Date(startDateTime) : null;
        const end = endDateTime ? new Date(endDateTime) : null;

        const processedLogs = dispositivosValidos
          .filter(device => {
            const deviceDate = new Date(device.Data);
            if (start && deviceDate < start) return false;
            if (end && deviceDate > end) return false;
            return true;
          })
          .map(device => ({
            data: device.Data, // Mantém data e hora
            value: parseFloat(device[selectedMetric]),
          }));

        console.log('Processed Logs:', processedLogs); // Depuração

        // Ordenar os dados por data e hora
        const sortedData = processedLogs.sort((a, b) => new Date(a.data) - new Date(b.data));

        setFilteredData(sortedData);
      } catch (err) {
        console.error('Erro ao processar dados do gráfico:', err);
        setChartError(err.message);
        toast.error(`Erro ao processar dados do gráfico: ${err.message}`);
      } finally {
        setIsChartLoading(false);
      }
    };

    getFilteredLogs();
  }, [selectedPoste, selectedMetric, startDateTime, endDateTime, postesComDevices, fotocelulas, postefotocelulas]);

  if (isLoading) {
    return <p className={styles.infoMessage}>Carregando dados dos postes...</p>;
  }

  if (error) {
    return <p className={styles.errorMessage}>Erro: {error}</p>;
  }

  return (
    <div className={styles.chartCard}>
      <h3 className={styles.chartTitle}>
        Logs de Leituras dos Dispositivos - {metricsMap[selectedMetric].label} {metricsMap[selectedMetric].unit && `(${metricsMap[selectedMetric].unit})`}
      </h3>

      {/* Seção de Filtros Compacta */}
      <div className={styles.controlsTop}>
        <div className={styles.filterGroup}>
          <h3 className={styles.filterGroupTitle}>Filtro de Geração</h3>

          {/* Container Flexível para Inputs Lado a Lado */}
          <div className={styles.filtersContainer}>
            <div className={styles.filterItem}>
              <label htmlFor="posteSelect" className={styles.filterLabel}>Selecionar Código do Poste:</label>
              <select
                id="posteSelect"
                value={selectedPoste}
                onChange={handlePosteChange}
                className={styles.dropdown}
              >
                <option value="">-- Selecione um Poste --</option>
                {codigoPostes.map((poste) => (
                  <option key={poste.id} value={poste.codigoPoste}>{poste.codigoPoste}</option>
                ))}
              </select>
            </div>

            <div className={styles.filterItem}>
              <label htmlFor="metricSelect" className={styles.filterLabel}>Selecionar Métrica:</label>
              <select
                id="metricSelect"
                value={selectedMetric}
                onChange={handleMetricChange}
                className={styles.dropdown}
                disabled={!selectedPoste}
              >
                {metricsOptions.map((metric) => (
                  <option key={metric.value} value={metric.value}>
                    {metric.label} {metric.unit && `(${metric.unit})`}
                  </option>
                ))}
              </select>
            </div>

            <div className={styles.filterItem}>
              <label htmlFor="startDateTime" className={styles.filterLabel}>Data e Hora Início:</label>
              <input
                type="datetime-local"
                id="startDateTime"
                value={startDateTime}
                onChange={handleStartDateTimeChange}
                className={styles.dropdown}
                disabled={!selectedPoste}
              />
            </div>

            <div className={styles.filterItem}>
              <label htmlFor="endDateTime" className={styles.filterLabel}>Data e Hora Fim:</label>
              <input
                type="datetime-local"
                id="endDateTime"
                value={endDateTime}
                onChange={handleEndDateTimeChange}
                className={styles.dropdown}
                disabled={!selectedPoste}
              />
            </div>
          </div>
        </div>

        {/* Botões de Ação */}
        <div className={styles.actionsContainer}>
          <button onClick={handleAtualizar} className={styles.button}>
            Atualizar
          </button>
          <button onClick={handleClearFilters} className={styles.clearFiltersButton}>
            Limpar Filtros
          </button>
        </div>
      </div>

      {/* Gráfico */}
      {isChartLoading ? (
        <p className={styles.infoMessage}>Carregando dados do gráfico...</p>
      ) : chartError ? (
        <p className={styles.errorMessage}>Erro: {chartError}</p>
      ) : selectedPoste ? (
        filteredData.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={filteredData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="data"
                tickFormatter={(tick) => format(new Date(tick), 'dd/MM/yyyy HH:mm')}
                minTickGap={20}
              />
              <YAxis 
                label={{
                  value: `${metricsMap[selectedMetric].label} ${metricsMap[selectedMetric].unit}`,
                  angle: -90,
                  position: 'insideLeft',
                  offset: 10,
                }}
              />
              <Tooltip 
                labelFormatter={(label) => format(new Date(label), 'dd/MM/yyyy HH:mm:ss')}
                formatter={(value) => `${value} ${metricsMap[selectedMetric].unit}`}
              />
              <Legend verticalAlign="top" height={36} />
              <Line 
                type="monotone" 
                dataKey="value" 
                name={`${metricsMap[selectedMetric].label} ${metricsMap[selectedMetric].unit}`} 
                stroke="#8884d8" 
                activeDot={{ r: 8 }} 
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <p className={styles.infoMessage}>Nenhum dado disponível para o poste selecionado no período especificado.</p>
        )
      ) : (
        <p className={styles.infoMessage}>Por favor, selecione um poste para visualizar o gráfico.</p>
      )}
    </div>
  );
};

export default GraficoDispositivos;
