// src/pages/Mapa/Modal.jsx

import React from 'react';
import styles from './Mapa.module.css';

const Modal = ({ isOpen, onClose, title, children, className }) => {
  if (!isOpen) return null;

  return (
    <div className={`${styles.modalOverlay} ${className || ''}`} onClick={onClose}>
      <div
        className={styles.modalContent}
        onClick={(e) => e.stopPropagation()} // Impede que o clique no conteúdo feche o modal
      >
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>{title}</h2>
          <button className={styles.closeButton} onClick={onClose}>
            &times;
          </button>
        </div>
        <div className={styles.modalBody}>{children}</div>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  title: '',
  children: null,
  className: '',
};

export default Modal;
