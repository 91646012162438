import { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

/**
 * Hook customizado para buscar a lista de tipos de poste.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @returns {object} - Contém a lista de tipos de poste, estado de carregamento, erro e função para refetch.
 */
const useTipoPoste = (isOpen) => {
  const URL = 'https://api.ads10.com.br/api/tipoposte';
  const { token, logout, cidadeId } = useContext(AuthContext); // Obtém token e logout do AuthContext
  const [tiposPoste, setTiposPoste] = useState([]);
  const [isTipoPosteLoading, setIsTipoPosteLoading] = useState(false);
  const [tipoPosteError, setTipoPosteError] = useState(null);

  /**
   * Função para buscar os tipos de poste da API.
   */
  const fetchTipoPoste = useCallback(async () => {
    if (!isOpen) return; // Não buscar se o modal não estiver aberto

    setIsTipoPosteLoading(true);
    setTipoPosteError(null);
    try {
      if (!token) throw new Error('Token de autenticação não encontrado');

      const response = await fetch(URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar tipos de poste: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Tipos de Poste recebidos da API:', data);
      setTiposPoste(data);
    } catch (error) {
      console.error('Erro ao buscar tipos de poste:', error);
      setTipoPosteError('Falha ao carregar a lista de tipos de poste. Verifique sua conexão ou tente novamente mais tarde.');
      toast.error(`Erro ao buscar tipos de poste: ${error.message}`);
    } finally {
      setIsTipoPosteLoading(false);
    }
  }, [isOpen, token, cidadeId, logout]);


  // Criar Tipo Poste metodo POST
  const createTipoPoste = useCallback(async (tipoposte) => {
    if (!token || !cidadeId) {
      setTipoPosteError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsTipoPosteLoading(true);
    setTipoPosteError(null);

    try {
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(tipoposte),
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao criar Tipo Poste: ${response.status} ${response.statusText}`);
      }

      toast.success('Tipo Poste criado com sucesso!');
    }
    catch (error) {
      console.error('Erro ao criar Tipo Poste:', error);
      setTipoPosteError(error.message);
      toast.error(`Erro ao criar Tipo Poste: ${error.message}`);
    } finally {
      setIsTipoPosteLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  // Editar Tipo Poste pelo metodo PUT
  const editTipoPoste = useCallback(async (tipoposteid, tipoposte) => {
    if (!token || !cidadeId) {
      setTipoPosteError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    if (tipoposteid == null || tipoposteid <= 0) {
      setTipoPosteError('ID do Tipo Poste Invalido');
      return;
    }

    setIsTipoPosteLoading(true);
    setTipoPosteError(null);

    try {
      const response = await fetch(URL + '/' + tipoposteid, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(tipoposte),
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao editar Tipo Poste: ${response.status} ${response.statusText}`);
      }

      toast.success('Tipo Poste Editado com sucesso!');
    }
    catch (error) {
      console.error('Erro ao editar Tipo Poste:', error);
      setTipoPosteError(error.message);
      toast.error(`Erro ao editar Tipo Poste: ${error.message}`);
    } finally {
      setIsTipoPosteLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  // Deletar Tipo Poste pelo metodo DELETE
  const deleteTipoPoste = useCallback(async (tipoposteid) => {
    if (!token || !cidadeId) {
      setTipoPosteError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    if (tipoposteid == null || tipoposteid <= 0) {
      setTipoPosteError('ID do Tipo Poste Invalido');
      return;
    }

    setIsTipoPosteLoading(true);
    setTipoPosteError(null);

    try {
      const response = await fetch(URL + '/' + tipoposteid, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao deletar Tipo Poste: ${response.status} ${response.statusText}`);
      }

      toast.success('Tipo Poste Deletado com sucesso!');
    }
    catch (error) {
      console.error('Erro ao deletar Tipo Poste:', error);
      setTipoPosteError(error.message);
      toast.error(`Erro ao deletar Tipo Poste: ${error.message}`);
    } finally {
      setIsTipoPosteLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  useEffect(() => {
    fetchTipoPoste();
  }, [fetchTipoPoste]);

  return { tiposPoste: tiposPoste, isTipoPosteLoading, tipoPosteError, refetch: fetchTipoPoste, createTipoPoste: createTipoPoste, editTipoPoste: editTipoPoste, deleteTipoPoste: deleteTipoPoste };
};

export default useTipoPoste;
