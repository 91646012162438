import { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

const useBracoTipo = () => {
  const URL = `https://api.ads10.com.br/api/bracotipo`;
  const { token, cidadeId, logout } = useContext(AuthContext);
  const [bracosTipos, setBracosTipos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Função para buscar e combinar dados dos postes, fotocélulas e mapeamentos
  const fetchData = useCallback(async () => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao buscar Braços Tipos: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      setBracosTipos(data);

    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setError(error.message);
      toast.error(`Erro ao buscar dados: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [URL, token, cidadeId, logout]);

  // Criar braços tipos metodo POST
  const createBracoTipo = useCallback(async (bracotipo) => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(bracotipo),
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao criar Braço Tipos: ${response.status} ${response.statusText}`);
      }

      toast.success('Braço Tipo criado com sucesso!');
    }
    catch (error) {
      console.error('Erro ao criar Braço Tipo:', error);
      setError(error.message);
      toast.error(`Erro ao criar Braço Tipo: ${error.message}`);
    } finally {
      setIsLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  // Editar Braço Tipo pelo metodo PUT
  const editBracoTipo = useCallback(async (bracotipoid, bracotipo) => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    if (bracotipoid == null || bracotipoid <= 0) {
      setError('ID do Braco Tipo Invalido');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(URL + '/' + bracotipoid, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(bracotipo),
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao editar Braço Tipo: ${response.status} ${response.statusText}`);
      }

      toast.success('Braço Tipo Editado com sucesso!');
    }
    catch (error) {
      console.error('Erro ao editar Braço Tipo:', error);
      setError(error.message);
      toast.error(`Erro ao editar Braço Tipo: ${error.message}`);
    } finally {
      setIsLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  // Deletar braco tipo pelo metodo DELETE
  const deleteBracoTipo = useCallback(async (bracotipoid) => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    if (bracotipoid == null || bracotipoid <= 0) {
      setError('ID da Braço Tipos Tipo');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(URL + '/' + bracotipoid, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao deletar Braço Tipo: ${response.status} ${response.statusText}`);
      }

      toast.success('Braço Tipo Deletado com sucesso!');
    }
    catch (error) {
      console.error('Erro ao deletar Braço Tipo:', error);
      setError(error.message);
      toast.error(`Erro ao deletar Braço Tipo: ${error.message}`);
    } finally {
      setIsLoading(false);
    }

  }, [URL, token, cidadeId, logout]);


  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { bracoTipos: bracosTipos, isLoading, error, refetch: fetchData, createBracoTipo: createBracoTipo, editBracoTipo: editBracoTipo, deleteBracoTipo: deleteBracoTipo };
};

export default useBracoTipo;