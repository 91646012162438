import { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';


const useFormatoTampa = (isOpen) => {
  const URL = 'https://api.ads10.com.br/api/formatotampa';
  const { token, logout, cidadeId } = useContext(AuthContext); // Obtém token e logout do contexto
  const [formatosTampa, setFormatosTampa] = useState([]);
  const [isFormatoTampaLoading, setIsFormatoTampaLoading] = useState(false);
  const [formatoTampaError, setFormatoTampaError] = useState(null);

  const fetchFormatoTampa = useCallback(async () => {
    if (!isOpen) return;

    setIsFormatoTampaLoading(true);
    setFormatoTampaError(null);
    try {
      if (!token) throw new Error('Token de autenticação não encontrado');

      const response = await fetch(URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar formatos de tampo: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Formatos de Tampo recebidos da API:', data);
      setFormatosTampa(data);
    } catch (error) {
      console.error('Erro ao buscar formatos de tampo:', error);
      setFormatoTampaError(error.message.includes('Token')
        ? 'Você precisa estar autenticado para carregar a lista de formatos de tampo.'
        : 'Falha ao carregar a lista de formatos de tampo. Verifique sua conexão ou tente novamente mais tarde.');
      toast.error(setFormatoTampaError);
    } finally {
      setIsFormatoTampaLoading(false);
    }
  }, [isOpen, cidadeId, token, logout]);


   // Criar Formato Tampa metodo POST
   const createFormatoTampa = useCallback(async (formatotampa) => {
    if (!token || !cidadeId) {
      setFormatoTampaError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsFormatoTampaLoading(true);
    setFormatoTampaError(null);

    try {
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(formatotampa),
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao criar Formato Tampa: ${response.status} ${response.statusText}`);
      }

      toast.success('Formato Tampa criado com sucesso!');
    }
    catch (error) {
      console.error('Erro ao criar Formato Tampa:', error);
      setFormatoTampaError(error.message);
      toast.error(`Erro ao criar Formato Tampa: ${error.message}`);
    } finally {
      setIsFormatoTampaLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  // Editar Formato Tampa pelo metodo PUT
  const editFormatoTampa = useCallback(async (formatotampaid, formatotampa) => {
    if (!token || !cidadeId) {
      setFormatoTampaError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    if (formatotampaid == null || formatotampaid <= 0) {
      setFormatoTampaError('ID do Formato Tampa Invalido');
      return;
    }

    setIsFormatoTampaLoading(true);
    setFormatoTampaError(null);

    try {
      const response = await fetch(URL + '/' + formatotampaid, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(formatotampa),
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao editar Formato Tampa: ${response.status} ${response.statusText}`);
      }

      toast.success('Formato Tampa Editado com sucesso!');
    }
    catch (error) {
      console.error('Erro ao editar Formato Tampa:', error);
      setFormatoTampaError(error.message);
      toast.error(`Erro ao editar Formato Tampa: ${error.message}`);
    } finally {
      setIsFormatoTampaLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  // Deletar Formato Tampa pelo metodo DELETE
  const deleteFormatoTampa = useCallback(async (formatotampaid) => {
    if (!token || !cidadeId) {
      setFormatoTampaError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    if (formatotampaid == null || formatotampaid <= 0) {
      setFormatoTampaError('ID do Formato Tampa Invalido');
      return;
    }

    setIsFormatoTampaLoading(true);
    setFormatoTampaError(null);

    try {
      const response = await fetch(URL + '/' + formatotampaid, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao deletar Formato Tampa: ${response.status} ${response.statusText}`);
      }

      toast.success('Formato Tampa Deletado com sucesso!');
    }
    catch (error) {
      console.error('Erro ao deletar Formato Tampa:', error);
      setFormatoTampaError(error.message);
      toast.error(`Erro ao deletar Formato Tampa: ${error.message}`);
    } finally {
      setIsFormatoTampaLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  useEffect(() => {
    fetchFormatoTampa();
  }, [fetchFormatoTampa]);

  return { formatoTampa: formatosTampa, isFormatoTampaLoading, formatoTampaError, refetch: fetchFormatoTampa, createFormatoTampa: createFormatoTampa, editFormatoTampa: editFormatoTampa, deleteFormatoTampa: deleteFormatoTampa };
};

export default useFormatoTampa;
