// src/components/ConfigurationProfileModal/ConfigurationProfileModal.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import { FaTimes, FaSave } from 'react-icons/fa';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { toast } from 'react-toastify';
import styles from './ConfigurationProfileModal.module.css';

const ConfigurationProfileModal = ({
  isOpen,
  onRequestClose,
  onSave,
  initialData,
}) => {
  // Estados para os campos do perfil
  const [perfilposteNome, setPerfilposteNome] = useState('');
  const [perfilposteHoraAcender, setPerfilposteHoraAcender] = useState('');
  const [perfilposteHoraApagar, setPerfilposteHoraApagar] = useState('');
  const [perfilposteDimerizacao, setPerfilposteDimerizacao] = useState(50);

  // Adicionado: Potência da luminária
  const [potenciaLuminaria, setPotenciaLuminaria] = useState(100); // Valor padrão: 100 W

  // Adicionado: Tarifa de energia
  const [electricityRate, setElectricityRate] = useState(0.5); // Valor padrão: R$0,5 por kWh

  // Estados para os cálculos de economia
  const [consumoTotal, setConsumoTotal] = useState(0);
  const [consumoComDimerizacao, setConsumoComDimerizacao] = useState(0);
  const [energySavings, setEnergySavings] = useState(0);
  const [costSavings, setCostSavings] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  // Função para calcular o tempo ligado
  const calcularTempoLigado = useCallback(() => {
    if (!perfilposteHoraAcender || !perfilposteHoraApagar) return 0;

    const [startHour, startMinute] = perfilposteHoraAcender
      .split(':')
      .map(Number);
    const [endHour, endMinute] = perfilposteHoraApagar.split(':').map(Number);

    let startTime = startHour + startMinute / 60;
    let endTime = endHour + endMinute / 60;

    // Considera virada do dia
    if (endTime <= startTime) {
      endTime += 24;
    }

    return endTime - startTime; // Tempo ligado em horas por dia
  }, [perfilposteHoraAcender, perfilposteHoraApagar]);

  // Inicializar os campos quando o modal abre
  useEffect(() => {
    if (isOpen) {
      if (initialData) {
        setPerfilposteNome(initialData.perfilposteNome || '');
        setPerfilposteHoraAcender(initialData.perfilposteHoraAcender || '');
        setPerfilposteHoraApagar(initialData.perfilposteHoraApagar || '');
        setPerfilposteDimerizacao(
          initialData.perfilposteDimerizacao !== undefined
            ? initialData.perfilposteDimerizacao
            : 50
        );
        setPotenciaLuminaria(
          initialData.potenciaLuminaria !== undefined
            ? initialData.potenciaLuminaria
            : 100
        );
        setElectricityRate(
          initialData.electricityRate !== undefined
            ? initialData.electricityRate
            : 0.5
        );
      } else {
        setPerfilposteNome('');
        setPerfilposteHoraAcender('');
        setPerfilposteHoraApagar('');
        setPerfilposteDimerizacao(50);
        setPotenciaLuminaria(100);
        setElectricityRate(0.5);
      }
      setConsumoTotal(0);
      setConsumoComDimerizacao(0);
      setEnergySavings(0);
      setCostSavings(0);
    }
  }, [isOpen, initialData]);

  // Cálculo da economia
  useEffect(() => {
    const tempoLigado = calcularTempoLigado(); // horas/dia
    const potenciaKwh = potenciaLuminaria / 1000; // Convertendo watts para quilowatts
    const consumoTotalCalculado = potenciaKwh * tempoLigado * 30; // Consumo mensal (30 dias)
    const consumoComDimerizacaoCalculado =
      consumoTotalCalculado * (perfilposteDimerizacao / 100); // Aplicando dimerização

    const economiaEnergia = consumoTotalCalculado - consumoComDimerizacaoCalculado;
    const economiaFinanceira = economiaEnergia * electricityRate;

    setConsumoTotal(consumoTotalCalculado);
    setConsumoComDimerizacao(consumoComDimerizacaoCalculado);
    setEnergySavings(economiaEnergia);
    setCostSavings(economiaFinanceira);
  }, [
    calcularTempoLigado,
    potenciaLuminaria,
    perfilposteDimerizacao,
    electricityRate,
  ]);

  const handleSave = async () => {
    // Validação dos campos obrigatórios
    if (!perfilposteNome || !perfilposteHoraAcender || !perfilposteHoraApagar) {
      toast.error('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    // Validação da dimerização
    if (perfilposteDimerizacao < 0 || perfilposteDimerizacao > 100) {
      toast.error('O valor de dimerização deve estar entre 0 e 100.');
      return;
    }

    // Validação da potência
    if (potenciaLuminaria <= 0) {
      toast.error('A potência da luminária deve ser maior que zero.');
      return;
    }

    // Validação da tarifa de energia
    if (electricityRate < 0) {
      toast.error('A tarifa de energia não pode ser negativa.');
      return;
    }

    setIsSaving(true);
    try {
      const perfilData = {
        perfilposteNome,
        perfilposteHoraAcender,
        perfilposteHoraApagar,
        perfilposteDimerizacao: parseInt(perfilposteDimerizacao, 10),
        potenciaLuminaria: parseFloat(potenciaLuminaria),
        electricityRate: parseFloat(electricityRate), // Incluído para possivelmente enviar ao backend
      };

      if (initialData && initialData.perfilposteId) {
        perfilData.perfilposteId = initialData.perfilposteId;
      }

      console.log('Perfil data being sent:', perfilData);

      await onSave(perfilData);
      toast.success('Perfil salvo com sucesso!');
    } catch (error) {
      console.error('Erro ao salvar o perfil:', error);
      toast.error('Erro ao salvar o perfil.');
    } finally {
      setIsSaving(false);
      onRequestClose();
    }
  };

  // Dados para o gráfico de barras com nomenclaturas ajustadas
  const data = useMemo(
    () => [
      {
        name: 'Consumo sem Dimerização (kWh)',
        value: parseFloat(consumoTotal.toFixed(2)),
      }, // Consumo sem dimerização
      {
        name: 'Economia de Consumo (kWh)',
        value: parseFloat(energySavings.toFixed(2)),
      }, // Economia de energia
      {
        name: 'Consumo com Dimerização (kWh)',
        value: parseFloat(consumoComDimerizacao.toFixed(2)),
      }, // Consumo com dimerização
    ],
    [consumoTotal, energySavings, consumoComDimerizacao]
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
      ariaHideApp={false}
    >
      <button className={styles.closeModalButton} onClick={onRequestClose}>
        <FaTimes />
      </button>
      <div className={styles.modalContent}>
        <h2 className={styles.modalTitle}>
          {initialData ? 'Editar Perfil' : 'Novo Perfil'}
        </h2>

        {/* Nome do Perfil */}
        <div className={styles.controlGroup}>
          <label htmlFor="perfilposteNome">Nome do Perfil:</label>
          <input
            type="text"
            id="perfilposteNome"
            value={perfilposteNome}
            onChange={(e) => setPerfilposteNome(e.target.value)}
            className={styles.input}
            required
          />
        </div>

        {/* Hora de Acender */}
        <div className={styles.controlGroup}>
          <label htmlFor="perfilposteHoraAcender">Hora de Acender:</label>
          <input
            type="time"
            id="perfilposteHoraAcender"
            value={perfilposteHoraAcender}
            onChange={(e) => setPerfilposteHoraAcender(e.target.value)}
            className={styles.input}
            required
          />
        </div>

        {/* Hora de Apagar */}
        <div className={styles.controlGroup}>
          <label htmlFor="perfilposteHoraApagar">Hora de Apagar:</label>
          <input
            type="time"
            id="perfilposteHoraApagar"
            value={perfilposteHoraApagar}
            onChange={(e) => setPerfilposteHoraApagar(e.target.value)}
            className={styles.input}
            required
          />
        </div>

        {/* Dimerização */}
        <div className={styles.controlGroup}>
          <label htmlFor="perfilposteDimerizacao">Dimerização (%):</label>
          <input
            type="range"
            min="0"
            max="100"
            id="perfilposteDimerizacao"
            value={perfilposteDimerizacao}
            onChange={(e) => setPerfilposteDimerizacao(e.target.value)}
            className={styles.slider}
          />
          <span>{perfilposteDimerizacao}%</span>
        </div>

        {/* Seção de Economia Estimada */}
        <div className={styles.energySavings}>
          <h3>Economia Estimada</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data}>
              <XAxis dataKey="name" />
              <YAxis domain={[0, consumoTotal > 0 ? consumoTotal * 1.1 : 100]} />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
          <p>
            <strong>Energia Economizada:</strong> {energySavings.toFixed(2)} kWh
          </p>
          <p>
            <strong>Economia em Reais:</strong> R${costSavings.toFixed(2)}
          </p>
        </div>

        {/* Seção de Inputs Hipotéticos */}
        <div className={styles.hypotheticalInputs}>
          <h3>Parâmetros</h3>
          {/* Potência da Luminária */}
          <div className={styles.controlGroup}>
            <label htmlFor="potenciaLuminaria">
              Potência da Luminária (W):
              <span className={styles.hint}> *Valor Hipotético*</span>
            </label>
            <input
              type="number"
              id="potenciaLuminaria"
              value={potenciaLuminaria}
              onChange={(e) => setPotenciaLuminaria(parseFloat(e.target.value))}
              className={styles.input}
              min="1"
              step="1"
              required
            />
          </div>

          {/* Tarifa de Energia */}
          <div className={styles.controlGroup}>
            <label htmlFor="electricityRate">
              Tarifa de Energia (R$/kWh):
              <span className={styles.hint}> *Valor Hipotético*</span>
            </label>
            <input
              type="number"
              id="electricityRate"
              value={electricityRate}
              onChange={(e) => setElectricityRate(parseFloat(e.target.value))}
              className={styles.input}
              min="0"
              step="0.01"
            />
          </div>
        </div>

        {/* Botão para Salvar */}
        <button
          onClick={handleSave}
          className={styles.saveButton}
          disabled={isSaving}
        >
          {isSaving ? 'Salvando...' : <><FaSave /> Salvar</>}
        </button>
      </div>
    </Modal>
  );
};

export default ConfigurationProfileModal;
