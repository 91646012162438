// src/hooks/usePostesComFotocelulas.js

import { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

const usePostesComFotocelulas = () => {
  const { token, cidadeId, logout } = useContext(AuthContext);
  const [postes, setPostes] = useState([]);
  const [fotocelulas, setFotocelulas] = useState([]);
  const [postefotocelulas, setPostefotocelulas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPostesComFotocelulas = useCallback(async () => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        cidadeId: cidadeId,
      };

      const [posteRes, fotocelulaRes, postefotocelulaRes] = await Promise.all([
        fetch('https://api.ads10.com.br/api/poste', { headers }),
        fetch('https://api.ads10.com.br/api/fotocelula', { headers }),
        fetch('https://api.ads10.com.br/api/postefotocelula', { headers }),
      ]);

      if ([posteRes, fotocelulaRes, postefotocelulaRes].some((res) => res.status === 401)) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (![posteRes, fotocelulaRes, postefotocelulaRes].every((res) => res.ok)) {
        throw new Error('Erro ao buscar dados das APIs');
      }

      const [postesData, fotocelulasData, postefotocelulasData] = await Promise.all([
        posteRes.json(),
        fotocelulaRes.json(),
        postefotocelulaRes.json(),
      ]);

      setPostes(postesData);
      setFotocelulas(fotocelulasData);
      setPostefotocelulas(postefotocelulasData);
    } catch (err) {
      console.error('Erro ao buscar dados:', err);
      setError(err.message);
      toast.error(`Erro ao buscar dados: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId, logout]);

  useEffect(() => {
    fetchPostesComFotocelulas();
  }, [fetchPostesComFotocelulas]);

  return {
    postes,
    fotocelulas,
    postefotocelulas,
    isLoading,
    error,
    refetch: fetchPostesComFotocelulas,
  };
};

export default usePostesComFotocelulas;
