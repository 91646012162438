// src/pages/Levantamentos/Levantamentos.jsx

import React, { useState } from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import GraficoAlertas from './graficos/GraficoAlertas';
import GraficoOrdensServico from './graficos/GraficoOrdensServico';
import GraficoDispositivos from './graficos/GraficoDispositivos';
import styles from './Levantamentos.module.css';

const Levantamentos = () => {
  const [dataType, setDataType] = useState(''); // Inicializado como vazio para mostrar a mensagem inicialmente

  const handleDataTypeChange = (e) => {
    setDataType(e.target.value);
  };

  const renderGrafico = () => {
    switch (dataType) {
      case 'alerts':
        return <GraficoAlertas />;
      case 'orders':
        return <GraficoOrdensServico />;
      case 'logs':
        return <GraficoDispositivos />;
      default:
        return (
          <p className={styles.infoMessage}>
            Por favor, selecione um tipo de dados para visualizar o gráfico.
          </p>
        );
    }
  };

  return (
    <PageLayout title="Levantamentos">
      <div className={styles.levantamentosPage}>
        <div className={styles.controls}>
          {/* Dropdown Tipo de Dados */}
          <div className={styles.controlGroup}>
            <label htmlFor="dataType">Tipo de Dados:</label>
            <div className={styles.dataTypeContainer}>
              <select
                id="dataType"
                value={dataType}
                onChange={handleDataTypeChange}
                className={styles.selectInput}
              >
                <option value=''>Selecione</option>
                <option value="logs">Logs de Dispositivos</option>
                <option value="alerts">Alertas</option>
                <option value="orders">Ordens de Serviço</option>
              </select>
            </div>
          </div>
        </div>

        <div className={styles.chartContainer}>
          {renderGrafico()}
        </div>
      </div>
    </PageLayout>
  );
};

export default Levantamentos;
