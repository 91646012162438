// src/components/UserModal/UserModal.jsx

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styles from './UserModal.module.css';
import { FaTimes } from 'react-icons/fa';

const UserModal = ({ isOpen, onRequestClose, userData, onSave }) => {
  const [formData, setFormData] = useState({
    login: '',
    nome: '',
    email: '',
    celular: '',
    administrador: false,
    prestador: false,
    ativo: false,
  });

  useEffect(() => {
    if (userData) {
      setFormData({
        login: userData.login || '',
        nome: userData.nome || '',
        email: userData.email || '',
        celular: userData.celular || '',
        administrador: userData.administrador || false,
        prestador: userData.prestador || false,
        ativo: userData.ativo || false,
      });
    }
  }, [userData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({
      ...userData,
      ...formData,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Editar Usuário"
      className={styles.modal}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <button className={styles.closeButton} onClick={onRequestClose} aria-label="Fechar Modal">
        <FaTimes />
      </button>
      <div className={styles.modalContent}>
        <h2>Editar Usuário</h2>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="login">Login</label>
            <input
              type="text"
              id="login"
              name="login"
              value={formData.login}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="nome">Nome</label>
            <input
              type="text"
              id="nome"
              name="nome"
              value={formData.nome}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="celular">Celular</label>
            <input
              type="text"
              id="celular"
              name="celular"
              value={formData.celular}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.checkboxGroup}>
            <label htmlFor="administrador" className={styles.checkboxLabel}>
              <input
                type="checkbox"
                id="administrador"
                name="administrador"
                checked={formData.administrador}
                onChange={handleChange}
              />
              Administrador
            </label>
          </div>
          <div className={styles.checkboxGroup}>
            <label htmlFor="prestador" className={styles.checkboxLabel}>
              <input
                type="checkbox"
                id="prestador"
                name="prestador"
                checked={formData.prestador}
                onChange={handleChange}
              />
              Prestador
            </label>
          </div>
          <div className={styles.checkboxGroup}>
            <label htmlFor="ativo" className={styles.checkboxLabel}>
              <input
                type="checkbox"
                id="ativo"
                name="ativo"
                checked={formData.ativo}
                onChange={handleChange}
              />
              Ativo
            </label>
          </div>
          <div className={styles.formActions}>
            <button type="submit" className={styles.saveButton}>
              Salvar
            </button>
            <button type="button" className={styles.cancelButton} onClick={onRequestClose}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UserModal;
