// src/pages/PosteGrupos/JanelaGrupos.js

import React, { useState, useContext, useEffect } from 'react';
import useGruposData from '../../hooks/useGruposData';
import GrupoModal from './GrupoModal';
import styles from './JanelaGrupos.module.css';
import { AuthContext } from '../../context/AuthContext';
import PropTypes from 'prop-types';

const JanelaGrupos = ({ onGroupSelect, isEditing }) => {
  const { grupos, isLoading, error, refetch } = useGruposData();
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingGroup, setEditingGroup] = useState(null);
  const { token, cidadeId } = useContext(AuthContext);

  const handleGroupChange = (event) => {
    const groupId = event.target.value;
    setSelectedGroupId(groupId);
    const group = grupos.find((g) => g.grupoId.toString() === groupId);
    onGroupSelect(group);
    console.log(`Grupo selecionado: ${group.grupoNome}, Cor: ${group.grupoCor}`);
  };

  const openCreateModal = () => {
    setEditingGroup(null);
    setIsModalOpen(true);
    console.log('Abrindo modal para criar novo grupo.');
  };

  const openEditModal = () => {
    const groupToEdit = grupos.find((group) => group.grupoId.toString() === selectedGroupId);
    if (!groupToEdit) {
      alert('Selecione um grupo para editar.');
      return;
    }
    setEditingGroup(groupToEdit);
    setIsModalOpen(true);
    console.log(`Abrindo modal para editar o grupo: ${groupToEdit.grupoNome}`);
  };

  const handleModalSubmit = async (groupData) => {
    try {
      console.log('Dados do Grupo para Salvar:', groupData);

      const url = editingGroup
        ? `https://api.ads10.com.br/api/grupo/${editingGroup.grupoId}`
        : 'https://api.ads10.com.br/api/grupo';

      const method = editingGroup ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          cidadeId: cidadeId,
        },
        body: JSON.stringify(groupData),
      });

      console.log('Resposta da API:', response);

      if (!response.ok) {
        const errorData = await response.json();
        console.log('Erro da API:', errorData);
        throw new Error(errorData.message || 'Erro ao salvar o grupo.');
      }

      const responseData = await response.json();
      console.log('Dados retornados pela API:', responseData);

      // Verifique se grupoCor está presente
      if (!responseData.grupoCor) {
        console.warn('A resposta da API não inclui grupoCor.');
      }

      alert('Grupo salvo com sucesso!');
      await refetch(); // Atualiza os grupos na interface

      // Força o recarregamento da página
      window.location.reload();

      // Opcional: Você pode comentar ou remover a linha abaixo, pois a página será recarregada
      // setIsModalOpen(false);
    } catch (error) {
      console.error('Erro ao salvar o grupo:', error);
      alert(`Erro ao salvar o grupo: ${error.message}`);
    }
  };

  // Adicionado: useEffect para atualizar selectedGroup após grupos serem refetch
  useEffect(() => {
    if (editingGroup) {
      const updatedGroup = grupos.find(g => g.grupoId === editingGroup.grupoId);
      if (updatedGroup) {
        onGroupSelect(updatedGroup);
        console.log('Grupo selecionado atualizado via useEffect:', updatedGroup);
      }
    }
  }, [grupos, editingGroup, onGroupSelect]);

  return (
    <div className={styles.janelaGrupos}>
      <h3>Grupos</h3>
      {isLoading && <p>Carregando...</p>}
      {error && <p className={styles.error}>Erro: {error}</p>}
      {!isLoading && !error && (
        <>
          <select
            value={selectedGroupId}
            onChange={handleGroupChange}
            className={styles.dropdown}
            disabled={isEditing} // Opcional: Desativar seleção durante edição
          >
            <option value="" disabled>
              Selecione um grupo
            </option>
            {grupos.map((grupo) => (
              <option key={grupo.grupoId} value={grupo.grupoId}>
                {grupo.grupoNome}
              </option>
            ))}
          </select>
          <div className={styles.actions}>
            <button
              onClick={openCreateModal}
              className={styles.createButton}
              disabled={isEditing} // Desativa quando está em modo de edição
            >
              Criar Novo Grupo
            </button>
            <button
              onClick={openEditModal}
              className={styles.editButton}
              disabled={!selectedGroupId || isEditing} // Desativa se nenhum grupo selecionado ou em modo de edição
            >
              Editar Grupo
            </button>
          </div>
        </>
      )}

      <GrupoModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleModalSubmit}
        initialData={editingGroup}
      />
    </div>
  );
};

JanelaGrupos.propTypes = {
  onGroupSelect: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired, // Adicionado PropType
};

export default JanelaGrupos;
