import React, { useState, useEffect } from 'react';
import { FaIdCard, FaRoad, FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import styles from './TipoViasModal.module.css';
import BaseModal from '../../../components/BaseModal/BaseModal';

Modal.setAppElement('#root');

const TipoViasModal = ({ isOpen, onRequestClose, onSubmit, initialData }) => {
  const [titulo, setTitulo] = useState("Novo Tipo de Via");
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    tv_id: '',
    tv_nome: '',
  });

  useEffect(() => {
    if (initialData) {
      const formattedData = {
        ...initialData,
        tv_id: initialData.tv_id,
      };
      setFormData(formattedData);
      setTitulo("Editar Tipo de Via");
      setEdit(true);
    } else {
      setFormData({
        tv_id: '',
        tv_nome: '',
      });
      setTitulo("Novo Tipo de Via");
      setEdit(false);
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ ...formData });
    onRequestClose();
  };


  return (
    <BaseModal contentLabel={"Informações de Tipo Via"} isOpen={isOpen} onRequestClose={onRequestClose}>
      <h1 className={styles.header}>{titulo}</h1>
      <form onSubmit={handleSubmit}>
        <fieldset className={styles.fieldset}>
          <div className={styles.formGroup}>
            {edit && (
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaIdCard /> ID</label>
                <input
                  className={styles.input}
                  type="text"
                  name="tv_id"
                  value={formData.tv_id}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            )}
            <div className={styles.colSpan1}>
              <label className={styles.label}><FaRoad /> Tipo de Via</label>
              <input
                className={styles.input}
                type="text"
                name="tv_nome"
                value={formData.tv_nome}
                onChange={handleChange}
              />
            </div>

          </div>
        </fieldset>

        <button className={styles.button} type="submit">Salvar</button>
      </form>
    </BaseModal>
  );
};

export default TipoViasModal;
