import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { FaTimes } from 'react-icons/fa';
import logsData from '../../data/logs.json'; // Certifique-se de que o caminho está correto
import styles from './GraphModal.module.css';

const GraphModal = ({ isOpen, onRequestClose, euis }) => {
  const [logData, setLogData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedMetric, setSelectedMetric] = useState('tensaoRede'); // Estado para a métrica selecionada
  const [selectedEUI, setSelectedEUI] = useState(euis.length > 0 ? euis[0] : ''); // Estado para o EUI selecionado

  useEffect(() => {
    if (isOpen) {
      if (euis.length > 0) {
        setSelectedEUI(euis[0]);
        loadDataForEUI(euis[0]);
      }
    }
  }, [isOpen, euis]);

  const loadDataForEUI = (eui) => {
    const filteredLogs = logsData.filter(log => log.dispositivoEUI === eui);
    const combinedLogs = filteredLogs.flatMap(log => log.logs);
    setLogData(combinedLogs);
  };

  const handleFilter = () => {
    const filteredLogs = logsData.filter(log => log.dispositivoEUI === selectedEUI);
    const combinedLogs = filteredLogs.flatMap(log => log.logs)
      .filter(log => (!startDate || new Date(log.data) >= new Date(startDate)) && (!endDate || new Date(log.data) <= new Date(endDate)));
    setLogData(combinedLogs);
  };

  const logDataWithPower = logData.map(log => ({
    ...log,
    power: log.tensaoRede * log.corrente,
    isLigado: log.isLigado ? 1 : 0 // Convertendo true/false para 1/0
  }));

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className={styles.modal} overlayClassName={styles.modalOverlay}>
      <div className={styles.modalHeader}>
        <h2 className={styles.modalTitle}>Histórico de Leituras</h2>
        <button className={styles.closeButton} onClick={onRequestClose}>
          <FaTimes />
        </button>
      </div>
      {euis.length > 0 ? (
        <>
          <div className={styles.filter}>
            <label>Selecionar Dispositivo:
              <select value={selectedEUI} onChange={(e) => {
                setSelectedEUI(e.target.value);
                loadDataForEUI(e.target.value);
              }}>
                {euis.map(eui => (
                  <option key={eui} value={eui}>{eui}</option>
                ))}
              </select>
            </label>
          </div>
          <div className={styles.filter}>
            <label>Data Inicial:
              <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </label>
            <label>Data Final:
              <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </label>
            <button onClick={handleFilter} className={styles.filterButton}>Filtrar</button>
          </div>
          <div className={styles.filter}>
            <label>Selecionar Métrica:
              <select value={selectedMetric} onChange={(e) => setSelectedMetric(e.target.value)}>
                <option value="tensaoRede">Tensão</option>
                <option value="potenciaAtual">Potência</option>
                <option value="consumo">Consumo</option>
                <option value="isLigado">ON/OFF</option>
              </select>
            </label>
          </div>
          <div className={styles.chartContainer}>
            <h3>{selectedMetric === 'isLigado' ? 'Estado de Ligado/Desligado' : 'Métrica Selecionada'}</h3>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={logDataWithPower}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="data" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey={selectedMetric} stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </>
      ) : (
        <div className={styles.alertMessage}>
          Nenhum dispositivo EUI associado a este poste, não há dados para exibir.
        </div>
      )}
    </Modal>
  );
};

export default GraphModal;
