import { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

const useDevicesSemPoste = () => {
  const { token, cidadeId } = useContext(AuthContext);
  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDevices = useCallback(async () => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Faz duas chamadas paralelas para fotocélulas sem poste e associações
      const [fotocelulasSemPosteResponse, postefotocelulaResponse] = await Promise.all([
        fetch('https://api.ads10.com.br/api/fotocelulassemposte', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            cidadeId: cidadeId,
          },
        }),
        fetch('https://api.ads10.com.br/api/postefotocelula', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            cidadeId: cidadeId,
          },
        }),
      ]);

      if (!fotocelulasSemPosteResponse.ok || !postefotocelulaResponse.ok) {
        throw new Error('Erro ao buscar dados das APIs.');
      }

      const fotocelulasSemPosteData = await fotocelulasSemPosteResponse.json();
      const postefotocelulaData = await postefotocelulaResponse.json();

      // Cria um Set com todos os IDs de fotocélulas associadas ativas
      const associatedFotocelulasActive = new Set(
        postefotocelulaData
          .filter((assoc) => assoc.ativo === 'S') // Filtra apenas associações ativas
          .map((assoc) => String(assoc.fotocelulaId)) // Converte para string
      );

      // Filtra dispositivos sem poste válidos
      const validDevices = fotocelulasSemPosteData.filter((device) => {
        const isActiveAssociation = associatedFotocelulasActive.has(String(device.fotocelulaId));
        const isValidGeolocation = parseFloat(device.latitude) !== 0 && parseFloat(device.longitude) !== 0;

        // Considera válido se não está associado ativamente e possui geolocalização válida
        return !isActiveAssociation && isValidGeolocation;
      });

      setDevices(validDevices);

      // **Log de Depuração**
      console.log('Dispositivos sem poste válidos:', validDevices);
    } catch (err) {
      console.error('Erro ao buscar dispositivos sem poste:', err);
      setError(err.message || 'Erro desconhecido');
      toast.error(`Erro ao buscar dispositivos sem poste: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId]);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  return { devices, isLoading, error, refetch: fetchDevices };
};

export default useDevicesSemPoste;
