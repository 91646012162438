// src/pages/Postes/SubComponents/Luminarias.jsx

import React from 'react';
import { FaPlus, FaTrash, FaSave, FaLightbulb, FaLayerGroup, FaCogs, FaCalendar, FaClock } from 'react-icons/fa';
import styles from '../PosteModal.module.css';

const Luminarias = ({
  savedLuminarias = [],
  unsavedLuminarias = [],
  handleAddLuminaria,
  handleLuminariaChange,
  handleSaveLuminaria,
  handleRemoveLuminaria,
  grupos,
  isGruposLoading,
  gruposError,
  componentes,
  isComponentesLoading,
  componentesError,
  refetchGrupos,
  refetchComponentes,
  animateSaveButtons, // Recebe os índices dos botões para animar
}) => {
  return (
    <fieldset className={styles.fieldset}>
      <legend>Luminárias</legend>

      {/* Luminárias Instaladas */}
      {savedLuminarias.length > 0 && (
        <div>
          <h4>Luminárias Instaladas</h4>
          {savedLuminarias.map((luminaria, index) => (
            <div key={luminaria.id} className={styles.itemContainer}>
              {/* Tipo de Instalação */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaLightbulb /> Tipo de Instalação</label>
                <input
                  className={styles.input}
                  type="text"
                  name="tipoInstalacao"
                  value={luminaria.tipoInstalacao}
                  readOnly
                />
              </div>

              {/* Grupo */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaLayerGroup /> Grupo</label>
                <input
                  className={styles.input}
                  type="text"
                  name="grupoNome"
                  value={luminaria.grupoNome}
                  readOnly
                />
              </div>

              {/* Componente */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaCogs /> Componente</label>
                <input
                  className={styles.input}
                  type="text"
                  name="componenteDescricao"
                  value={luminaria.componenteDescricao}
                  readOnly
                />
              </div>

              {/* Quantidade */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaLightbulb /> Quantidade</label>
                <input
                  className={styles.input}
                  type="number"
                  name="quantidade"
                  value={luminaria.quantidade}
                  readOnly
                />
              </div>

              {/* Data */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaCalendar /> Data</label>
                <input
                  className={styles.input}
                  type="text"
                  name="data"
                  value={luminaria.data}
                  readOnly
                />
              </div>

              {/* Hora */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaClock /> Hora</label>
                <input
                  className={styles.input}
                  type="text"
                  name="hora"
                  value={luminaria.hora}
                  readOnly
                />
              </div>

              {/* Botão para Remover Luminária Instaladas */}
              <button
                type="button"
                className={styles.removeButton}
                onClick={() => handleRemoveLuminaria(luminaria, index, false)} // Passa isUnsaved=false
                title="Remover Luminária Instaladas"
              >
                <FaTrash />
              </button>
            </div>
          ))}
        </div>
      )}

      {/* Novas Luminárias Não Salvas */}
      {unsavedLuminarias.length > 0 && (
        <div>
          <h4>Novas Luminárias</h4>
          {unsavedLuminarias.map((luminaria, index) => (
            <div key={`unsaved-${index}`} className={styles.itemContainer}>
              {/* Tipo de Instalação */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaLightbulb /> Tipo de Instalação</label>
                <select
                  className={styles.input}
                  name="tipoInstalacao"
                  value={luminaria.tipoInstalacao}
                  onChange={(e) => handleLuminariaChange(index, e)}
                  required
                >
                  <option value="">Selecione um tipo</option>
                  <option value="Instalação">Instalação</option>
                  <option value="Alteração">Alteração</option>
                  <option value="Outros">Outros</option>
                </select>
              </div>

              {/* Grupo */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaLayerGroup /> Grupo</label>
                <select
                  className={styles.input}
                  name="grupoId"
                  value={luminaria.grupoId}
                  onChange={(e) => handleLuminariaChange(index, e)}
                  required
                >
                  <option value="">Selecione um grupo</option>
                  {isGruposLoading ? (
                    <option value="">Carregando grupos...</option>
                  ) : gruposError ? (
                    <option value="">Erro ao carregar grupos</option>
                  ) : (
                    grupos.map((grupo) => (
                      <option key={grupo.pgId} value={grupo.pgId}>
                        {grupo.pgNome}
                      </option>
                    ))
                  )}
                </select>
              </div>

              {/* Componente */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaCogs /> Componente</label>
                <select
                  className={styles.input}
                  name="componenteId"
                  value={luminaria.componenteId}
                  onChange={(e) => handleLuminariaChange(index, e)}
                  required
                  disabled={!luminaria.grupoId}
                >
                  <option value="">Selecione um componente</option>
                  {isComponentesLoading ? (
                    <option value="">Carregando componentes...</option>
                  ) : componentesError ? (
                    <option value="">Erro ao carregar componentes</option>
                  ) : (
                    componentes
                      .filter((componente) => componente.produtoGrupo === luminaria.grupoId)
                      .map((componente) => (
                        <option key={componente.produtoId} value={componente.produtoId}>
                          {componente.produtoDescricao}
                        </option>
                      ))
                  )}
                </select>
              </div>

              {/* Quantidade */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaLightbulb /> Quantidade</label>
                <input
                  className={styles.input}
                  type="number"
                  name="quantidade"
                  value={luminaria.quantidade}
                  onChange={(e) => handleLuminariaChange(index, e)}
                  required
                  min="1"
                />
              </div>

              {/* Data */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaCalendar /> Data</label>
                <input
                  className={styles.input}
                  type="text"
                  name="data"
                  value={luminaria.data}
                  readOnly
                />
              </div>

              {/* Hora */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaClock /> Hora</label>
                <input
                  className={styles.input}
                  type="text"
                  name="hora"
                  value={luminaria.hora}
                  readOnly
                />
              </div>

              {/* Botões para Salvar ou Remover Luminária Não Salva */}
              <div className={styles.buttonContainer}>
                <button
                  type="button"
                  className={`${styles.saveButton} ${animateSaveButtons[index] ? styles.animateSaveButton : ''}`}
                  onClick={() => handleSaveLuminaria(index)}
                  title="Salvar Luminária"
                >
                  <FaSave />
                </button>
                <button
                  type="button"
                  className={styles.removeButton}
                  onClick={() => handleRemoveLuminaria(luminaria, index, true)} // Passa isUnsaved=true
                  title="Remover Luminária"
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      <button
        type="button"
        className={styles.button}
        onClick={handleAddLuminaria}
      >
        <FaPlus /> Adicionar Luminária
      </button>
    </fieldset>
  );
};

export default Luminarias;
