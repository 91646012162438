import { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

const useBracoDimensao = () => {
  const URL = `https://api.ads10.com.br/api/bracodimensao`;
  const { token, cidadeId, logout } = useContext(AuthContext);
  const [bracosDimensoes, setBracosDimensoes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Função para buscar e combinar dados dos postes, fotocélulas e mapeamentos
  const fetchData = useCallback(async () => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao buscar Braços Dimensões: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      setBracosDimensoes(data);

    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setError(error.message);
      toast.error(`Erro ao buscar dados: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [URL, token, cidadeId, logout]);

  // Criar braços dimensões metodo POST
  const createBracoDimensao = useCallback(async (bracodimensao) => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(bracodimensao),
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao criar Braço Dimensão: ${response.status} ${response.statusText}`);
      }

      toast.success('Braço Dimensão criada com sucesso!');
    }
    catch (error) {
      console.error('Erro ao criar Braço Dimensão:', error);
      setError(error.message);
      toast.error(`Erro ao criar Braço Dimensão: ${error.message}`);
    } finally {
      setIsLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  // Editar braco dimensao pelo metodo PUT
  const editBracoDimensao = useCallback(async (bracodimensaoid, bracodimensao) => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    if (bracodimensaoid == null || bracodimensaoid <= 0) {
      setError('ID do Braco Dimensão Invalido');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(URL + '/' + bracodimensaoid, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(bracodimensao),
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao editar Braço Dimensão: ${response.status} ${response.statusText}`);
      }

      toast.success('Braço Dimensão Editada com sucesso!');
    }
    catch (error) {
      console.error('Erro ao editar Braço Dimensão:', error);
      setError(error.message);
      toast.error(`Erro ao editar Braço Dimensão: ${error.message}`);
    } finally {
      setIsLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  // Deletar braço dimensão pelo metodo DELETE
  const deleteBracoDimensao = useCallback(async (bracodimensaoid) => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    if (bracodimensaoid == null || bracodimensaoid <= 0) {
      setError('ID da Braço Dimensão Invalido');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(URL + '/' + bracodimensaoid, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao deletar Braço Dimensão: ${response.status} ${response.statusText}`);
      }

      toast.success('Braço Dimensão Deletada com sucesso!');
    }
    catch (error) {
      console.error('Erro ao deletar Braço Dimensão:', error);
      setError(error.message);
      toast.error(`Erro ao deletar Braço Dimensão: ${error.message}`);
    } finally {
      setIsLoading(false);
    }

  }, [URL, token, cidadeId, logout]);


  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { bracoDimensoes: bracosDimensoes, isLoading, error, refetch: fetchData, createBracoDimensao: createBracoDimensao, editBracoDimensao: editBracoDimensao, deleteBracoDimensao: deleteBracoDimensao };
};

export default useBracoDimensao;