// src/hooks/usePerfilPoste.js

import { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

const usePerfilPoste = () => {
  const { token, cidadeId, logout } = useContext(AuthContext);
  const [perfilPostes, setPerfilPostes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Função para buscar todos os perfis de poste
  const fetchPerfilPostes = useCallback(async () => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('https://api.ads10.com.br/api/perfilposte', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Por favor, faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Erro ao buscar perfis de poste');
      }

      const data = await response.json();
      setPerfilPostes(data);
    } catch (err) {
      console.error('Erro ao buscar perfis de poste:', err);
      setError(err.message);
      toast.error(`Erro ao buscar perfis de poste: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId, logout]);

  // Função para criar um novo perfil de poste
  const createPerfilPoste = useCallback(async (perfilData) => {
    console.log('createPerfilPoste called with:', perfilData); // Log de chamada
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('https://api.ads10.com.br/api/perfilposte', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(perfilData),
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Por favor, faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Erro ao criar perfil de poste');
      }

      const newPerfilPoste = await response.json();
      setPerfilPostes((prevPerfis) => [...prevPerfis, newPerfilPoste]);
      toast.success('Perfil de poste criado com sucesso!');
    } catch (err) {
      console.error('Erro ao criar perfil de poste:', err);
      setError(err.message);
      toast.error(`Erro ao criar perfil de poste: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId, logout]);

  // Função para atualizar um perfil de poste existente
  const updatePerfilPoste = useCallback(async (perfilData) => {
    console.log('updatePerfilPoste called with:', perfilData); // Log de chamada
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`https://api.ads10.com.br/api/perfilposte/${perfilData.perfilposteId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(perfilData),
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Por favor, faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Erro ao atualizar perfil de poste');
      }

      const updatedPerfilPoste = await response.json();
      setPerfilPostes((prevPerfis) =>
        prevPerfis.map((p) => (p.perfilposteId === updatedPerfilPoste.perfilposteId ? updatedPerfilPoste : p))
      );
      toast.success('Perfil de poste atualizado com sucesso!');
    } catch (err) {
      console.error('Erro ao atualizar perfil de poste:', err);
      setError(err.message);
      toast.error(`Erro ao atualizar perfil de poste: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId, logout]);

  // Função para deletar um perfil de poste
  const deletePerfilPoste = useCallback(async (perfilposteId) => {
    console.log('deletePerfilPoste called with:', perfilposteId); // Log de chamada
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`https://api.ads10.com.br/api/perfilposte/${perfilposteId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Por favor, faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Erro ao deletar perfil de poste');
      }

      // Remover o perfil deletado do estado
      setPerfilPostes((prevPerfis) => prevPerfis.filter((p) => p.perfilposteId !== perfilposteId));
      toast.success('Perfil de poste deletado com sucesso!');
    } catch (err) {
      console.error('Erro ao deletar perfil de poste:', err);
      setError(err.message);
      toast.error(`Erro ao deletar perfil de poste: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId, logout]);

  useEffect(() => {
    fetchPerfilPostes();
  }, [fetchPerfilPostes]);

  return {
    perfilPostes,
    isLoading,
    error,
    fetchPerfilPostes,
    createPerfilPoste,
    updatePerfilPoste,
    deletePerfilPoste,
  };
};

export default usePerfilPoste;
