import React, { useState } from 'react';
import PageLayout from '../../../components/PageLayout/PageLayout';
import styles from './Bracos.module.css';
import BracoTiposTabela from './BracoTiposTabela';
import BracoDimensaoTabela from './BracoDimensaoTabela';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const Bracos = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // Função para mudar de aba
  const handleTabSelect = (index) => {
    setActiveTabIndex(index);
  };

  return (
    <PageLayout title="Lista de Braços">
      <div >
        <Tabs selectedIndex={activeTabIndex} onSelect={handleTabSelect}>
          <TabList>
            <Tab>Dimensões Dos Braços</Tab>
            <Tab>Tipos de Braços</Tab>
          </TabList>

          <TabPanel>
            <div className={styles.header}>
              <h3 className={styles.pageTitle}>Lista de Braços Dimensões</h3>
            </div>

            <BracoDimensaoTabela />
          </TabPanel>

          <TabPanel>
            <div className={styles.header}>
              <h3 className={styles.pageTitle}>Lista de Braços Tipos</h3>
            </div>

              <BracoTiposTabela />
          </TabPanel>

        </Tabs>
      </div>

    </PageLayout>);


};

export default Bracos;