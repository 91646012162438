// src/pages/PerfisConfiguracao/PerfisConfiguracao.js

import React, { useState, useMemo } from 'react';
import { FaPlus, FaEdit, FaTrash, FaSortUp, FaSortDown } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import ConfigurationProfileModal from '../../components/ConfigurationProfileModal/ConfigurationProfileModal';
import PageLayout from '../../components/PageLayout/PageLayout';
import styles from '../DispositivosIOT/DispositivosIOT.module.css'; // Reutilizando o mesmo CSS
import usePerfilPoste from '../../hooks/usePerfilPoste';

const PerfisConfiguracao = () => {
  const {
    perfilPostes,
    isLoading,
    error,
    createPerfilPoste,
    updatePerfilPoste,
    deletePerfilPoste,
  } = usePerfilPoste();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentProfile, setCurrentProfile] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  // Função para abrir o modal de edição
  const handleEditProfile = (profile) => {
    setCurrentProfile(profile);
    setModalIsOpen(true);
  };

  // Função para abrir o modal de criação
  const handleAddNew = () => {
    setCurrentProfile(null);
    setModalIsOpen(true);
  };

  // Função para lidar com a ordem de classificação
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Ordenação dos perfis com base na configuração
  const sortedProfiles = useMemo(() => {
    const sorted = [...perfilPostes];
    if (sortConfig.key) {
      sorted.sort((a, b) => {
        let aKey = a[sortConfig.key];
        let bKey = b[sortConfig.key];

        // Para campos de hora, converter para objetos Date para comparação correta
        if (sortConfig.key.includes('Hora')) {
          aKey = aKey ? new Date(`1970-01-01T${aKey}:00`) : new Date(0);
          bKey = bKey ? new Date(`1970-01-01T${bKey}:00`) : new Date(0);
        }

        if (aKey < bKey) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aKey > bKey) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  }, [perfilPostes, sortConfig]);

  // Paginação
  const pageCount = Math.ceil(sortedProfiles.length / itemsPerPage);

  const currentPageData = useMemo(() => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return sortedProfiles.slice(start, end);
  }, [sortedProfiles, currentPage, itemsPerPage]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  // Função para salvar (criar ou atualizar) um perfil
  const handleSaveProfile = async (profile) => {
    console.log('handleSaveProfile called with:', profile); // Log de chamada
    if (profile.perfilposteId) {
      await updatePerfilPoste(profile);
    } else {
      await createPerfilPoste(profile);
    }
  };

  // Função para deletar um perfil
  const handleDeleteProfile = async (perfilposteId) => {
    const confirmDelete = window.confirm('Tem certeza que deseja deletar este perfil de configuração?');
    if (!confirmDelete) return;

    await deletePerfilPoste(perfilposteId);
  };

  return (
    <PageLayout title="Perfis de Configuração">
      <div className={styles.container}>
        {isLoading && <div className={styles.loadingOverlay}>Carregando...</div>}
        {error && <div className={styles.error}>{error}</div>}
        <div className={styles.controlsTop}>
          <button className={styles.addButton} onClick={handleAddNew}>
            <FaPlus /> Adicionar Novo Perfil
          </button>
        </div>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th onClick={() => requestSort('perfilposteNome')}>
                  Nome {sortConfig.key === 'perfilposteNome' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('perfilposteHoraAcender')}>
                  Hora de Acender {sortConfig.key === 'perfilposteHoraAcender' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('perfilposteHoraApagar')}>
                  Hora de Apagar {sortConfig.key === 'perfilposteHoraApagar' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('perfilposteDimerizacao')}>
                  Dimerização {sortConfig.key === 'perfilposteDimerizacao' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.length > 0 ? (
                currentPageData.map(profile => (
                  <tr key={profile.perfilposteId}>
                    <td>{profile.perfilposteNome}</td>
                    <td>{profile.perfilposteHoraAcender}</td>
                    <td>{profile.perfilposteHoraApagar}</td>
                    <td>{profile.perfilposteDimerizacao}%</td>
                    <td>
                      <button className={styles.actionButton} onClick={() => handleEditProfile(profile)} title="Editar">
                        <FaEdit />
                      </button>
                      <button
                        className={`${styles.actionButton} ${styles.deleteButton}`}
                        onClick={() => handleDeleteProfile(profile.perfilposteId)}
                        title="Deletar"
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className={styles.noData}>
                    Nenhum perfil encontrado.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={styles.paginationContainer}>
          <ReactPaginate
            previousLabel={"← Anterior"}
            nextLabel={"Próximo →"}
            breakLabel={"..."}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={styles.pagination}
            previousLinkClassName={styles.paginationLink}
            nextLinkClassName={styles.paginationLink}
            breakLinkClassName={styles.paginationLink}
            disabledClassName={styles.paginationDisabled}
            activeClassName={styles.paginationActive}
            forcePage={currentPage}
          />
        </div>
        {modalIsOpen && (
          <ConfigurationProfileModal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            onSave={handleSaveProfile}
            initialData={currentProfile}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default PerfisConfiguracao;
