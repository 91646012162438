// src/components/PasswordModal/PasswordModal.jsx

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styles from './PasswordModal.module.css';
import { FaTimes } from 'react-icons/fa';

const PasswordModal = ({ isOpen, onRequestClose, userData, onSave }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (userData) {
      setPassword('');
      setConfirmPassword('');
      setError('');
    }
  }, [userData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('As senhas não coincidem.');
      return;
    }
    if (password.length < 6) {
      setError('A senha deve ter pelo menos 6 caracteres.');
      return;
    }
    setError('');
    // Chama a função onSave passando todo o objeto do usuário com a senha atualizada
    onSave({ ...userData, usuarioSenha: password });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Alterar Senha"
      className={styles.modal}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <button className={styles.closeButton} onClick={onRequestClose} aria-label="Fechar Modal">
        <FaTimes />
      </button>
      <div className={styles.modalContent}>
        <h2>Alterar Senha</h2>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="password">Nova Senha</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              minLength="6"
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="confirmPassword">Confirmar Senha</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              minLength="6"
            />
          </div>
          {error && <p className={styles.error}>{error}</p>}
          <div className={styles.formActions}>
            <button type="submit" className={styles.saveButton}>
              Salvar
            </button>
            <button type="button" className={styles.cancelButton} onClick={onRequestClose}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default PasswordModal;
