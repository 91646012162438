// src/fetchInterceptor.js

import { useContext, useEffect } from 'react';
import { AuthContext } from './context/AuthContext';
import { toast } from 'react-toastify';

export const useFetchInterceptor = () => {
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    const originalFetch = window.fetch;

    window.fetch = async (...args) => {
      const [resource, config] = args;

      // Verifique se a requisição já tem headers e inicialize caso não tenha
      const headers = config?.headers || {};

      const token =
        localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      const cidadeId =
        localStorage.getItem('cidadeId') || sessionStorage.getItem('cidadeId');

      // Se houver token e cidadeId, adicione-os aos headers
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }

      // Verifica se o cidadeID existe e não está incluido nos Headers
      if (cidadeId && (isEmpty(headers['CidadeId']) && isEmpty(headers['cidadeId']) && isEmpty(headers['cidadeid']))) {
        headers['cidadeId'] = cidadeId;
      }

      const response = await originalFetch(resource, {
        ...config,
        headers: {
          ...headers,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 401) { // Unauthorized
        if (resource.includes('/api/login')) {
          // Se for a rota de login, deixe o componente de login tratar o erro
        } else {
          toast.error('Sessão expirada. Por favor, faça login novamente.');
          logout();
        }
      } else if (!response.ok) {
        // Trate outros erros aqui se necessário
        try {
          const errorData = await response.json();
          if (errorData.message) {
            toast.error(`Erro: ${errorData.message}`);
          } else {
            toast.error('Ocorreu um erro inesperado.');
          }
        } catch (err) {
          toast.error('Ocorreu um erro inesperado.');
        }
      }

      return response;
    };

    return () => {
      window.fetch = originalFetch;
    };
  }, [logout]);
};

function isEmpty(value) {
  return (value == null || (typeof value === "string" && value.trim().length === 0));
}

