// src/pages/Login/Login.js

import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import FormularioLogin from "./FormularioLogin";
import styles from "./Login.module.css";
import backgroundImage from "../../../src/assets/telaLogin-background.png"; // Verifique o caminho correto
import directLuzLogo from "../../assets/login-directluz-icone.png"; // Verifique o caminho correto
import { AuthContext } from "../../context/AuthContext";

const Login = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated) {  
      // Se o usuário já estiver autenticado, redireciona para o dashboard
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <img
          className={styles.backgroundImage}
          src={backgroundImage}
          alt="Imagem de fundo da janela de login"
        />
        <div className={styles.formContent}>
          <h1 className={styles.title}>Login</h1>
          <img src={directLuzLogo} className={styles.logo} alt="Logo DirectLuz" />
          <FormularioLogin />
        </div>
      </div>
    </div>
  );
};

export default Login;
