import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './AtrelarModal.module.css';
import { MdClose } from 'react-icons/md';

const AtrelarModal = ({ isOpen, onClose, markerData, atribuirPoste, postesDropdown, markerType, error, setError }) => {
  const [selectedPoste, setSelectedPoste] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPostes, setFilteredPostes] = useState(postesDropdown);

  useEffect(() => {
    if (isOpen) {
      // Resetar seleção ao abrir o modal
      setSelectedPoste('');
      setSearchTerm('');
      setFilteredPostes(postesDropdown);
      setError(null);
    }
  }, [isOpen, postesDropdown, setError]);

  useEffect(() => {
    // Filtrar postes com base no termo de busca
    const filtered = postesDropdown.filter((poste) =>
      poste.codigoPoste.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredPostes(filtered);
  }, [searchTerm, postesDropdown]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedPoste) {
      setError('Por favor, selecione um poste.');
      return;
    }

    // Passar o posteId e o deviceEUI para a função de associação
    atribuirPoste(selectedPoste, markerData.fotocelulaId);
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        {/* Botão de fechar */}
        <button
          className={styles.closeButton}
          onClick={onClose}
          aria-label="Fechar Modal"
        >
          <MdClose size={24} />
        </button>

        <h2>Atribuir Poste à Fotocélula</h2>

        {/* Informações da Fotocélula */}
        <div className={styles.deviceInfo}>
          <p><strong>Fotocélula ID:</strong> {markerData.fotocelulaId}</p>
          <p><strong>Device EUI:</strong> {markerData.deviceEUI}</p>
          <p><strong>Descrição:</strong> {markerData.descricao}</p>
          <p><strong>Localização:</strong> Latitude {markerData.latitude}, Longitude {markerData.longitude}</p>
          <p><strong>Última Atualização:</strong> {markerData.ultimaAtualizacao}</p>
        </div>

        {/* Formulário de Associação */}
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formGroup}>
            <label htmlFor="posteSearch">Buscar ou Selecionar Poste:</label>
            <input
              id="posteSearch"
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Buscar pelo código do poste"
              className={styles.searchInput}
            />
          </div>

          <ul className={styles.posteList}>
            {filteredPostes.map((poste) => (
              <li
                key={poste.id}
                onClick={() => setSelectedPoste(poste.id)}
                className={`${styles.posteItem} ${selectedPoste === poste.id ? styles.selected : ''}`}
              >
                {poste.codigoPoste}
              </li>
            ))}
          </ul>

          {error && <p className={styles.errorMessage}>{error}</p>}

          <button type="submit" className={styles.submitButton}>
            Associar
          </button>
        </form>
      </div>
    </div>
  );
};

AtrelarModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  markerData: PropTypes.shape({
    fotocelulaId: PropTypes.string.isRequired,
    deviceEUI: PropTypes.string.isRequired,
    descricao: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    ultimaAtualizacao: PropTypes.string,
  }).isRequired,
  atribuirPoste: PropTypes.func.isRequired,
  postesDropdown: PropTypes.array.isRequired,
  markerType: PropTypes.string.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func.isRequired,
};

AtrelarModal.defaultProps = {
  error: null,
};

export default AtrelarModal;
