import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate'; // Importar React Paginate
import { FaPlus, FaEdit, FaTrash, FaSortUp, FaSortDown } from 'react-icons/fa';
import { CSVLink } from 'react-csv';
import LampadaModal from './LampadaModal';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay'; // Importando o componente de Loading
import PageLayout from '../../../components/PageLayout/PageLayout';
import styles from './Lampadas.module.css';
import useLampada from '../../../hooks/useLampada';
import Tabela from '../../../components/Tabela/Tabela';


const Lampadas = () => {
  const {lampadas, isLoading, error, refetch, createLampada, editLampada, deleteLampada} = useLampada([]);
  const [selectedLampada, setSelectedLampada] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [tipoFilter, setTipoFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(0); // Estado para a página atual
  const itemsPerPage = 10; // Define o número de itens por página


  useEffect(() => {
  }, [lampadas]);


  /* Funções de Modificação de dados  */
  const handleAddNew = () => {
    setSelectedLampada(null);
    setIsModalOpen(true);
  };

  const handleEdit = (lampada) => {
    setSelectedLampada(lampada);
    setIsModalOpen(true);
  };

  const handleDelete = (lampada) => {
    const confirm = window.confirm('Tem certeza de que deseja deletar a Lampada: ' + lampada.ltTipo + " (ID: " + lampada.ltId + ")?");

    if (!confirm) {
      return;
    }
    else {
      //setIsLoading(true);
      deleteLampada(lampada.ltId).then(() => {
        refetch();
      });
    }
  };

  const handleSaveLampada = (lampada) => {
    //setIsLoading(false);
    if (lampada.ltId) {
      editLampada(lampada.ltId, lampada).then(() => {
        refetch();
      });
    }
    else {
      createLampada(lampada).then(() => {
        refetch();
      });
    }
  };


  // Funções de sort da tabela
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedLampadas = () => {
    const sortedLampadas = [...lampadas];
    if (sortConfig.key) {
      sortedLampadas.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedLampadas;
  };

  const filteredLampadas = sortedLampadas().filter(lampada => {
    const matchesSearchTerm = lampada.ltId.toString().toLowerCase().includes(searchTerm.toLowerCase());
    const matchesTipoFilter = tipoFilter ? lampada.ltTipo.toLowerCase().includes(tipoFilter.toLowerCase()) : true;

    return matchesSearchTerm && matchesTipoFilter;
  });


  // Funções de paginação
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentPageData = filteredLampadas.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(filteredLampadas.length / itemsPerPage);

  // Funções de input de filtro/pesquisa
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTipoFilterChange = (e) => {
    setTipoFilter(e.target.value);
  };


  // Preparando dados para exportação CSV
  const headers = [
    { label: 'ID', key: 'ltId' },
    { label: 'Tipo', key: 'ltTipo' },
    { label: 'Potencia', key: 'ltPotencia' },
    { label: 'Perdas', key: 'ltPerdas' },
    { label: 'Consumo', key: 'ltConsumo' },
    { label: 'kWh', key: 'ltValorkwh' },
  ];

  const csvData = filteredLampadas.map(lampada => ({
    ltId: lampada.ltId,
    ltTipo: lampada.ltTipo,
    ltPotencia: lampada.ltPotencia,
    ltPerdas: lampada.ltPerdas,
    ltConsumo: lampada.ltConsumo,
    ltValorkwh: lampada.ltValorkwh,
  }));


  return (
    <PageLayout title="Lista de Lampadas">
      {isLoading && <LoadingOverlay />} {/* Loading screen enquanto os dados estão sendo carregados */}

      {!isLoading && (
        <>
        {error && (
            <div className={styles.errorContainer}>
              <p className={styles.errorMessage}>Erro: {error}</p>
              <button className={styles.retryButton} onClick={refetch}>
                Tentar Novamente
              </button>
            </div>
          )}
          <div className={styles.controlsTop}>
            <button className={styles.addButton} onClick={handleAddNew}>
              <FaPlus /> Adicionar Nova Lampada 
            </button>
            <CSVLink
              data={csvData}
              headers={headers}
              filename={"lampadas-export.csv"}
              className={styles.exportButton}
              bom={true}
              separator={";"}
              enclosingCharacter={`"`}
            >
              Exportar para Excel
            </CSVLink>
          </div>
          <div className={styles.controls}>
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Pesquisar por ID do Poste..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Filtrar por Tipo..."
              value={tipoFilter}
              onChange={handleTipoFilterChange}
            />
          </div>

          {/* Tabela de Lampadas */}
          <div className={styles.tableContainer}>
            <Tabela>
              <thead>
                <tr>
                  <th onClick={() => requestSort('ltId')}>
                    ID {sortConfig.key === 'ltId' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('ltTipo')}>
                    Tipo {sortConfig.key === 'ltTipo' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('ltPotencia')}>
                    Potência {sortConfig.key === 'ltPotencia' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('ltPerdas')}>
                    Perdas {sortConfig.key === 'ltPerdas' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('ltConsumo')}>
                    Consumo {sortConfig.key === 'ltConsumo' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('ltValorkwh')}>
                    kWh {sortConfig.key === 'ltValorkwh' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {currentPageData.map(lampada => (
                  <tr key={lampada.ltId}>
                    <td>{lampada.ltId}</td>
                    <td>{lampada.ltTipo}</td>
                    <td>{lampada.ltPotencia}</td>
                    <td>{lampada.ltPerdas}</td>
                    <td>{lampada.ltConsumo}</td>
                    <td>{lampada.ltValorkwh}</td>
                    <td>
                      <button className={styles.actionButton} onClick={() => handleEdit(lampada)}>
                        <FaEdit />
                      </button>
                      <button className={`${styles.actionButton} ${styles.deleteButton}`} onClick={() => handleDelete(lampada)}>
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Tabela>
          </div>

          {/* Componente de paginação */}
          <div className={styles.paginationContainer}>
            <ReactPaginate
              previousLabel={"← Anterior"}
              nextLabel={"Próximo →"}
              breakLabel={"..."}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={styles.pagination}
              previousLinkClassName={styles.paginationLink}
              nextLinkClassName={styles.paginationLink}
              disabledClassName={styles.paginationDisabled}
              activeClassName={styles.paginationActive}
            />
          </div>


          <LampadaModal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            onSubmit={handleSaveLampada}
            initialData={selectedLampada}
          />
        </>
      )}

    </PageLayout>);


};

export default Lampadas;