// src/hooks/useGruposData.js

import { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

const useGruposData = () => {
  const { token, cidadeId, logout } = useContext(AuthContext);
  const [grupos, setGrupos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    // Verifica se o `cidadeId` e `token` estão disponíveis
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Headers da requisição
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        cidadeId: cidadeId,
      };

      // Chamada à API de grupos
      const response = await fetch('https://api.ads10.com.br/api/grupo', { headers });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        throw new Error('Erro ao buscar dados dos grupos');
      }

      const data = await response.json();

      // Define os dados no estado
      setGrupos(data);
    } catch (error) {
      console.error('Erro ao buscar dados dos grupos:', error);
      setError(error.message);
      toast.error(`Erro ao buscar dados dos grupos: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId, logout]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { grupos, isLoading, error, refetch: fetchData };
};

export default useGruposData;
