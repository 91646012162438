// src/components/PosteGrupos/GrupoModal.jsx

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './GrupoModal.module.css';

const GrupoModal = ({ isOpen, onClose, onSubmit, initialData }) => {
  const [grupoNome, setGrupoNome] = useState('');
  const [grupoCor, setGrupoCor] = useState('#000000');
  const colorInputRef = useRef(null);

  useEffect(() => {
    if (initialData) {
      setGrupoNome(initialData.grupoNome || '');
      setGrupoCor(initialData.grupoCor || '#000000');
    } else {
      setGrupoNome('');
      setGrupoCor('#000000');
    }
  }, [initialData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!grupoNome.trim()) {
      alert('O nome do grupo é obrigatório.');
      return;
    }
    onSubmit({ grupoNome, grupoCor });
  };

  const handleColorClick = () => {
    if (colorInputRef.current) {
      colorInputRef.current.click();
    }
  };

  const handleColorChange = (e) => {
    setGrupoCor(e.target.value);
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent} role="dialog" aria-modal="true" aria-labelledby="modal-title">
        <h2 id="modal-title">{initialData ? 'Editar Grupo' : 'Criar Novo Grupo'}</h2>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formGroup}>
            <label htmlFor="grupoNome">Nome do Grupo:</label>
            <input
              type="text"
              id="grupoNome"
              value={grupoNome}
              onChange={(e) => setGrupoNome(e.target.value)}
              required
              className={styles.input}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="grupoCor">Cor do Grupo:</label>
            <div className={styles.colorPickerContainer}>
              <div
                className={styles.colorDisplay}
                style={{ backgroundColor: grupoCor }}
                onClick={handleColorClick}
                title="Clique para selecionar a cor"
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleColorClick();
                  }
                }}
              />
              <input
                type="color"
                id="grupoCor"
                value={grupoCor}
                onChange={handleColorChange}
                ref={colorInputRef}
                className={styles.colorInput}
                aria-label="Selecionar Cor do Grupo"
              />
            </div>
          </div>
          <div className={styles.formActions}>
            <button type="submit" className={styles.saveButton}>
              Salvar
            </button>
            <button type="button" onClick={onClose} className={styles.cancelButton}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

GrupoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object,
};

export default GrupoModal;
