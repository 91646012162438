import { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

/**
 * Hook customizado para buscar a lista de formatos de poste.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @returns {object} - Contém a lista de formatos de poste, estado de carregamento, erro e função para refetch.
 */
const useFormatoPoste = (isOpen) => {
  const URL = 'https://api.ads10.com.br/api/formatoposte';
  const { token, logout, cidadeId } = useContext(AuthContext); // Obtém token e logout do contexto
  const [formatosPoste, setFormatosPoste] = useState([]);
  const [isFormatoPosteLoading, setIsFormatoPosteLoading] = useState(false);
  const [formatoPosteError, setFormatoPosteError] = useState(null);

  /**
   * Função para buscar os formatos de poste da API.
   */
  const fetchFormatoPoste = useCallback(async () => {
    if (!isOpen) return; // Não buscar se o modal não estiver aberto

    setIsFormatoPosteLoading(true);
    setFormatoPosteError(null);
    try {
      if (!token) throw new Error('Token de autenticação não encontrado');

      const response = await fetch(URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Incluindo o token de autenticação
          'cidadeId': cidadeId,
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar formatos de poste: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Formatos de Poste recebidos da API:', data); // Log para depuração
      setFormatosPoste(data);
    } catch (error) {
      console.error('Erro ao buscar formatos de poste:', error);
      setFormatoPosteError(error.message.includes('Token')
        ? 'Você precisa estar autenticado para carregar a lista de formatos de poste.'
        : 'Falha ao carregar a lista de formatos de poste. Verifique sua conexão ou tente novamente mais tarde.');
      toast.error(setFormatoPosteError);
    } finally {
      setIsFormatoPosteLoading(false);
    }
  }, [isOpen, cidadeId, token, logout]);


  // Criar Formato Poste metodo POST
  const createFormatoPoste = useCallback(async (formatoposte) => {
    if (!token || !cidadeId) {
      setFormatoPosteError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsFormatoPosteLoading(true);
    setFormatoPosteError(null);

    try {
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(formatoposte),
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao criar Formato Poste: ${response.status} ${response.statusText}`);
      }

      toast.success('Formato Poste criado com sucesso!');
    }
    catch (error) {
      console.error('Erro ao criar Formato Poste:', error);
      setFormatoPosteError(error.message);
      toast.error(`Erro ao criar Formato Poste: ${error.message}`);
    } finally {
      setIsFormatoPosteLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  // Editar Formato Poste pelo metodo PUT
  const editFormatoPoste = useCallback(async (formatoposteid, formatoposte) => {
    if (!token || !cidadeId) {
      setFormatoPosteError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    if (formatoposteid == null || formatoposteid <= 0) {
      setFormatoPosteError('ID do Formato Poste Invalido');
      return;
    }

    setIsFormatoPosteLoading(true);
    setFormatoPosteError(null);

    try {
      const response = await fetch(URL + '/' + formatoposteid, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
        body: JSON.stringify(formatoposte),
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao editar Formato Poste: ${response.status} ${response.statusText}`);
      }

      toast.success('Formato Poste Editado com sucesso!');
    }
    catch (error) {
      console.error('Erro ao editar Formato Poste:', error);
      setFormatoPosteError(error.message);
      toast.error(`Erro ao editar Formato Poste: ${error.message}`);
    } finally {
      setIsFormatoPosteLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  // Deletar Formato Poste pelo metodo DELETE
  const deleteFormatoPoste = useCallback(async (formatoposteid) => {
    if (!token || !cidadeId) {
      setFormatoPosteError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    if (formatoposteid == null || formatoposteid <= 0) {
      setFormatoPosteError('ID do Formato Poste Invalido');
      return;
    }

    setIsFormatoPosteLoading(true);
    setFormatoPosteError(null);

    try {
      const response = await fetch(URL + '/' + formatoposteid, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId,
        },
      });

      // Tratamento de erros nas requisições
      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        console.log(response.statusText);
        throw new Error(`Erro ao deletar Formato Poste: ${response.status} ${response.statusText}`);
      }

      toast.success('Formato Poste Deletado com sucesso!');
    }
    catch (error) {
      console.error('Erro ao deletar Formato Poste:', error);
      setFormatoPosteError(error.message);
      toast.error(`Erro ao deletar Formato Poste: ${error.message}`);
    } finally {
      setIsFormatoPosteLoading(false);
    }

  }, [URL, token, cidadeId, logout]);

  useEffect(() => {
    fetchFormatoPoste();
  }, [fetchFormatoPoste]);

  return { formatosPoste, isFormatoPosteLoading, formatoPosteError, refetch: fetchFormatoPoste, createFormatoPoste: createFormatoPoste, editFormatoPoste: editFormatoPoste, deleteFormatoPoste: deleteFormatoPoste };
};

export default useFormatoPoste;
